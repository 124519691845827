<template>
  <div class="smartFactory">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div
          :style="{background: `url('https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/bgc${showNum}.png') no-repeat 0 0`,backgroundSize: '100% 100%'}"
          class="mainCont">
        <div class="leftBox">
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle1.png">
            </div>
            <div class="contBox">
              <div class="contItemBox">
                <div class="contItemBox1">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem1.png">
                  <div class="contPox">
                    <div class="topBox">
                      <span class="name">电</span>
                      <span class="unit">kwh</span>
                    </div>
                    <!--                    <p class="val">{{ data.totalPowerAmount }}</p>-->
                  </div>
                </div>
                <p class="val">{{ data.totalPowerAmount }}</p>
              </div>
              <div class="contItemBox">
                <div class="contItemBox1">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem2.png">
                  <div class="contPox">
                    <div class="topBox">
                      <span class="name">水</span>
                      <span class="unit">m³</span>
                    </div>
                    <!--                    <p class="val">{{ data.totalWaterAmount }}</p>-->
                  </div>
                </div>
                <p class="val">{{ data.totalWaterAmount }}</p>
              </div>
              <div class="contItemBox">
                <div class="contItemBox1">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem3.png">
                  <div class="contPox">
                    <div class="topBox">
                      <span class="name">气</span>
                      <span class="unit">m³</span>
                    </div>
                    <!--                    <p class="val">{{ data.totalGasAmount }}</p>-->
                  </div>
                </div>
                <p class="val">{{ data.totalGasAmount }}</p>
              </div>
              <div class="contItemBox">
                <div class="contItemBox1">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem4.png">
                  <div class="contPox">
                    <div class="topBox">
                      <span class="name">空压机</span>
                      <span class="unit">KW</span>
                    </div>
                    <!--                    <p class="val">{{ data.totalAirCompressorAmount }}</p>-->
                  </div>
                </div>
                <p class="val">{{ data.totalAirCompressorAmount }}</p>
              </div>
            </div>
          </div>
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle2.png">
            </div>
            <div class="tabBox">
              <p :class="tabIdx1===1?'active':''" @click="tabClick1(1)">电</p>
              <p :class="tabIdx1===2?'active':''" @click="tabClick1(2)">水</p>
              <p :class="tabIdx1===3?'active':''" @click="tabClick1(3)">气</p>
            </div>
            <div class="chart1"></div>
          </div>
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle3.png">
            </div>
            <div class="tabBox">
              <p :class="tabIdx2===1?'active':''" @click="tabClick2(1)">设备</p>
              <p :class="tabIdx2===2?'active':''" @click="tabClick2(2)">网关</p>
            </div>
            <div class="circleBgc"></div>
            <div class="chart2"></div>
            <div class="centerBox">
              <p>{{ tabIdx2 === 1 ? data.equipmentNumber : data.gatewayNumber }}</p>
              <p>{{ tabIdx2 === 1 ? '设备总数' : '网关总数' }}</p>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle1.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem1.png">
              <div class="contPox">
                <div class="name">实时功率(KW)</div>
                <p class="val">{{ data.weekPowerAmount }}</p>
              </div>
            </div>
            <div class="chart3 chart"></div>
          </div>
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle2.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem2.png">
              <div class="contPox">
                <div class="name">生产总用水量(m³)</div>
                <p class="val">{{ data.productWaterAmount }}</p>
              </div>
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem2.png">
              <div class="contPox">
                <div class="name">生活总用水量(m³)</div>
                <p class="val">{{ data.liveWaterAmount }}</p>
              </div>
            </div>
            <!--            <div class="chart4 chart"></div>-->
          </div>
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle3.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem3.png">
              <div class="contPox">
                <div class="name">近一周总量(m³)</div>
                <p class="val">{{ data.weekGasAmount }}</p>
              </div>
            </div>
            <div class="chart5 chart"></div>
          </div>
        </div>
        <div v-show="showNum===1" class="toolitBox toolitBox1">
          <p class="name">{{ data.n1.name }}</p>
          <p v-for="(item,index) in data.n1.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <div v-show="showNum===2" class="toolitBox toolitBox2">
          <p class="name">{{ data.n2.name }}</p>
          <p v-for="(item,index) in data.n2.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <div v-show="showNum===3" class="toolitBox toolitBox3">
          <p class="name">{{ data.n3.name }}</p>
          <p v-for="(item,index) in data.n3.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <div v-show="showNum===4" class="toolitBox toolitBox4">
          <p class="name">{{ data.n4.name }}</p>
          <p v-for="(item,index) in data.n4.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <div v-show="showNum===5" class="toolitBox toolitBox5">
          <p class="name">{{ data.n5.name }}</p>
          <p v-for="(item,index) in data.n5.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <div v-show="showNum===6" class="toolitBox toolitBox6">
          <p class="name">{{ data.n6.name }}</p>
          <p v-for="(item,index) in data.n6.list" :key="index">
            <span>{{ item.key }}: &nbsp;</span>
            <span>{{ item.value }} kwh</span>
          </p>
        </div>
        <!--        <div v-show="showNum===7" class="toolitBox toolitBox7">-->
        <!--          <p class="name">{{ data.n7.name }}</p>-->
        <!--          <p v-for="(item,index) in data.n7.list" :key="index">-->
        <!--            <span>{{ item.key }}: &nbsp;</span>-->
        <!--            <span>{{ item.value }} kwh</span>-->
        <!--          </p>-->
        <!--        </div>-->
        <div class="clickBox1" @click="showNum=1"></div>
        <div class="clickBox2" @click="showNum=2"></div>
        <div class="clickBox3" @click="showNum=3"></div>
        <div class="clickBox4" @click="showNum=4"></div>
        <div class="clickBox5" @click="showNum=5"></div>
        <div class="clickBox6" @click="showNum=6"></div>


        <!--进入退出全屏-->
        <div class="enterScreenBox">
          <img v-show="!isScreen" alt="" class="enterBtn"
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/enterScreen.png"
               @click="enterFullScreen">
          <img v-show="isScreen" alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/outScreen.png"
               @click="exitFullscreen">
        </div>
        <!--时间-->
        <div class="timeBox">
          <p class="date">{{ dateVal }} &nbsp;</p>
          <p class="time">{{ timeVal }}</p>
          <!--          <p class="week">{{ weekVal }}</p>-->
        </div>
      </div>
      <div class="bgcGif"></div>
      <div class="headGif"></div>
      <div class="bottomGif"></div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import './smartFactory.less'
import VScaleScreen from 'v-scale-screen'
import * as echarts from "echarts";
import {getHanDerScreenApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()

// 当前时间
let timeVal = ref()
let dateVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '.' + month + '.' + day
}


// 今日能耗展示水电气tab
let tabIdx1 = ref(1)
const tabClick1 = function (idx) {
  tabIdx1.value = idx
  if (idx === 1) {
    xAxios1.value = data.value.dayPower.timeList
    yAxios1.value = data.value.dayPower.list[0].value
    echartsName1.value = data.value.dayPower.list[0].key
  } else if (idx === 2) {
    xAxios1.value = data.value.dayWater.timeList
    yAxios1.value = data.value.dayWater.list[0].value
    echartsName1.value = data.value.dayWater.list[0].key
  } else if (idx === 3) {
    xAxios1.value = data.value.dayGas.timeList
    yAxios1.value = data.value.dayGas.list[0].value
    echartsName1.value = data.value.dayGas.list[0].key
  }

  nextTick(() => {
    chartFun1()
  })
}

// 设备状况展示设备还是网关
let tabIdx2 = ref(1)
const tabClick2 = function (idx) {
  tabIdx2.value = idx
  nextTick(() => {
    chartFun2()
  })
}

// 加载折线图
let xAxios1 = ref([])
let yAxios1 = ref([])
let echartsName1 = ref('')
const chartFun1 = function () {
  var chartDom = document.querySelector('.chart1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: tabIdx1.value === 1 ? 'kwh' : 'm³',
      textStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: '#fff',
      }
    },
    grid: {
      left: 40,
      top: 30,
      bottom: 20,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#fff'
        }
      },
      data: xAxios1.value
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        type: 'line',
        name: echartsName1.value,
        smooth: true,
        data: yAxios1.value,
        itemStyle: {
          color: '#00EBEB',
          opacity: 0
        },
        lineStyle: {
          width: 3
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(0, 235, 235, 0.20)'
          },
            {
              offset: 1,
              color: 'rgba(0, 235, 235, 0.00)'
            },
          ])
        }
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载饼图
const chartFun2 = function () {
  var chartDom = document.querySelector('.chart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: -70,
      top: 10,
      bottom: 10,
      right: 10,
    },
    tooltip: {
      trigger: 'item', // axis   item   none三个值
      show: true,
      position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置
        var pointX = point[0];
        var pointY = point[1];
        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];
        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = 100;
        } else { // 左边放的下
          x = pointX - boxWidth;
        }
        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5;
        } else { // 上边放得下
          y = pointY - boxHeight;
        }
        return [x, y];
      },
    },
    legend: {
      show: false
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['50%', '65%'],
        avoidLabelOverlap: false,
        label: {
          formatter: '{b}: {c}'
          // formatter: function (data) {
          //   let ele = data.name === '运行中' ? `
          //   <p style="width: 10px;height: 10px;border-radius: 50%;border: 4px solid #05D1F4;"></p>
          //   ` : `
          //   <p style="width: 10px;height: 10px;border-radius: 50%;border: 4px solid #DBDBDB;"></p>
          //   `
          //   return `${data.name} ${data.value}`;
          // }
        },
        labelLine: {
          show: true
        },
        color: ['#05D1F4', '#DBDBDB'],
        data: [
          {value: tabIdx2.value === 1 ? data.value.onlineEquipmentNumber : data.value.onlineGatewayNumber, name: '在线'},
          {
            value: tabIdx2.value === 1 ? data.value.offlineEquipmentNumber : data.value.offlineGatewayNumber,
            name: '离线'
          },
        ]
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载柱状图1
const chartFun3 = function () {
  var chartDom = document.querySelector('.chart3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: '近一周用电：kwh',
      textStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: '#fff',
      }
    },
    grid: {
      left: 60,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#fff'
        }
      },
      data: data.value.weekPower.timeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        type: "pictorialBar", //设置类型为象形柱状图
        name: '用电量',
        // symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
        symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/chart1.png", //图形类型，带圆角的矩形
        barWidth: 26,
        symbolRepeat: false,
        symbolClip: true,
        z: 1,
        label: {
          show: false,
          position: 'top',
          color: '#2A6EF3',
          formatter: '{c}',
        },
        data: data.value.weekPower.list[0].value,
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载柱状图2
const chartFun4 = function () {
  var chartDom = document.querySelector('.chart4');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: '近一周用水：m³',
      textStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: '#fff',
      }
    },
    grid: {
      left: 60,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#fff'
        }
      },
      data: data.value.weekWater.timeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        type: "pictorialBar", //设置类型为象形柱状图
        name: '用水量',
        // symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
        symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/chart1.png", //图形类型，带圆角的矩形
        barWidth: 26,
        symbolRepeat: false,
        symbolClip: true,
        z: 1,
        label: {
          show: false,
          position: 'top',
          color: '#2A6EF3',
          formatter: '{c}',
        },
        data: data.value.weekWater.list[0].value,
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载柱状图3
const chartFun5 = function () {
  var chartDom = document.querySelector('.chart5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      // text: '近一周用气：m³',
      textStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: '#fff',
      }
    },
    grid: {
      left: 60,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#fff'
        }
      },
      data: data.value.weekGas.timeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        type: "pictorialBar", //设置类型为象形柱状图
        name: '用气量',
        // symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
        symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/chart1.png", //图形类型，带圆角的矩形
        barWidth: 26,
        symbolRepeat: false,
        symbolClip: true,
        z: 1,
        label: {
          show: false,
          position: 'top',
          color: '#2A6EF3',
          formatter: '{c}',
        },
        data: data.value.weekGas.list[0].value,
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}


let data = ref({
  n1: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
  n2: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
  n3: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
  n4: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
  n5: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
  n6: {
    name: 'aa',
    list: [{
      key: '11',
      value: 1323
    }]
  },
})
// 获取大屏数据
const getHanDerScreenFun = function () {
  getHanDerScreenApi().then(res => {
    if (res.data.code === 0) {
      data.value = res.data.result
      // 今日能耗趋势
      if (tabIdx1.value === 1) {
        xAxios1.value = data.value.dayPower.timeList
        yAxios1.value = data.value.dayPower.list[0].value
        echartsName1.value = data.value.dayPower.list[0].key
      } else if (tabIdx1.value === 2) {
        xAxios1.value = data.value.dayWater.timeList
        yAxios1.value = data.value.dayWater.list[0].value
        echartsName1.value = data.value.dayWater.list[0].key
      } else if (tabIdx1.value === 3) {
        xAxios1.value = data.value.dayGas.timeList
        yAxios1.value = data.value.dayGas.list[0].value
        echartsName1.value = data.value.dayGas.list[0].key
      }
      nextTick(() => {
        chartFun1()
        chartFun2()
        chartFun3()
        // chartFun4()
        chartFun5()
      })

      if (router.currentRoute.value.href === "#/smartFactory") {
        dataTimer = setTimeout(() => {
          getHanDerScreenFun()
        }, 30000)
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
      if (router.currentRoute.value.href === "#/smartFactory") {
        dataTimer = setTimeout(() => {
          getHanDerScreenFun()
        }, 30000)
      }
    }
  }).catch(err => {
    console.log(err);
    if (router.currentRoute.value.href === "#/smartFactory") {
      dataTimer = setTimeout(() => {
        getHanDerScreenFun()
      }, 30000)
    }
  })
}


// 背景循环定时器
let showNum = ref(1)


let isScreen = ref(false)
// 进入全屏
const enterFullScreen = () => {
  isScreen.value = true
  let element = document.querySelector('body')
  element.requestFullscreen();
  console.log(element)
  // 进入全屏
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    // IE11
    element.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  isScreen.value = false
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};


onMounted(() => {
  // 获取大屏数据
  getHanDerScreenFun()

  updateTime()

  // nextTick(() => {
  //   document.querySelector('.enterScreenBox .enterBtn').click()
  // })
})

let dataTimer
let loaderTimer = setInterval(() => {
  location.reload()
}, 1000 * 60 * 60 * 12)
const intervalId = setInterval(updateTime, 1000)
onUnmounted(() => {
  clearTimeout(dataTimer)
  clearInterval(loaderTimer)
  clearInterval(intervalId)
})
</script>

<style lang="less" scoped>
</style>
