<template>
  <div class="deviceCheckComponent">
    <!--    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">-->
    <!--      全选-->
    <!--    </el-checkbox>-->
    <!--    <el-checkbox-group v-model="dictId" @change="handleCheckedCitiesChange">-->
    <!--      <el-checkbox v-for="(item,index) in dictList" :key="index" :label="item.name" :value="item.id">-->
    <!--        {{ item.name }}-->
    <!--      </el-checkbox>-->
    <!--    </el-checkbox-group>-->
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" class="bindDialog" title="选择设备" width="80%">
      <div class="dialogCont">
        <el-input v-model="name" placeholder="请输入指标名称" @input="getDictList"></el-input>
        <el-radio-group v-show="!isMultiple" v-model="dictCheckId" @change="handleCheckedCitiesChange">
          <el-radio v-for="(item,index) in dictList" :key="index" :label="item.name" :value="item.id">{{
              item.name
            }}
          </el-radio>
        </el-radio-group>
        <el-checkbox-group v-show="isMultiple" v-model="dictCheckId" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="(item,index) in dictList" :key="index" :label="item.name" :value="item.id">
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addDictFun">添加</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, onMounted, reactive, ref, watch} from "vue"
import {getTreePrefixApi} from "@/api/modules/eleChargeQuery";
import qs from "qs";
import {ElMessage} from "element-plus";
import {listSelectByPrefixEquipmentIdApi} from "@/api/modules/alarm";

const props = defineProps(['dictVisible', 'checkDictList', 'isMultiple', 'equipmentId'])
const emit = defineEmits(['dictVisibleFun', 'checkDictListFun']);

// 获取设备列表
let dictId = ref([])
let dictCheckId = ref([])
let dictList = ref([])  //设备列表
let dictName = ref()
let dialogVisible = ref(false)
let name = ref()
let getDictList = function () {
  listSelectByPrefixEquipmentIdApi(qs.stringify({
    equipmentIdList: props.isMultiple ? equipmentId.value.join(',') : equipmentId.value,
    name: name.value
  })).then(res => {
    if (res.data.code === 0) {
      dictList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// const checkAll = ref(false)  //是否全选
// const isIndeterminate = ref(false)  //是否半选
// // 点击全选
// const handleCheckAllChange = (val) => {
//   dictId.value = val ? dictList.value.map(item => item.id) : []
//   isIndeterminate.value = false
//   emit('update:modelValue', dictId.value)
// }
// 点击选择
const handleCheckedCitiesChange = (value) => {
  // const checkedCount = value.length
  // checkAll.value = checkedCount === dictList.value.length
  // isIndeterminate.value = checkedCount > 0 && checkedCount < dictList.value.length
  if (props.isMultiple) {   //多选
    dictId.value = []
    for (let i = 0; i < dictList.value.length; i++) {
      for (let j = 0; j < value.length; j++) {
        if (dictList.value[i].id == value[j]) {
          dictId.value.push(dictList.value[i])
        }
      }
    }
  } else {   //单选
    dictId.value = [dictList.value.find(item => item.id === value)]
  }
}

// 点击将设备添加到选择框内
const addDictFun = function () {
  dialogVisible.value = false
  emit('dictVisibleFun', false)
  emit('checkDictListFun', dictId.value)
}

let equipmentId = ref()
// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.equipmentId, async (newValues, oldValues) => {
  equipmentId.value = newValues
  if (equipmentId.value) {
    getDictList()
  }
}, {immediate: true, deep: true});


let isMultiple = ref()
watch(() => props.dictVisible, (newVal) => {
  dialogVisible.value = newVal
}, {immediate: true, deep: true})
watch(() => props.checkDictList, (newVal) => {
  console.log(newVal)
  if (props.isMultiple) {
    dictCheckId.value = []
    for (let i = 0; i < newVal.length; i++) {
      dictCheckId.value.push(newVal[i].id)
    }
  } else {
    dictCheckId.value = newVal[0].id
  }
}, {immediate: true, deep: true})
watch(() => props.isMultiple, (newVal) => {
  isMultiple.value = newVal
}, {immediate: true, deep: true})
watch(() => dialogVisible.value, (newVal) => {
  dialogVisible.value = newVal
  emit('dictVisibleFun', newVal)
})


onMounted(() => {
  // getDictList()
})


defineExpose({    // 对外暴露
  dictId,
})
</script>

<style lang="less" scoped>
.deviceCheckComponent {
  .el-checkbox {
    //background: #EBEDF0;
    //padding: 0 10px;
    margin-bottom: 10px;
    border-radius: 3px;
  }
}
</style>
