<template>
  <!--产品详情页面-->
  <div class="productDetail">
    <div class="title">
      <p>
        <span @click="goBack">产品设置</span>
        <span> / </span>
        <span>产品详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        产品详情
      </p>
    </div>
    <div class="content">
      <div class="tableBox">
        <div class="operationBox">
          <el-button type="primary" @click="editClick()">编辑</el-button>
        </div>
        <el-table ref="multipleTableRef" :data="tableData" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="产品型号" min-width="2%" property="model">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type == 11" label="产品id" min-width="2%"
                           property="productCode">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="规约类型" min-width="2%"
                           property="rulerTypeStr">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="通讯密码" min-width="2%"
                           property="password">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="费率个数" min-width="2%"
                           property="feeNum">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="用户类型" min-width="2%"
                           property="userType">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="波特率" min-width="2%"
                           property="rateTypeStr">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="接线方式" min-width="2%"
                           property="joinTypeStr">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="额定电压" min-width="2%"
                           property="ratedV">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="额定电流" min-width="2%"
                           property="ratedA">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="资产号" min-width="2%"
                           property="pCode">
          </el-table-column>
          <el-table-column v-if="router.currentRoute.value.query.type != 11" label="抄读方式" min-width="2%"
                           property="parserName">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="parser_itemBox">
        <div v-for="item in parserItemListCopy" v-show="item.checked==true" class="parser_item">
          <span>{{ item.name }}</span>
          <span style="margin-left: 10px;">{{ item.dataCode }}</span>
        </div>
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="itemBox">
        <div class="titleBox">
          <span>是否支持补抄</span>
        </div>
        <div class="yesLi">
          <el-radio v-model="isrepair" label="是" @change="radioClick(1)">是</el-radio>
        </div>
        <div class="yesLi">
          <el-radio v-model="isrepair" label="否" @change="radioClick(1)">否</el-radio>
        </div>
      </div>
      <div class="itemBox">
        <div class="titleBox">
          <span>采集数据项</span>
          <el-button v-if="router.currentRoute.value.query.type != 11" type="primary" @click="collectClick()">筛选采集数据项
          </el-button>
        </div>
        <div v-for="item in collectList" class="ergencyLi">
          <el-checkbox v-model="collectChecked" disabled>{{ item }}</el-checkbox>
        </div>
      </div>
      <div class="itemBox">
        <div class="titleBox">
          <span>发布数据项</span>
          <el-button v-if="router.currentRoute.value.query.type != 11" type="primary" @click="collectClick2()">筛选发布数据项
          </el-button>
        </div>
        <div v-for="item in publishList" class="ergencyLi">
          <el-checkbox v-model="collectChecked" disabled>{{ item }}</el-checkbox>
        </div>
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="itemBox">
        <div class="titleBox">
          <span>数据项显示</span>
          <el-button type="primary" @click="showClick()">筛选数据项显示</el-button>
        </div>
        <div v-for="(item,index) in showDictList" :key="index">
          <p class="groupName">{{ item.name }}</p>
          <div v-for="item_i in item.list" class="ergencyLi">
            <el-checkbox v-model="collectChecked" disabled>{{ item_i.value }}</el-checkbox>
          </div>
        </div>
        <!--        <div v-for="item in showList" class="ergencyLi">-->
        <!--          <el-checkbox v-model="collectChecked" disabled>{{ item }}</el-checkbox>-->
        <!--        </div>-->
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="itemBox">
        <div class="titleBox">
          <span>事件</span>
          <el-button type="primary" @click="eventClick()">设置事件</el-button>
        </div>
        <div v-for="item in eventList" class="yesLi">
          <el-checkbox v-model="controlChecked" disabled>{{ item.name }}</el-checkbox>
          <div v-if="item.level==3" class="riskClassH" @click="">高风险</div>
          <div v-if="item.level==2" class="riskClassC" @click="">中风险</div>
          <div v-if="item.level==1" class="riskClassL" @click="">低风险</div>
          <div v-if="item.level==0" class="riskClassN" @click="">无风险</div>
          <el-icon class="eidtClass" @click="eventClick(item.id)">
            <Edit/>
          </el-icon>
          <el-icon class="closeClass" @click="eventDelete(item.id)">
            <CloseBold/>
          </el-icon>
        </div>
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="itemBox">
        <div class="titleBox">
          <span>特殊计算显示项</span>
        </div>
        <div class="yesLi">
          <el-checkbox v-model="special1" @change="radioClick(3)">线路复合率</el-checkbox>
        </div>
        <div class="yesLi">
          <el-checkbox v-model="special2" @change="radioClick(4)">三相电流不平衡率</el-checkbox>
        </div>
      </div>
      <div class="itemBox">
        <div class="titleBox">
          <span>控制</span>
          <el-button v-if="router.currentRoute.value.query.type != 11" type="primary" @click="controlClick()">设置控制
          </el-button>
        </div>
        <div v-for="item in controlList" v-if="router.currentRoute.value.query.type != 11" class="yesLi">
          <el-checkbox v-model="controlChecked" disabled>{{ item.name }}</el-checkbox>
          <el-icon class="eidtClass" @click="getControlDetailFun(item.id)">
            <Edit/>
          </el-icon>
          <el-icon class="closeClass" @click="contrlDelete(item.id)">
            <CloseBold/>
          </el-icon>
        </div>
        <div v-if="router.currentRoute.value.query.type != 11" class="yesLi">
          <el-checkbox v-model="ischeck" @change="radioClick(2)">校时</el-checkbox>
        </div>
        <div v-for="item in controlList" v-if="router.currentRoute.value.query.type == 11" class="yesLi">
          <el-checkbox v-model="controlChecked" disabled>{{ item }}</el-checkbox>
        </div>
      </div>
      <div v-if="router.currentRoute.value.query.type != 11" class="itemBox">
        <div class="titleBox">
          <span>图片</span>
          <el-button type="primary" @click="imgClick()">设置图片</el-button>
        </div>
        <div>
          <div v-for="item in imageList" class="imgBox" @click="handlePictureCardPreview2(item)">
            <img :src="item" alt=""/>
          </div>
        </div>

      </div>
    </div>
    <!--编辑产品弹窗-->
    <el-dialog v-model="productDialogVisible" :close-on-click-modal="false" :width="dialogWidth" title="编辑产品">
      <el-form ref="productFormRef" :model="productForm" :rules="productRules" class="demo-ruleForm" label-width="auto"
               style="width:98%">
        <el-form-item label="分类" prop="categoryId">
          <el-select v-model="productForm.categoryId" :disabled="router.currentRoute.value.query.type==11" clearable
                     placeholder="请选择产品分类"
                     style="width:100%" @change="categoryIdChange()">
            <el-option v-for="item in productCategoryAllList" :key="item.id" :label="item.name" :value="item.id"
                       style="width:100%">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-show="categoryType==5">
          <el-form-item label="保留soc" prop="retainSoc">
            <el-input v-model="productForm.retainSoc" placeholder="请输入保留soc"/>
          </el-form-item>
          <el-form-item label="充放电最大功率" prop="maxPower">
            <el-input v-model="productForm.maxPower" placeholder="请输入充放电最大功率"/>
          </el-form-item>
          <el-form-item label="申请容量" prop="applyCapacity">
            <el-input v-model="productForm.applyCapacity" placeholder="请输入申请容量"/>
          </el-form-item>
          <el-form-item label="充电量字典key" prop="rechargeDictKey">
            <el-input v-model="productForm.rechargeDictKey" placeholder="请输入充电量字典key"/>
          </el-form-item>
          <el-form-item label="放电量字典key" prop="generateDictKey">
            <el-input v-model="productForm.generateDictKey" placeholder="请输入放电量字典key"/>
          </el-form-item>
        </div>
        <el-form-item label="型号" prop="model">
          <el-input v-model="productForm.model" placeholder="请输入型号"/>
        </el-form-item>
        <el-form-item label="产品id" prop="productCode">
          <el-input v-model="productForm.productCode" placeholder="请输入产品id"/>
        </el-form-item>
        <div v-show="categoryType!=11">
          <el-form-item label="规约类型" prop="rulerType">
            <el-select v-model="productForm.rulerType" placeholder="请选择" style="width:100%">
              <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通信密码" prop="password">
            <el-input v-model="productForm.password" placeholder="请输入通信密码"/>
          </el-form-item>
          <el-form-item label="费率个数" prop="feeNum">
            <el-input v-model="productForm.feeNum" placeholder="请输入费率个数" type="number"/>
          </el-form-item>
          <el-form-item label="用户类型" prop="userType">
            <el-input v-model="productForm.userType" placeholder="请输入用户类型"/>
          </el-form-item>
          <el-form-item label="波特率" prop="rateType">
            <el-select v-model="productForm.rateType" placeholder="请选择" style="width:100%">
              <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接线方式" prop="joinType">
            <el-select v-model="productForm.joinType" placeholder="请选择" style="width:100%">
              <el-option v-for="item in joinTypeList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="额定电压" prop="ratedV">
            <el-input v-model="productForm.ratedV" placeholder="请输入额定电压" type="number">
              <template #suffix>
                V
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="额定电流" prop="ratedA">
            <el-input v-model="productForm.ratedA" placeholder="请输入额定电流" type="number">
              <template #suffix>
                mA
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="资产号" prop="pCode">
            <el-input v-model="productForm.pCode" placeholder="请输入资产号"/>
          </el-form-item>
          <el-form-item label="抄读方式
" prop="parserId">
            <el-select v-model="productForm.parserId" placeholder="请选择" style="width:100%">
              <el-option v-for="item in parserList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-button v-show="categoryType!=11" class="nowdataClass" type="primary" @click="setNowData(parser_id)">配置实时数据
      </el-button>
      <div class="specilCheckBox">
        <div v-for="item in parserItemList" v-show="item.checked==true" class="parserClass">
          <div class="nameClass">{{ item.name }}</div>
          <div class="nameClass">{{ item.dataCode }}</div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="productDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="productSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--实时数据弹窗-->
    <el-dialog v-model="nowDataDialogVisible" :close-on-click-modal="false" :width="dialogWidth2" title="实时抄读设置">
      <div>
        <span style="display:inline-block;margin-bottom: 20px;">抄读方式：{{ parserName }}</span>
        <div class="checkAllBox">
          <el-checkbox v-model="checkedAll" @change="checkedAllChange(parserItemList,checkedAll)">全选</el-checkbox>
        </div>
        <div v-for="item in parserItemList" :class="[item.checkFlag?'parserClass2':'parserClass3']">
          <el-checkbox v-model="item.checked" @change="checkedRadioChange('实时数据项')">{{ item.name }}</el-checkbox>
          <div v-if="item.checkFlag" :class="[item.checkFlag?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
          <div v-if="!item.checkFlag" :class="[item.checked?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="nowDataDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="nowDataDialogVisible = false">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--控制弹窗-->
    <el-dialog v-model="controlDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="controlFormRef" :model="controlForm" :rules="controlRules" class="demo-ruleForm" label-width="auto"
               style="width:98%">
        <el-form-item label="控制名称" prop="name">
          <el-input v-model="controlForm.name" placeholder="请输入控制名称"/>
        </el-form-item>
        <el-form-item label="数据标识" prop="dataCode">
          <el-input v-model="controlForm.dataCode" placeholder="请输入数据标识"/>
        </el-form-item>
        <el-form-item label="枚举值" prop="item">
          <el-input v-model="controlForm.item" placeholder="请输入枚举值"/>
        </el-form-item>
        <el-form-item label="附加数" prop="ext">
          <el-input v-model="controlForm.ext" placeholder="请输入附加数"/>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="controlForm.unit" placeholder="请输入单位"/>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="controlForm.type" placeholder="请选择类型" style="width:100%">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="controlDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="controlSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--采集弹窗-->
    <el-dialog v-model="collectDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div class="checkAllBox">
        <el-checkbox v-model="checkedAllCollect" @change="checkedAllChange(collectAllList,checkedAllCollect)">全选
        </el-checkbox>
      </div>
      <div v-for="item in collectAllList" class="ergencyLi">
        <el-checkbox v-model="item.checked" @change="checkedRadioChange('采集')">{{ item.name }}</el-checkbox>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="collectDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="updateDataItemSaveFun(1)">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--发布弹窗-->
    <el-dialog v-model="collectDialogVisible2" :close-on-click-modal="false" :width="dialogWidth" title="发布数据项">
      <div class="checkAllBox">
        <el-checkbox v-model="checkedAllCollect2" @change="checkedAllChange(collectAllList2,checkedAllCollect2)">全选
        </el-checkbox>
      </div>
      <div v-for="item in collectAllList2" class="ergencyLi">
        <el-checkbox v-model="item.checked" @change="checkedRadioChange('发布')">{{ item.name }}</el-checkbox>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="collectDialogVisible2 = false">取消</el-button>
		    		<el-button type="primary" @click="updateDataItemSaveFun(4)">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--显示弹窗-->
    <el-dialog v-model="showDialogVisible" :close-on-click-modal="false" :title="title" class="showDialogVisible"
               width="604px">
      <el-form-item label="分类名称" required>
        <el-select v-model="categoryId" :teleported="false" allow-create filterable @change="categoryNameSelect">
          <el-option v-for="(item,index) in categoryNameList" :key="index" :label="item.name"
                     :value="item.id">
            <span style="float: left">{{ item.name }}</span>
            <el-icon style="float: right;transform: translateY(10px);color: red;" @click.stop="delGroupClick(item.id)">
              <Delete/>
            </el-icon>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="差值" required>
        <el-radio-group v-model="diffFlag">
          <el-radio :value="1">差值</el-radio>
          <el-radio :value="0">读数</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分类数据" required>
        <div class="transferBox">
          <el-transfer v-model="targetList" :data="showAllList" :titles="['未选中', '已选中']" filterable
                       target-order="push"/>
        </div>
      </el-form-item>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="showDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="updateDataItemSaveFun(2)">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--事件弹窗-->
    <el-dialog v-model="eventDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="eventFormRef" :model="eventForm" :rules="eventRules" class="demo-ruleForm" label-width="auto"
               style="width:98%">
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="eventForm.name" placeholder="请输入分类名称"/>
        </el-form-item>
        <el-form-item label="风险等级" prop="level">
          <el-select v-model="eventForm.level" placeholder="请选择类型" style="width:100%">
            <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="checkAllBox">
        <el-checkbox v-model="checkedAllEvent" @change="checkedAllChange(eventAllList,checkedAllEvent)">全选</el-checkbox>
      </div>
      <div v-for="item in eventAllList" class="ergencyLi">
        <el-checkbox v-model="item.checked" @change="checkedRadioChange('事件')">{{ item.name }}</el-checkbox>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="eventDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="levelSave()">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--风险弹窗-->
    <el-dialog v-model="riskDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div class="riskBox" @click="circleHandle(3)">
        <span :class="{'circleSpecil' : circleNumber == 3}" class="circle"></span>
        <span class="riskSign">高风险</span>
      </div>
      <div class="riskBox" @click="circleHandle(2)">
        <span :class="{'circleSpecil' : circleNumber == 2}" class="circle"></span>
        <span class="riskSign2">中风险</span>
      </div>
      <div class="riskBox" @click="circleHandle(1)">
        <span :class="{'circleSpecil' : circleNumber == 1}" class="circle"></span>
        <span class="riskSign3">低风险</span>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="riskDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="riskSave">保存</el-button>
      			</span>
      </template>

    </el-dialog>
    <!--图片弹窗-->
    <el-dialog v-model="imgDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-upload v-model:file-list='imgList' :auto-upload="false" :limit="5" :on-preview="handlePictureCardPreview"
                 :on-remove="handleRemove" accept=".png,.jpg" action="#" list-type="picture-card" @change="uploadFile">
        <el-icon>
          <Plus/>
        </el-icon>
        <span>上传图片</span>
      </el-upload>
      <p>支持jpg、png格式,且图片大小不超过5M 最多上传5张 </p>
      <p>(推荐图片尺寸：254px*254px)</p>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="imgDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="imgSave">保存</el-button>
      			</span>
      </template>

    </el-dialog>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, nextTick, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import Sortable from 'sortablejs';
import {ArrowUpBold, ArrowDownBold, Plus, Edit, CloseBold, Back, ZoomIn, Delete} from '@element-plus/icons-vue'
import {
  getDetail, getDetail2,
  getAllCategoryList,
  getAllParserList,
  getParserItemList,
  saveList,
  getListTypeSelect,
  controlSaveList,
  getControlDetail,
  controlDelete,
  updateCheck,
  getDataItemCheck,
  updateDataItemSave,
  getEventDetail,
  eventSave,
  eventDeleteFun,
  uploadImg,
  saveImg, saveWithProductApi, getDataItemCheckWithProductApi, getProductGroupListApi, delProductGroupListApi
} from '../../api/modules/productDetail'
import './productDetail.less'
import qs from 'qs';
import {saveList2} from "@/api/modules/product";

let router = useRouter()
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 返回
const goBack = function () {
  router.push('/admin/product')
}

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
let isrepair = ref()
let ischeck = ref()
let special1 = ref()
let special2 = ref()
//获取详情列表
const getDetailFun = function () {
  openFullScreen()
  tableData.value = []
  router.currentRoute.value.query.type == 11 ? getDetail2(qs.stringify({
        id: router.currentRoute.value.query.id
      })).then(res => {
        //console.log(res);
        loadingAdd.value.close();
        if (res.data.code === 0) {
          tableData.value.push(res.data.result)
          controlList.value = res.data.result.controlList
          publishList.value = res.data.result.publishList
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      }) :
      getDetail(qs.stringify({
        id: router.currentRoute.value.query.id
      })).then(res => {
        //console.log(res);
        loadingAdd.value.close();
        if (res.data.ok) {
          tableData.value.push(res.data.result)
          isrepair.value = res.data.result.isrepair == 0 ? '否' : '是'
          ischeck.value = res.data.result.ischeck == 0 ? false : true
          special1.value = res.data.result.special1 == 0 ? false : true
          special2.value = res.data.result.special2 == 0 ? false : true
          rulerTypeList.value = res.data.result.rulerTypeList
          rateTypeList.value = res.data.result.rateTypeList
          joinTypeList.value = res.data.result.joinTypeList
          controlList.value = res.data.result.controlList
          collectList.value = res.data.result.collectList
          publishList.value = res.data.result.publishList
          eventList.value = res.data.result.eventList
          showList.value = res.data.result.showList
          showDictList.value = res.data.result.showDictList
          imgList.value = []
          imageList.value = []
          res.data.result.imageList.forEach(item => {
            //					if(item.indexOf("blob") != -1 ){
            //						console.log(item)
            //						item= window.URL.createObjectURL(new Blob([item]))
            //					}
            imgList.value.push({
              url: item
            })
          })
          imageList.value = res.data.result.imageList
          getParserItemList(qs.stringify({
            parserId: tableData.value[0].parserId,
            productId: tableData.value[0].id
          })).then(res => {
            //console.log(res);
            if (res.data.ok) {
              parserItemListCopy.value = res.data.result
              parserItemListCopy.value.forEach(item => {
                if (item.checkFlag == 1) {
                  item.checked = true
                } else {
                  item.checked = false
                }
              })
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
}
let productCategoryAllList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryListFun = function () {
  getAllCategoryList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      productCategoryAllList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let parserList = ref([]) //所有抄读列表
//所有抄读列表
const getAllParserListFun = function () {
  getAllParserList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      parserList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let rulerTypeList = ref([]) //规约类型
let rateTypeList = ref([]) //波特率
let joinTypeList = ref([]) //接线方式
/*
 * 配置实时数据
 * */
let dialogWidth2 = ref('60%') //实时数据弹窗宽度
let nowDataDialogVisible = ref(false) //实时数据弹窗开关
let parserItemList = ref([]) //抄读项列表弹窗
let parserItemListCopy = ref([]) //抄读项列表页面

let parserName = ref() //抄读名称
let checkedAll = ref() //全选
let checkedAll2 = ref() //全选
//实时数据的点击
const setNowData = function () {
  if (productForm.value.parserId) {
    nowDataDialogVisible.value = true
    parserList.value.forEach(item => {
      if (item.id == productForm.value.parserId) {
        parserName.value = item.name
      }
    })
    getParserItemList(qs.stringify({
      parserId: productForm.value.parserId,
      productId: tableData.value[0].id
    })).then(res => {
      console.log(res);
      if (res.data.ok) {
        parserItemList.value = res.data.result
        parserItemList.value.forEach(item => {
          if (item.checkFlag == 1) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      message: '请先选择抄读方式',
      type: 'info'
    })
  }
}
//全选、全不选
const checkedAllChange = function (val, checkedVal) {
  if (checkedVal == true) {
    val.forEach(item => {
      item.checked = true
    })
  } else {
    val.forEach(item => {
      item.checked = false
    })
  }
}
//单选
const checkedRadioChange = function (val) {
  if (val == '采集') {
    var checkedArr = []
    collectAllList.value.forEach(item => {
      if (item.checked == true) {
        checkedArr.push(item)
      }
    })
    if (checkedArr.length == collectAllList.value.length) {
      checkedAllCollect.value = true
    } else {
      checkedAllCollect.value = false
    }
  } else if (val == '发布') {
    var checkedArr2 = []
    collectAllList2.value.forEach(item => {
      if (item.checked == true) {
        checkedArr2.push(item)
      }
    })
    if (checkedArr2.length == collectAllList2.value.length) {
      checkedAllCollect2.value = true
    } else {
      checkedAllCollect2.value = false
    }
  } else if (val == '实时数据项') {
    var checkedArr = []
    parserItemList.value.forEach(item => {
      if (item.checked == true) {
        checkedArr.push(item)
      }
    })
    if (checkedArr.length == parserItemList.value.length) {
      checkedAll.value = true
    } else {
      checkedAll.value = false
    }
  } else if (val == '事件') {
    var checkedArr = []
    eventAllList.value.forEach(item => {
      if (item.checked == true) {
        checkedArr.push(item)
      }
    })
    if (checkedArr.length == eventAllList.value.length) {
      checkedAllEvent.value = true
    } else {
      checkedAllEvent.value = false
    }
  }
}
let dialogWidth = ref('40%') //新增产品弹窗宽度
let productDialogVisible = ref(false) //新增产品弹窗开关
let editId = ref() //编辑的id
//编辑产品的点击
const editClick = function () {
  if (router.currentRoute.value.query.type == 11) {
    productDialogVisible.value = true
    categoryType.value = tableData.value[0].categoryShowType
    editId.value = tableData.value[0].id
    productForm.value.categoryId = tableData.value[0].categoryId
    productForm.value.model = tableData.value[0].model
    productForm.value.productCode = tableData.value[0].productCode
  } else {
    categoryType.value = tableData.value[0].categoryShowType
    productDialogVisible.value = true
    editId.value = tableData.value[0].id
    productForm.value.categoryId = tableData.value[0].categoryId
    productForm.value.retainSoc = tableData.value[0].retainSoc
    productForm.value.maxPower = tableData.value[0].maxPower
    productForm.value.applyCapacity = tableData.value[0].applyCapacity
    productForm.value.rechargeDictKey = tableData.value[0].rechargeDictKey
    productForm.value.generateDictKey = tableData.value[0].generateDictKey
    productForm.value.model = tableData.value[0].model
    productForm.value.rulerType = String(tableData.value[0].rulerType)
    productForm.value.password = tableData.value[0].password
    productForm.value.feeNum = tableData.value[0].feeNum
    productForm.value.userType = tableData.value[0].userType
    productForm.value.rateType = String(tableData.value[0].rateType)
    productForm.value.joinType = String(tableData.value[0].joinType)
    productForm.value.ratedV = tableData.value[0].ratedV
    productForm.value.ratedA = tableData.value[0].ratedA
    productForm.value.pCode = tableData.value[0].pCode
    productForm.value.parserId = tableData.value[0].parserId
    getParserItemList(qs.stringify({
      parserId: tableData.value[0].parserId,
      productId: tableData.value[0].id,
    })).then(res => {
      //console.log(res);
      if (res.data.ok) {
        parserItemList.value = res.data.result
        parserItemList.value.forEach(item => {
          if (item.checkFlag == 1) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
        parserItemListCopy.value = parserItemList.value
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
let productFormRef = ref() //产品
//产品表单
let productForm = ref({
  categoryId: '',
  retainSoc: '',
  maxPower: '',
  applyCapacity: '',
  rechargeDictKey: '',
  generateDictKey: '',
  model: '',
  rulerType: '',
  password: '123456020000',
  feeNum: '',
  userType: '',
  rateType: '',
  joinType: '',
  ratedV: '',
  ratedA: '',
  pCode: '000000000000',
  parserId: ''
})
// 产品表单验证规则
let productRules = ref({
  categoryId: [{
    required: true,
    message: '请选择分类',
    trigger: 'blur'
  }],
  model: [{
    required: true,
    message: '请输入型号',
    trigger: 'blur'
  }],
  // rulerType: [{
  //   required: true,
  //   message: '请选择规约类型',
  //   trigger: 'blur'
  // }],
  // password: [{
  //   required: true,
  //   message: '请输入通讯密码',
  //   trigger: 'blur'
  // }],
  // feeNum: [{
  //   required: true,
  //   message: '请输入费率个数',
  //   trigger: 'blur'
  // }],
  // userType: [{
  //   required: true,
  //   message: '请输入用户类型',
  //   trigger: 'blur'
  // }],
  // rateType: [{
  //   required: true,
  //   message: '请选择波特率',
  //   trigger: 'blur'
  // }],
  // joinType: [{
  //   required: true,
  //   message: '请选择接线方式',
  //   trigger: 'blur'
  // }],
  // ratedV: [{
  //   required: true,
  //   message: '请输入额定电压',
  //   trigger: 'blur'
  // }],
  // ratedA: [{
  //   required: true,
  //   message: '请输入额定电流',
  //   trigger: 'blur'
  // }],
  // pCode: [{
  //   required: true,
  //   message: '请输入资产号',
  //   trigger: 'blur'
  // }],
  // parserId: [{
  //   required: true,
  //   message: '请选择抄读方式',
  //   trigger: 'blur'
  // }]
})
//产品的保存
const productSave = function () {
  var arr = []
  if (parserItemList.value && parserItemList.value.length > 0) {
    parserItemList.value.forEach(item => {
      if (item.checked) {
        arr.push(item.id)
      }
    })
  }
  if (categoryType.value != 5) {
    productForm.value.retainSoc = ''
    productForm.value.maxPower = ''
    productForm.value.applyCapacity = ''
    productForm.value.rechargeDictKey = ''
    productForm.value.generateDictKey = ''
  }
  let params = {
    id: editId.value,
    categoryId: productForm.value.categoryId,
    productCode: productForm.value.productCode,
    retainSoc: productForm.value.retainSoc,
    maxPower: productForm.value.maxPower,
    applyCapacity: productForm.value.applyCapacity,
    rechargeDictKey: productForm.value.rechargeDictKey,
    generateDictKey: productForm.value.generateDictKey,
    model: productForm.value.model,
    rulerType: productForm.value.rulerType,
    password: productForm.value.password,
    feeNum: productForm.value.feeNum,
    userType: productForm.value.userType,
    rateType: productForm.value.rateType,
    joinType: productForm.value.joinType,
    ratedV: productForm.value.ratedV,
    ratedA: productForm.value.ratedA,
    pCode: productForm.value.pCode,
    parserId: productForm.value.parserId,
    parserItemIdList: arr.toString()
  }
  if (productFormRef) {
    productFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        router.currentRoute.value.query.type == 11 ? saveList2(
                qs.stringify(params)
            ).then(async res => {
              //console.log(res);
              if (res.data.ok) {
                productDialogVisible.value = false
                getDetailFun()
              } else {
                ElMessage({
                  message: res.data.message,
                  type: 'error'
                })
              }
            }).catch(err => {
              console.log(err);
            }) :
            saveList(
                qs.stringify(params)
            ).then(async res => {
              //console.log(res);
              if (res.data.ok) {
                productDialogVisible.value = false
                getDetailFun()
              } else {
                ElMessage({
                  message: res.data.message,
                  type: 'error'
                })
              }
            }).catch(err => {
              console.log(err);
            })
      }
    })
  }
} /*是否补抄、特殊计算显示项、控制校时************/
const radioClick = function (type) {
  let params = {
    id: tableData.value[0].id,
    type: type
  }
  updateCheck(
      qs.stringify(params)
  ).then(async res => {
    //console.log(res);
    if (res.data.ok) {
      getDetailFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
/*控制*****************************/
let title = ref() //标题
let controlDialogVisible = ref(false) //控制弹窗开关
let controlFormRef = ref() //控制
let typeList = ref([]) //控制类型列表
let controlList = ref([]) //控制列表
let collectList = ref([]) //采集列表
let publishList = ref([]) //发布列表
let eventList = ref([]) //事件列表
let showList = ref([]) //显示列表
let showDictList = ref([]) //显示分组列表
let controlChecked = ref(true)
//控制表单
let controlForm = ref({
  name: '',
  dataCode: '',
  item: '',
  ext: '',
  unit: '',
  type: '',
})
// 控制表单验证规则
let controlRules = ref({
  name: [{
    required: true,
    message: '请输入控制名称',
    trigger: 'blur'
  }],
  dataCode: [{
    required: true,
    message: '请输入数据标识',
    trigger: 'blur'
  }],
  item: [{
    required: true,
    message: '请输入枚举值',
    trigger: 'blur'
  }]
})
//控制新增的点击
const controlClick = function () {
  title.value = '新建控制'
  controlDialogVisible.value = true
  controlId.value = ''
  controlForm.value = {}
}
//控制类型列表
const getListTypeSelectFun = function () {
  getListTypeSelect(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      typeList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//控制的保存
const controlSave = function (id) {
  var params = {
    productId: tableData.value[0].id,
    name: controlForm.value.name,
    dataCode: controlForm.value.dataCode,
    item: controlForm.value.item,
    ext: controlForm.value.ext,
    unit: controlForm.value.unit,
    type: controlForm.value.type
  }
  if (controlFormRef) {
    controlFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新建控制') {
          params['id'] = 0
        } else if (title.value == '编辑控制') {
          params['id'] = controlId.value
        }
        controlSaveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            controlDialogVisible.value = false
            getDetailFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
let controlId = ref() //控制id
//控制编辑的点击
const getControlDetailFun = function (id) {
  controlDialogVisible.value = true
  title.value = '编辑控制'
  getControlDetail(qs.stringify({
    id: id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      controlId.value = res.data.result.id
      controlForm.value.name = res.data.result.name
      controlForm.value.dataCode = res.data.result.dataCode
      controlForm.value.item = res.data.result.item
      controlForm.value.ext = res.data.result.ext
      controlForm.value.unit = res.data.result.unit
      controlForm.value.type = String(res.data.result.type)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//控制删除
const contrlDelete = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除控制',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此控制，删除后数据不可恢复'),
    ])
  }).then(() => {
    controlDelete(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getDetailFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
/*采集******************************/
let collectAllList = ref([]) //所有采集列表
let collectAllList2 = ref([]) //所有发布列表
let checkedAllCollect = ref(false) //采集全选
let checkedAllCollect2 = ref(false) //采集全选
let collectDialogVisible = ref(false)
let collectDialogVisible2 = ref(false)
let collectChecked = ref(true)
const collectClick = function () {
  collectDialogVisible.value = true
  title.value = '采集设置'
  getDataItemCheckFun(1)
  checkedAllCollect.value = false
}
const collectClick2 = function () {
  collectDialogVisible2.value = true
  getDataItemCheckFun2(4)
}
const getDataItemCheckFun = function (type) {
  getDataItemCheck(qs.stringify({
    id: tableData.value[0].id,
    type: type
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      res.data.result.list.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      res.data.result.checkList.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      collectAllList.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const getDataItemCheckFun2 = function (type) {
  getDataItemCheck(qs.stringify({
    id: tableData.value[0].id,
    type: type
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      res.data.result.list.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      res.data.result.checkList.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      collectAllList2.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 显示分组
let categoryId = ref()
let categoryName = ref()
let categoryNameList = ref([])
const categoryNameSelect = function (e) {
  let flag = false
  for (let i = 0; i < categoryNameList.value.length; i++) {
    if (categoryNameList.value[i].id == e) {
      flag = true
      categoryName.value = categoryNameList.value[i].name
    }
  }
  if (flag) {
    getDataItemCheckWithEquipmentFun(e)
  } else {
    getDataItemCheckWithEquipmentFun(0)
  }

}
let diffFlag = ref()
//采集,发布、显示保存
const updateDataItemSaveFun = function (type) {
  let dictIdList;
  let arr = [];
  if (type == 1) {
    collectAllList.value.forEach(item => {
      if (item.checked) {
        arr.push(item.id)
      }
    })
    dictIdList = arr.toString()
  } else if (type == 2) {
    arr = targetList.value
  } else if (type == 4) {
    collectAllList2.value.forEach(item => {
      if (item.checked) {
        arr.push(item.id)
      }
    })
    dictIdList = arr.toString()
  }
  dictIdList = arr.toString()
  //console.log(dictIdList)
  let params = {
    id: tableData.value[0].id,
    type: type,
    dictIdList: dictIdList
  }
  if (type == 1 || type == 4) {
    updateDataItemSave(qs.stringify(params)).then(res => {
      //console.log(res);
      if (res.data.ok) {
        if (type == 1) {
          collectDialogVisible.value = false
        } else if (type == 2) {
          showDialogVisible.value = false
        } else if (type == 4) {
          collectDialogVisible2.value = false
        }
        getDetailFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    let groupId = 0
    for (let i = 0; i < categoryNameList.value.length; i++) {
      if (categoryNameList.value[i].id == categoryId.value) {
        groupId = categoryId.value
      }
    }
    saveWithProductApi(qs.stringify({
      id: groupId,
      productId: router.currentRoute.value.query.id,
      name: groupId !== 0 ? categoryName.value : categoryId.value,
      diffFlag: diffFlag.value,
      dictIdList: dictIdList
    })).then(res => {
      //console.log(res);
      if (res.data.ok) {
        if (type == 1) {
          collectDialogVisible.value = false
        } else if (type == 2) {
          showDialogVisible.value = false
        }
        getDetailFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
/*显示*********************************/
let showAllList = ref([]) //所有显示列表
let showDialogVisible = ref(false)
// 获取显示穿梭框的数据
const getDataItemCheckWithEquipmentFun = function (id) {
  getDataItemCheckWithProductApi(qs.stringify({
    productId: tableData.value[0].id,
    id: id
  })).then(res => {
    if (res.data.code === 0) {
      res.data.result.list.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      res.data.result.checkList.forEach(item => {
        item.key = item.id
        item.label = item.name
        if (item.checkFlag == 1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      // 处理穿梭框未选中数据
      showAllList.value = []
      res.data.result.list.forEach(item => {
        collectList.value.forEach(items => {
          if (item.name == items) {
            showAllList.value.push(item)
          }
        })
      })
      // 处理穿梭框选中的数据
      var arr = []
      res.data.result.checkList.forEach(item => {
        arr.push(item.id)
      })
      targetList.value = arr
      diffFlag.value = res.data.result.diffFlag
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取分组的下拉列表
const getProductGroupListFun = function () {
  getProductGroupListApi(qs.stringify({
    mainId: tableData.value[0].id,
    type: 1
  })).then(res => {
    if (res.data.code === 0) {
      categoryNameList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const showClick = function () {
  categoryId.value = ''
  showDialogVisible.value = true
  title.value = '显示设置'
  nextTick(() => {
    var el = document.querySelectorAll('.el-checkbox-group')[1];
    var sortable = Sortable.create(el, {
      onEnd: function (evt) {
        const movedItem = targetList.value.splice(evt.oldIndex, 1)[0];
        targetList.value.splice(evt.newIndex, 0, movedItem);
      }
    });
  })
  // 获取分组的下拉列表
  // 获取显示穿梭框的数据
  getDataItemCheckWithEquipmentFun(0)
  getProductGroupListFun()
}
const generateData = () => {
  const data = []
  for (let i = 1; i <= 15; i++) {
    data.push({
      key: i,
      label: `Option ${i}`,
    })
  }
  return data
}
const data = ref(generateData())
const targetList = ref([])
// 删除显示分组
const delGroupClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除分组',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此分组，删除后数据不可恢复'),
    ])
  }).then(() => {
    delProductGroupListApi(qs.stringify({id: id})).then(res => {
      if (res.data.ok) {
        getProductGroupListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
/*事件***************************/
let eventAllList = ref([]) //所有事件列表
let checkedAllEvent = ref(false)
let eventDialogVisible = ref(false)

let eventFormRef = ref() //事件
let levelList = ref([{
  name: '无风险',
  id: 0
},
  {
    name: '低风险',
    id: 1
  },
  {
    name: '中风险',
    id: 2
  },
  {
    name: '高风险',
    id: 3
  },
]) //风险等级列表
//事件表单
let eventForm = ref({
  name: '',
  level: ''
})
// 事件表单验证规则
let eventRules = ref({
  name: [{
    required: true,
    message: '请输入分类名称',
    trigger: 'blur'
  }],
  level: [{
    required: true,
    message: '请选择风险等级',
    trigger: 'blur'
  }],
})
let eventId = ref() //事件的id
//事件的点击
const eventClick = function (id) {
  eventDialogVisible.value = true
  if (id) {
    eventId.value = id
    title.value = '编辑事件'
    getEventDetail(
        qs.stringify({
          productId: tableData.value[0].id,
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        eventAllList.value = res.data.result.dictList
        eventAllList.value.forEach(item => {
          if (item.checkFlag) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
        eventForm.value.name = res.data.result.name
        eventForm.value.level = res.data.result.level
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    eventForm.value = {}
    eventId.value = ''
    title.value = '添加事件'
    getEventDetail(
        qs.stringify({
          productId: tableData.value[0].id,
          id: 0
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        eventAllList.value = res.data.result.dictList
        eventAllList.value.forEach(item => {
          if (item.checkFlag) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//事件的删除
const eventDelete = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除事件',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此事件，删除后数据不可恢复'),
    ])
  }).then(() => {
    eventDeleteFun(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getDetailFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
//事件的保存
const levelSave = function () {
  let dictIdList;
  let arr = [];
  eventAllList.value.forEach(item => {
    if (item.checked) {
      arr.push(item.id)
    }
    dictIdList = arr.toString()
  })
  if (arr && arr.length > 0) {
    var params = {
      productId: tableData.value[0].id,
      type: 1,
      name: eventForm.value.name,
      level: eventForm.value.level,
      dictIdList: dictIdList
    }
    if (eventFormRef) {
      eventFormRef.value.validate((valid) => {
        //提交成功
        if (valid) {
          if (eventId.value) {
            params['id'] = eventId.value
          } else {
            params['id'] = 0
          }
          eventSave(
              qs.stringify(params)
          ).then(async res => {
            //console.log(res);
            if (res.data.ok) {
              eventDialogVisible.value = false
              getDetailFun()
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      })
    }
  } else {
    ElMessage({
      message: '请至少选择一个字典',
      type: 'info'
    })
  }
}

/*风险****************************/
let riskDialogVisible = ref(false) //风险弹窗显示
//风险的保存
const riskSave = function () {

}
//风险的点击
const riskClick = function () {
  riskDialogVisible.value = true
  title.value = "风险"
}
let circleNumber = ref() //圆圈高亮值
//圆圈的点击
const circleHandle = function (val) {
  circleNumber.value = val
}
/*图片*/
let imgList = ref([]) //图片列表
let imageList = ref([]) //图片列表
let imgDialogVisible = ref(false) //图片弹窗
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//上传文件
const uploadFile = function (file) {
  if ((file.size / 1024 / 1024) > 5) {
    ElMessage({
      message: '图片大小不能超过5M!',
      type: 'info'
    })
    imgList.value.splice(imgList.value.length - 1, 1)
  } else {
    let fd = new FormData();
    //注意是文件流file.raw
    fd.append('file', file.raw); //传文件
    uploadImg(
        fd
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        imgList.value[imgList.value.length - 1].url = res.data.result.fullPath
        if (imgList.value.length > 4) {
          document.querySelector('.el-upload--picture-card').style.display = 'none'
        } else {
          document.querySelector('.el-upload--picture-card').style.display = 'flex'
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//设置图片的点击
const imgClick = function () {
  imgDialogVisible.value = true
  title.value = '设置图片'
  getDetailFun()
  nextTick(() => {

    document.querySelector('.el-upload--picture-card').style.display = 'flex'
  })
}
//删除图片
const handleRemove = (file) => {
  if (imgList.value.length > 4) {
    document.querySelector('.el-upload--picture-card').style.display = 'none'
  } else {
    document.querySelector('.el-upload--picture-card').style.display = 'flex'
  }
}
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}
const handlePictureCardPreview2 = (file) => {
  dialogImageUrl.value = file
  dialogVisible.value = true
}

//图片的保存
const imgSave = function () {
  var arr = []
  imgList.value.forEach(item => {
    arr.push(item.url)
  })
  saveImg(
      qs.stringify({
        id: router.currentRoute.value.query.id,
        imageList: arr.join(',')
      })
  ).then(res => {
    //console.log(res);
    if (res.data.ok) {
      getDetailFun()
      imgDialogVisible.value = false
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let categoryType = ref() //分类类型
const categoryIdChange = function () {
  productCategoryAllList.value.forEach(item => {
    if (productForm.value.categoryId == item.id) {
      categoryType.value = item.showType
    }
  })
}
/*生命周期*/
onMounted(() => {
  getDetailFun()
  getAllCategoryListFun()
  getAllParserListFun()
  getListTypeSelectFun()
})
</script>
