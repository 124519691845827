<template>
  <div class="login">
    <div
        :style="{backgroundImage:bgcImg?`url(${bgcImg})`:'url(https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/login.png)'}"
        class="bg"></div>
    <!--    <img alt="" src="https://images.tuyacn.com/zcdp-web/images/1678873879dda3be7cd9f.png">-->
    <div class="rightBox">
      <!--登陆-->
      <div v-if="!flag" class="cont1 cont">
        <p class="title">数字能源中心</p>
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" class="demo-ruleForm" status-icon>
          <el-form-item label="账号" prop="account">
            <el-input v-model="loginForm.account" :prefix-icon="User" placeholder="请输入账号"/>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="loginForm.password" :prefix-icon="Lock" placeholder="请输入密码" show-password
                      type="password"/>
          </el-form-item>
          <el-form-item label="" prop="" style="padding-top: 8px">
            <!--            <el-checkbox v-model="loginForm.password" label="" size="default" style="margin-right:8px"/>-->
            <!--            <span style="margin-right:8px">阅读并同意</span>-->
            <!--            <span style="color:rgb(24, 144, 255);margin-right:8px">《服务条款》</span>-->
            <!--            <span style="color:rgb(24, 144, 255);margin-right:8px">《法律声明》</span>-->
            <!--            <span style="color:rgb(24, 144, 255);margin-right:8px">《隐私政策》</span>-->
          </el-form-item>
          <el-form-item>
            <el-button class="submit" type="primary" @click="loginClick">登 陆</el-button>
          </el-form-item>
        </el-form>
        <!--本地版不展示-->
        <p class="forget" @click="forgetClick">忘记密码?</p>
      </div>
      <!--找回密码-->
      <div v-if="flag" class="cont2 cont">
        <div class="title">
          <el-icon @click="back">
            <Back/>
          </el-icon>
          <span>找回密码</span>
        </div>
        <el-form ref="forgetFormRef" :model="forgetForm" :rules="forgetRules" class="demo-ruleForm" status-icon>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="forgetForm.mobile" :prefix-icon="User" placeholder="请输入手机号"/>
          </el-form-item>
          <el-form-item label="获取验证码" prop="code">
            <el-input v-model="forgetForm.code" :prefix-icon="Message" placeholder="验证码"/>
            <el-button class="getCode" @click="getCode">获取验证码</el-button>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input v-model="forgetForm.password" :prefix-icon="Lock" placeholder="请输入新密码" show-password
                      type="password"/>
          </el-form-item>
          <el-form-item label="确认新密码" prop="againPassword">
            <el-input v-model="forgetForm.againPassword" :prefix-icon="Lock" placeholder="请再次输入新密码" show-password
                      type="password"/>
          </el-form-item>
          <el-form-item>
            <el-button class="submit" type="primary" @click="resetSubmit">重置密码</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Vcode :show="isShow" @close="onClose" @success="onSuccess"/>
    <!--宏恩底部-->
    <div v-if="http2==='https://api.hezy.com.cn/'" class="footer"
         style="display: flex;position:absolute;bottom:10px;right:30px;">
      <p>页面版权所有：吴江市宏恩节能电气技术有限公司</p>
      <p style="margin-left: 30px">备案号：<a href="https://beian.miit.gov.cn" style="cursor: pointer" target="_blank">苏ICP备2023019276号-3</a>
      </p>
      <p style="margin-left: 30px">域名：iot.hezy.com.cn</p>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {User, Lock, Back, Message} from '@element-plus/icons-vue'
import {useRouter} from 'vue-router';
import {ElMessage} from "element-plus";
import Vcode from "vue3-puzzle-vcode";
import {loginApi, forgetApi, sendCodeApi, loginApi2, getImageConfigApi} from '@/api/modules/login.js'
import qs from "qs";
import {http2} from "@/api";

let router = useRouter()

// 是否展示忘记密码
let flag = ref(false)

// 登陆操作-------------------------------------------------------------
// 登陆表单数据
let loginForm = ref({
  account: '',
  password: '',
})

// 登陆表单验证规则
let loginRules = ref({
  account: [{
    required: true,
    message: '请输入账号',
    trigger: 'blur'
  },],
  password: [{
    required: true,
    message: '请输入密码',
    trigger: 'blur'
  },],
})

// 登陆验证-------------------------------------------------------------
// 是否展示滑动验证
let isShow = ref(false)
const loginClick = () => {
  isShow.value = true;
};

const onClose = () => {
  isShow.value = false;
};

// 表单元素
let loginFormRef = ref()
// 验证成功
const onSuccess = () => {
  // 提交表单
  if (loginFormRef) {
    loginFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        // loginApi(qs.stringify(loginForm.value)).then(res => {
        //   if (res.data.code === 1) {
        //     window.localStorage.setItem('Authorization', res.data.result.access_token)


        loginApi2(qs.stringify(loginForm.value)).then(res => {
          onClose(); // 验证成功，手动关闭模态框
          if (res.data.code === 0) {
            router.push('/admin')
            window.localStorage.setItem('memberId', res.data.result.id)
            window.localStorage.setItem('menuList', JSON.stringify(res.data.result.menuList))
            window.localStorage.setItem('Authorization', res.data.result.token)
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })

        //   } else {
        //     ElMessage({
        //       message: res.data.message,
        //       type: 'error'
        //     })
        //   }
        // }).catch(err => {
        //   console.log(err);
        // })
      }
    })
  }
};

// 忘记密码
const forgetClick = function () {
  flag.value = true
}

// 找回密码操作-------------------------------------------------------------
// 找回密码表单数据
let forgetForm = ref({
  mobile: '',
  code: '',
  password: '',
  againPassword: ''
})
// 找回密码表单验证规则
let againPass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== forgetForm.value.password) {
    callback(new Error('两次输入密码不一致!'))
  } else {
    callback()
  }
}
//密码验证规则
// 密码校验 长度不能小于8位且不能大于20位字符,必须包含大写字母、小写字母、数字和特殊符号
let ISPWD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._\+(){}])[0-9a-zA-Z!@#$%^&*,\\._\+(){}]{8,20}$/;
let passwordValidator = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入新密码'))
  } else if (!ISPWD.test(value)) {
    callback(new Error('密码长度 8-20 位，至少包含以下四种字符类型（数字、大写字母、小写字母、英文符号）'))
  } else {
    callback()
  }
}
// 编辑新建管理员的表单规则
let forgetRules = ref({
  mobile: [{
    required: true,
    message: '请输入手机号',
    trigger: 'blur'
  }, {
    message: '请输入有效的手机号码',
    pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
    trigger: 'blur'
  }],
  code: [{
    required: true,
    trigger: 'blur',
    message: '请输入验证码'
  },],
  password: [{
    required: true,
    trigger: 'blur',
    validator: passwordValidator
  },],
  againPassword: [{
    required: true,
    trigger: "blur",
    validator: againPass
  }]
})
// 表单元素
let forgetFormRef = ref()
// 点击发送验证码
const getCode = function () {
  forgetFormRef.value.validateField('mobile', () => {
    sendCodeApi(qs.stringify({
      mobile: forgetForm.value.mobile
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          message: '已发送验证码',
          type: 'success',
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error',
        })
      }
    }).catch(err => {
      console.log(err);
    })
  })
}
// 找回密码表单提交
const resetSubmit = function () {
  if (forgetFormRef) {
    forgetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        forgetApi(qs.stringify(forgetForm.value)).then(res => {
          if (res.data.code === 0) {
            flag.value = false
            ElMessage({
              message: '重置密码成功，请重新登陆',
              type: 'success',
            })
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error',
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
// 返回
const back = function () {
  flag.value = false
}


let bgcImg = ref()
onMounted(() => {
  // 本地版打开
  getImageConfigApi().then(res => {
    if (res.data.code === 0) {
      bgcImg.value = res.data.result.loginImage
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less">
.login {
  min-height: 700px;
  width: 100vw;
  height: 100vh;
  display: flex;

  .bg {
    width: 60%;
    //height: 700px;
    //background: url("https://images.tuyacn.com/zcdp-web/images/1678873879dda3be7cd9f.png") no-repeat 0 0; //得力
    background: url("https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/login.png") no-repeat center center; //线上
    background-size: 95% 90%;
  }

  .rightBox {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .cont {
      transform: translateY(-30px);

      .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 56px;
      }

      .el-form {
        .el-form-item {
          display: block;
          margin-bottom: 24px;

          .submit {
            width: 100%;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
          }
        }

        .el-input {
          height: 40px;
          line-height: 40px;

          .el-icon {
            font-size: 16px !important;
            color: rgba(0, 0, 0);
          }
        }

        .el-form-item.is-error .el-icon {
          color: red;
        }

        ::placeholder {
          font-size: 16px;
        }
      }

      .forget {
        color: #409eff;
        cursor: pointer;
      }
    }

    .cont1 {
      width: 400px;
      height: 419px;

      .el-form-item:nth-child(3) {
        margin-bottom: 13px;
      }

      .el-form-item:nth-child(4) {
        margin-bottom: 8px;
      }
    }

    .cont2 {
      width: 400px;
      height: 522px;

      .title {
        .el-icon {
          transform: translateY(3px);
          margin-right: 10px;
          color: black;
          cursor: pointer;
        }
      }

      .el-form {
        .el-form-item:nth-child(2) {
          .el-input {
            width: 280px;
          }

          .getCode {
            width: 112px;
            height: 40px;
            margin-left: 8px;
          }
        }

        .submit {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
