import {api} from "@/api";

// 获取电费查询图表
export const getEleChargeChartApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/powerFeeRecord/getReportData',
        url: '/zouk/rest/powerFeeRecordV2/getReportData',
        method: 'POST',
        data: data
    })
}
// 获取电费查询表格
export const getEleChargeTableApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/powerFeeRecord/getTableData',
        url: '/zouk/rest/powerFeeRecordV2/getTableData',
        method: 'POST',
        data: data
    })
}


// 获取电量查询图表
export const getEleChartApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/powerFeeRecord/power/getReportData',
        url: '/zouk/rest/powerFeeRecordV2/power/getReportData',
        method: 'POST',
        data: data
    })
}
// 获取电量查询表格
export const getEleTableApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/powerFeeRecord/power/getTableData',
        url: '/zouk/rest/powerFeeRecordV2/power/getTableData',
        method: 'POST',
        data: data
    })
}
// 获取项目树-id带前缀
export const getTreePrefixApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentTree/getTreePrefix',
        method: 'POST',
        data: data
    })
}
// 获取顶级项目树-带前缀
export const getTopTreePrefixApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTopTreePrefix',
        method: 'POST',
        data: data
    })
}
// 获取顶级项目树-带前缀
export const getSubTreePrefixApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getSubTreePrefix',
        method: 'POST',
        data: data
    })
}

// 溯源设备使用
export const traceWithEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/traceWithEquipment',
        method: 'POST',
        data: data
    })
}

// 溯源项目使用
export const traceWithProjectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/traceWithProject',
        method: 'POST',
        data: data
    })
}
