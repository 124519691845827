import {api} from "@/api";

// 获取设备列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/page',
        method: 'POST',
        data: data
    })
}

//获取所有分类列表
export const getAllCategory = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listAll',
        method: 'POST',
        data: data
    })
}
//获取产品列表
export const getProductList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/v3/listSelect',
        method: 'POST',
        data: data
    })
}
//获取产品列表 --  mqtt
export const getMqttProductList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/listMqttType',
        method: 'POST',
        data: data
    })
}
//获取select
export const getSelectList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getSelect',
        method: 'POST',
        data: data
    })
}
//获取网关select
export const getGatewayList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/gateway/listSelect',
        method: 'POST',
        data: data
    })
}
//获取设备详情
export const getDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDetail',
        method: 'POST',
        data: data
    })
}
//获取设备详情  --  mqtt
export const getMqttDetail = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentMqtt/getDetail',
        method: 'POST',
        data: data
    })
}

//新增、编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/save',
        method: 'POST',
        data: data
    })
}
//新增、编辑的保存
export const saveMqttList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentMqtt/save',
        method: 'POST',
        data: data
    })
}

//删除设备
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/delete',
        method: 'POST',
        data: data
    })
}
export const batchDeleteApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/batchDelete',
        method: 'POST',
        data: data
    })
}


//删除设备 --- mqtt
export const deleteMqttList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentMqtt/delete',
        method: 'POST',
        data: data
    })
}
//获取费率配置选项
export const powerGridRateList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerGridRate/listSelect',
        method: 'POST',
        data: data
    })
}

// 编辑本地直连设备的电网费率
export const updatePowerGridRateIdApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentLocal/updatePowerGridRateId',
        method: 'POST',
        data: data
    })
}


// 导入设备
export const importDeviceList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/importGatewayEquipment',
        method: 'POST',
        data: data
    })
}

// 导入设备   ---   mqtt
export const importMqttDeviceList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentMqtt/import',
        method: 'POST',
        data: data
    })
}
