<template>
  <div class="deviceCheckComponent">
    <el-dialog v-model="deviceVisible" :close-on-click-modal="false" class="bindDialog" title="选择设备" width="80%">
      <div class="dialogCont">
        <div class="leftBox">
          <div class="head">待选项:</div>
          <div class="leftBoxCont">
            <div class="transferContentLeft">
              <el-tree ref="treeRef" :current-node-key="currentNodeKey"
                       :default-expanded-keys="defaultExpandKeys"
                       :expand-on-click-node="false" :load="treeList"
                       :props="{label: 'name',children: 'subList',isLeaf:'noExistSub'}"
                       lazy node-key="id"
                       @node-click="handleNodeClick">
              </el-tree>
            </div>
            <div class="transferContRight">
              <el-popover :visible="checkAllVisible" :width="224" placement="top">
                <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                  <div style="display:flex;align-items: center;">确定要添加全部设备吗？</div>
                  <div style="display:flex;justify-content: end;height: 32px;">
                    <el-button size="small" @click="checkAllVisible = false">取消</el-button>
                    <el-button size="small" type="primary" @click="equipmentSelectAllClick">确定</el-button>
                  </div>
                </div>
                <template #reference>
                  <!--                  <p @click="checkAllVisible=true">全选</p>-->
                  <el-button v-show="isMultiple" type="primary" @click="checkAllVisible=true">全选</el-button>
                </template>
              </el-popover>
              <p v-for="(item,index) in equipmentSelectList" :key="index"
                 :style="{cursor:item.enableFlag==1?'no-drop':'pointer'}"
                 @click="addEquipmentFun(item)">
                {{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="head">已选中的设备:</div>
          <div class="deviceBox">
            <p v-for="(item,index) in checkList" :key="index">
              <span>{{ item.name }}</span>
              <el-icon @click="delEquipmentFun(index)">
                <CircleCloseFilled/>
              </el-icon>
            </p>
          </div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addDeviceFun">添加</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, nextTick, onMounted, reactive, ref, watch} from "vue"
import {getTreePrefixApi} from "@/api/modules/eleChargeQuery";
import qs from "qs";
import {ElMessage} from "element-plus";
import {getProjectSubTreeApi, getProjectTopTreeApi} from "@/api/modules/project";
import {CircleCloseFilled} from '@element-plus/icons-vue'

const props = defineProps(['deviceVisible', 'checkDeviceList', 'isMultiple'])
const emit = defineEmits(['deviceVisibleFun', 'checkDeviceListFun']);
const deviceVisible = ref()


// 绑定设备操作-------------------------------------------------------------------
// 点击新增设备
let checkList = ref([])   //选中的设备
let dialogVisible = ref(false)  //时间段弹窗
// 点击添加
const addEquipmentFun = function (item) {
  let flag = false
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkList.value[i].id === item.id) {
      flag = true
    }
  }
  if (!flag) {
    if (isMultiple.value) {
      checkList.value.push({
        id: item.id,
        name: item.name
      })
    } else {
      if (checkList.value.length === 0) {
        checkList.value.push({
          id: item.id,
          name: item.name
        })
      } else {
        ElMessage({
          type: 'info',
          message: '最多只能选择一个设备'
        })
      }
    }
  } else {
    ElMessage({
      type: 'info',
      message: '该设备已选中'
    })
  }
}
const delEquipmentFun = function (idx) {
  checkList.value.splice(idx, 1)
}
// 全选应用设备
let checkAllVisible = ref(false)
const equipmentSelectAllClick = function () {
  for (let i = 0; i < equipmentSelectList.value.length; i++) {
    addEquipmentFun(equipmentSelectList.value[i])
  }
  checkAllVisible.value = false
}
// 点击将设备添加到选择框内
const addDeviceFun = function () {
  dialogVisible.value = false
  emit('deviceVisibleFun', false)
  emit('checkDeviceListFun', checkList.value)
}
// 树级列表
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let defaultExpandKeys = ref() //默认选中树级列表第一级
let treeRef = ref()
// 项目树懒加载-------------------
const treeList = async function (node, resolve) {
  if (node.level === 0) {
    await getProjectTopTreeApi().then(res => {
      if (res.data.code === 0) {
        handleNodeClick(res.data.result)
        defaultExpandKeys.value = [res.data.result.id]
        currentNodeKey.value = res.data.result.id
        return resolve([res.data.result])
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (node.level === 1) {
    await getProjectTopTreeApi().then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result.subList)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    await getProjectSubTreeApi(qs.stringify({id: node.data.id})).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }

}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  defaultExpandKeys.value.push(e.parentId)
  getEquipmentSelectFun()
}
// 根据项目获取设备选项
let equipmentSelectList = ref([])
const getEquipmentSelectFun = function () {
  getTreePrefixApi(qs.stringify({
    projectId: custerObj.value.id,
  })).then(res => {
    if (res.data.code === 0) {
      equipmentSelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


let isMultiple = ref()
watch(() => props.deviceVisible, (newVal) => {
  deviceVisible.value = newVal
}, {immediate: true, deep: true})
watch(() => props.checkDeviceList, (newVal) => {
  checkList.value = JSON.parse(JSON.stringify(newVal))
}, {immediate: true, deep: true})
watch(() => props.isMultiple, (newVal) => {
  isMultiple.value = newVal
}, {immediate: true, deep: true})
watch(() => deviceVisible.value, (newVal) => {
  deviceVisible.value = newVal
  emit('deviceVisibleFun', newVal)
})
onMounted(() => {
  // getProjectListFun()
})
</script>

<style lang="less" scoped>
.deviceCheckComponent {

  .el-dialog {
    .dialogCont {
      display: flex;
      justify-content: space-between;
      min-height: 300px;

      .leftBox, .rightBox {
        flex: 1;
        border-radius: 3px;
        border: 1px solid #DDDDDD;
      }

      .leftBox {
        margin-right: 10px;
        height: auto;
        padding-top: 0;
        box-shadow: 0 0 0 0;

        .head {
          width: 100%;
          height: 48px;
          background: #F5F7FA;
          border-radius: 3px 3px 0px 0px;
          border: 1px solid #EEEEEE;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 48px;
          text-indent: 16px;
        }

        .leftBoxCont {
          width: 100%;
          height: calc(100% - 52px);
          display: flex;
          justify-content: space-between;


          .transferContentLeft {
            flex: 1;
            min-width: 200px;
            padding: 10px;
            overflow: auto;
            border-right: 1px solid #eee;

            .el-tree-node__content {
              height: 40px;

              .opacityIcon {
                display: none;
              }
            }

            .el-tree-node__content:hover {
              background-color: #F6F7F7;

              .opacityIcon {
                display: inline-flex;
              }
            }

            /*选中颜色*/

            .el-tree-node.is-current > .el-tree-node__content {
              background-color: rgba(22, 93, 255, 0.1);
              color: #165DFF;

              .el-icon {
                color: #165DFF;
              }
            }

            .el-tree {
              --el-tree-expand-icon-color: #000;
              color: #000;
            }

          }

          .transferContRight {
            flex: 1;
            padding: 10px;
            overflow: auto;
            min-width: 200px;

            p {
              width: 100%;
              height: 32px;
              line-height: 32px;
              text-indent: 10px;
              cursor: pointer;
            }

            p:hover {
              background-color: rgba(22, 93, 255, 0.1);
            }
          }
        }
      }


      .rightBox {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .head {
          width: 100%;
          height: 48px;
          background: #F5F7FA;
          border-radius: 3px 3px 0px 0px;
          border: 1px solid #EEEEEE;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 48px;
          text-indent: 16px;
        }

        .deviceBox {
          padding: 16px;

          p {
            display: inline-block;
            padding: 1px 8px;
            background: #EBEDF0;
            border-radius: 3px;
            margin-right: 10px;
            margin-bottom: 10px;

            .el-icon {
              margin-left: 5px;
              transform: translateY(2px);
              color: #999999;
              cursor: pointer;
            }
          }
        }
      }

      > .deviceBox {

        p {
          display: inline-block;
          padding: 1px 8px;
          background: #EBEDF0;
          border-radius: 3px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }

    }
  }

  .bindDialog {
    margin-top: 5vh;
    max-height: 90vh;

    .leftBox, .rightBox {
      max-height: calc(90vh - 200px);
    }
  }
}
</style>
