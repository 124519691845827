<template>
  <div class="project">
    <div class="title">
      <p>
        <span>资产管理</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div :style="{width:listShow?'auto':0}" class="leftBox">
        <div v-show="listShow" class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <!--树形结构全部加载-->
          <!--          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"-->
          <!--                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"-->
          <!--                   :filter-node-method="filterNode" :props="{label: 'name',children: 'subList',}"-->
          <!--                   node-key="id" @node-click="handleNodeClick">-->
          <!--            <template #default="{ node, data }">-->
          <!--							<span class="custom-tree-node" style="width:100%;">-->
          <!--                <img v-if="data.existMonitorFlag==1" alt=""-->
          <!--                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/videoIcon.png"-->
          <!--                     style="width: 16px;height: 16px;margin-right: 6px;transform: translateY(3px)">-->
          <!--                <span v-if="node.label!==''">{{ node.label }}</span>-->
          <!--                <el-input v-if="node.label===''" v-model="addProjectName" autofocus class="addInput"></el-input>-->
          <!--                <span v-show="data.nodeType==2" class="localSymbol">本地</span>-->
          <!--							  <el-tooltip content="修改项目" placement="top">-->
          <!--								  <img v-if="data.canEditFlag" v-show="editLimit" alt="" class="opacityIcon"-->
          <!--                       src="../../assets/img/128@2x.png"-->
          <!--                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"-->
          <!--                       @click="saveProjectClick(1,data)">-->
          <!--                </el-tooltip>-->
          <!--                <el-tooltip content="新增项目" placement="top">-->
          <!--                  <img v-if="data.canAddFlag" v-show="addLimit" alt="" class="opacityIcon"-->
          <!--                       src="../../assets/img/127@2x.png"-->
          <!--                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"-->
          <!--                       @click="saveProjectClick(0,data)">-->
          <!--							  </el-tooltip>-->
          <!--                <el-tooltip content="导出" placement="top">-->
          <!--                  <el-icon v-show="data.nodeType!=2" class="opacityIcon" @click="uploadProject(data)"><Upload/></el-icon>-->
          <!--                  &lt;!&ndash;								  <img v-if="data.canAddFlag" alt="" class="opacityIcon" src="../../assets/img/127@2x.png"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"&ndash;&gt;-->
          <!--                  &lt;!&ndash;                       @click="saveProjectClick(0,data)">&ndash;&gt;-->
          <!--							  </el-tooltip>-->
          <!--							  <el-tooltip content="删除项目" placement="top">-->
          <!--								  <img v-if="delLimit" v-show="data.canDeleteFlag" alt="" class="opacityIcon"-->
          <!--                       src="../../assets/img/126@2x.png"-->
          <!--                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"-->
          <!--                       @click="delProjectClick(data.id)">-->
          <!--							  </el-tooltip>-->
          <!--							</span>-->
          <!--            </template>-->
          <!--          </el-tree>-->
          <!--树形结构懒加载-->
          <el-tree v-if="isShowTreeFlag" ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys"
                   :expand-on-click-node="false" :filter-node-method="filterNode" :load="treeList"
                   :props="{label: 'name',children: 'subList',isLeaf:'noExistSub'}" lazy
                   node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
                <img v-if="data.existMonitorFlag==1" alt=""
                     src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/videoIcon.png"
                     style="width: 16px;height: 16px;margin-right: 6px;transform: translateY(3px)">
                <span v-if="node.label!==''">{{ node.label }}</span>
                <el-input v-if="node.label===''" v-model="addProjectName" autofocus class="addInput"></el-input>
                <span v-show="data.nodeType==2" class="localSymbol">本地</span>
							  <el-tooltip content="修改项目" placement="top">
								  <img v-if="data.canEditFlag" v-show="editLimit" alt="" class="opacityIcon"
                       src="../../assets/img/128@2x.png"
                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"
                       @click="saveProjectClick(1,data,node)">
                </el-tooltip>
                <el-tooltip content="新增项目" placement="top">
                  <img v-if="data.canAddFlag" v-show="addLimit" alt="" class="opacityIcon"
                       src="../../assets/img/127@2x.png"
                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"
                       @click="saveProjectClick(0,data,node)">
							  </el-tooltip>
                <el-tooltip content="导出" placement="top">
                  <el-icon v-show="data.nodeType!=2" class="opacityIcon" @click="uploadProject(data)"><Upload/></el-icon>
                  <!--								  <img v-if="data.canAddFlag" alt="" class="opacityIcon" src="../../assets/img/127@2x.png"-->
                  <!--                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"-->
                  <!--                       @click="saveProjectClick(0,data)">-->
							  </el-tooltip>
							  <el-tooltip content="删除项目" placement="top">
								  <img v-if="delLimit" v-show="data.canDeleteFlag" alt="" class="opacityIcon"
                       src="../../assets/img/126@2x.png"
                       style="margin-left: 6px;width: 19px;height: 19px;vertical-align: middle;"
                       @click="delProjectClick(data.id,node)">
							  </el-tooltip>
							</span>
            </template>
          </el-tree>
        </div>
        <!--        <div v-if="custerObj.canAddFlag" class="addBox">-->
        <!--          <el-button :icon="Plus" @click="saveProjectClick(0)">新建项目</el-button>-->
        <!--        </div>-->
        <img v-show="listShow" alt="" class="listShow"
             src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/shouIcon.png"
             @click="listShow=false">
        <img v-show="!listShow" alt="" class="listShow listShow2"
             src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/zhanIcon.png"
             @click="listShow=true">
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <!--        <div class="headBox">-->
        <!--          <div v-if="custerObj.faultImage2&&custerObj.faultImage2.length>0" class="imgBox">-->
        <!--            <el-carousel v-if="custerObj.faultImage2&&custerObj.faultImage2.length>0" height="254px" type="none">-->
        <!--              <el-carousel-item v-for="(item,index) in custerObj.faultImage2" :key="index">-->
        <!--                <img :src="item.url" alt="" @click="handlePictureCardPreview(item)">-->
        <!--              </el-carousel-item>-->
        <!--            </el-carousel>-->
        <!--          </div>-->
        <!--          <div class="headRightBox">-->
        <!--            <div class="topBox">-->
        <!--              <div class="topBoxHead">-->
        <!--                <p>{{ custerObj.name }}</p>-->
        <!--                &lt;!&ndash;                <div class="btn">&ndash;&gt;-->
        <!--                &lt;!&ndash;                  <el-button class="customizedBtn" @click="customizedClick">定制仪表盘</el-button>&ndash;&gt;-->
        <!--                &lt;!&ndash;                  <el-button :disabled="!custerObj.canEditFlag" class="eidtButton" type="primary"&ndash;&gt;-->
        <!--                &lt;!&ndash;                             @click="saveProjectClick(1,custerObj)">编辑项目信息&ndash;&gt;-->
        <!--                &lt;!&ndash;                  </el-button>&ndash;&gt;-->
        <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--                <el-button class="exitClass" @click="exitFullscreen">-->
        <!--                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"-->
        <!--                       style="width: 16px;height: 16px;margin-right: 7px">返回-->
        <!--                </el-button>-->
        <!--              </div>-->
        <!--              <div v-if="custerObj.faultImage2&&custerObj.faultImage2.length>0" class="baseBox">-->
        <!--                <div class="contItem">-->
        <!--                  <img alt="" src="../../assets/img/141@2x.png">-->
        <!--                  <span> {{ custerObj.address ? custerObj.address : '-' }}</span>-->
        <!--                </div>-->
        <!--                <div class="contItem">-->
        <!--                  <img alt="" src="../../assets/img/357.svg">-->
        <!--                  <span> {{ custerObj.linkman ? custerObj.linkman : '-' }}</span>-->
        <!--                </div>-->
        <!--                <div class="contItem">-->
        <!--                  <img alt="" src="../../assets/img/145@2x.png">-->
        <!--                  <span> {{ custerObj.mobile ? custerObj.mobile : '-' }}</span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div v-if="custerObj.faultImage2&&custerObj.faultImage2.length>0" class="bottomBox">-->
        <!--              <div id="allmap2" ref="baiduRef2"></div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="tab">
          <el-tabs v-model="showFlag" class="demo-tabs" style="width: calc(100% - 300px)"
                   @tab-click="tabClick">
            <!--            <el-tab-pane label="显示本级" name="0"></el-tab-pane>-->
            <!--            <el-tab-pane label="显示下级" name="1"></el-tab-pane>-->
            <!--            <el-tab-pane label="仪表盘" name="2"></el-tab-pane>-->
            <!--            <el-tab-pane label="一次图" name="3"></el-tab-pane>-->
            <!--            <el-tab-pane label="能流图" name="4"></el-tab-pane>-->
            <el-tab-pane label="项目概览" name="5"></el-tab-pane>
            <el-tab-pane label="网关设备" name="6"></el-tab-pane>
            <el-tab-pane v-if="isOpenDevice||custerObj.nodeType==2" label="直连设备" name="7"></el-tab-pane>
            <el-tab-pane v-if="isOpenDevice||custerObj.nodeType!=2" label="mqtt设备" name="8"></el-tab-pane>
            <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.name"
                         :name="item.type"></el-tab-pane>
          </el-tabs>
          <div>
            <el-button :icon="Plus" type="primary" @click="addTemplateClick">添加</el-button>
            <el-button v-show="Number(showFlag)>10" :icon="Plus" type="primary" @click="addTemplateClick('edit')">编辑
            </el-button>
            <el-button v-show="Number(showFlag)>10" :icon="Minus" type="primary" @click="delTemplateClick">删除
            </el-button>
          </div>
          <!--          <img v-if="custerObj.monitorList&&custerObj.monitorList.length>0" alt=""-->
          <!--               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/videoIcon.png"-->
          <!--               style="width: 32px;height: 32px;cursor: pointer"-->
          <!--               @click="lookVideoDetail">-->
        </div>
        <div v-show="showFlag==0||showFlag==1" class="tableHeader">
          <div class="seachBox">
            <el-input v-model="productModel" clearable placeholder="请输入产品型号"></el-input>
            <el-input v-model="equipmentName" clearable placeholder="请输入设备名称"></el-input>
            <el-input v-model="equipmentAddress" clearable placeholder="请输入设备地址"></el-input>
            <el-button type="primary" @click="searchDeviceClick">查询</el-button>
          </div>
          <div v-show="showFlag==0&&custerObj.nodeType!=2" class="btn">
            <el-button :disabled="bindFlag===custerObj.id" type="primary" @click="bindClick()">绑定设备</el-button>
            <el-button :disabled="!!(showFlag==='1'||checkIdArr.length===0)" @click="removeClick()">批量解绑</el-button>
          </div>
        </div>
        <div v-show="showFlag==0||showFlag==1" class="tableBox">
          <el-table ref="multipleTableRef" v-loading='deviceLoading' :data="deviceList" :row-key="rowKey2"
                    style="width: 100%" table-layout="auto"
                    @selection-change="handleSelectionChange">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                <p>您可以在此页面绑定设备</p>
              </el-empty>
            </template>
            <el-table-column :reserve-selection="true" type="selection" width="55"/>
            <el-table-column label="设备名称" min-width="150px" prop="equipmentName"/>
            <el-table-column label="设备地址" min-width="150px" prop="equipmentAddress"/>
            <el-table-column label="网关" min-width="150px" prop="gatewayAddress"/>
            <el-table-column label="状态" min-width="150px" property="useStatusStr">
              <template #default="scope">
                <div v-show="scope.row.useStatus==1" class='statusOnClass'>
                  <div class="dotOnClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
                <div v-show="scope.row.useStatus==0" class='statusOutClass'>
                  <div class="dotOutClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品型号" min-width="150px" prop="productModel"/>
            <el-table-column label="创建人" min-width="150px" prop="creatorName"/>
            <el-table-column label="创建时间" min-width="150px" prop="createTime" sortable/>
            <el-table-column fixed="right" label="操作" width="170">
              <template #default="scope">
                <ul class="operationUl">
                  <li v-show="custerObj.nodeType!=2" class="editClass" style="color: #307FE2;cursor: pointer"
                      @click="addClick(scope.row.id,scope.row)">编辑
                  </li>
                  <li v-show="custerObj.nodeType!=2" class="editClass" style="color: #307FE2;cursor: pointer"
                      @click="removeClick(scope.row.id)">解绑
                  </li>
                  <li class="editClass" style="color: #307FE2;cursor: pointer" @click="goClick(scope.row.id)">管理
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerBox">
            <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]"
                           :total="total" background layout="total,sizes, prev, pager, next" small
                           @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
          </div>
        </div>
        <div v-show="showFlag==2" class="curstCont">
          <el-empty
              v-if="(detailData.queryList&&detailData.queryList.length===0)&&(detailData.paramList&&detailData.paramList.length===0)"
              class="emptyClass" description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
          <div v-show="detailData.paramList&&detailData.paramList.length!==0" class="boxCont">
            <div v-for="(item,index) in detailData.paramList" :key="index" class="itemBox">
              <p class="tit">{{ item.name }}</p>
              <p class="val">{{ item.value }}</p>
              <img alt="" src="../../assets/img/401.svg">
            </div>
          </div>
          <div v-show="detailData.queryList&&detailData.queryList.length!==0" class="chatCont">
            <div class="projectName">数字能源中心</div>
            <div v-for="(item,index) in detailData.queryList" :key="index" class="itemCont">
              <el-empty v-if="item.chartData.xAxis.length===0" class="emptyClass" description="暂无数据信息"
                        image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              </el-empty>
              <!--图表-->
              <p v-show="item.chartData.xAxis.length!==0" style="text-align: center;font-size: 16px;margin:10px auto;">
                {{ item.queryName }}</p>
              <div v-show="item.chartData.xAxis.length!==0" :class="'chat'+index" class="chart"
                   style="width: 100%;height: 400px;"></div>
            </div>
          </div>
        </div>
        <div v-if="showFlag==5" style="height: calc(100% - 70px);overflow: auto">
          <Overview :custerObj="custerObj"/>
        </div>
        <div v-if="showFlag==6||showFlag==7" style="height: calc(100% - 70px);overflow: auto">
          <ProjectDevice :custerObj="custerObj" :tabindex="showFlag"/>
        </div>
        <div v-if="showFlag==8" style="height: calc(100% - 70px);overflow: auto">
          <MqttDevice :custerObj="custerObj"/>
        </div>


        <!--模版-->
        <div v-if="showFlag==11||showFlag==12" style="height: calc(100% - 70px);overflow: auto">
          <Template1 :custerObj="custerObj" :projectModuleId="projectModuleId" :showFlag="showFlag"/>
        </div>
        <div v-if="showFlag==13" style="height: calc(100% - 70px);overflow: auto">
        </div>
        <div v-if="showFlag==14" style="height: calc(100% - 70px);overflow: auto">
        </div>
        <div v-if="showFlag==15" style="height: calc(100% - 70px);overflow: auto">
        </div>
        <div v-if="showFlag==16" style="height: calc(100% - 70px);overflow: auto">
        </div>
        <div v-if="showFlag==17" style="height: calc(100% - 70px);overflow: auto">
        </div>
      </div>
    </div>
    <!--新建、编辑项目弹窗-->
    <el-drawer v-model="drawerProject" :show-close="false" direction="rtl" size="30%">
      <template #header>
				<span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerProject=false">
						<CloseBold/>
					</el-icon>
				</span>
        <span class="tltleClass">{{ projectFormData.id ? '编辑项目' : '新建项目' }}</span>
        <div class="operBox">
          <el-button class="resetButton" @click="drawerProject=false">取消</el-button>
          <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;">
          <el-form ref="projectFormRef" :model="projectFormData" :rules="projectFormRules" class="demo-ruleForm"
                   status-icon>
            <el-form-item label="项目名称" name="name" prop="name">
              <el-input v-model="projectFormData.name" placeholder="请输入项目名称"/>
            </el-form-item>
            <el-form-item label="项目地址" prop="address">
              <el-input v-model="projectFormData.address" placeholder="请输入项目地址"/>
            </el-form-item>
            <el-form-item label="节点类型" prop="nodeType">
              <el-select v-model="projectFormData.nodeType" placeholder="请选择节点类型" style="width: 100%;">
                <el-option :value="1" label="云端节点"/>
                <el-option :value="2" label="本地节点"/>
              </el-select>
            </el-form-item>
            <el-form-item label="地图选点" prop="">
              <el-input id="suggestId" v-model="nameValue" autocomplete="off" placeholder="请输入地址"
                        type="text"></el-input>
            </el-form-item>
            <div id="allmap" ref="baiduRef"></div>
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="projectFormData.longitude" placeholder="请输入经度" type="number"/>
            </el-form-item>
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="projectFormData.latitude" placeholder="请输入纬度" type="number"/>
            </el-form-item>
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="projectFormData.linkman" placeholder="请输入联系人"/>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="projectFormData.mobile" placeholder="请输入手机号码"/>
            </el-form-item>
            <el-form-item class="videoFormItemBox" label="" prop="monitorList">
              <div class="headBox">
                <div>监控名称</div>
                <div>监控视频</div>
              </div>
              <div v-for="(item,index) in projectFormData.monitorList" :key="index" class="listItemBox">
                <div>
                  <el-input v-model="item.name" placeholder="请输入监控名称"/>
                  <el-input v-model="item.url" placeholder="请输入监控视频地址"/>
                </div>
                <el-icon class="delIcon" style="margin-right: 6px;"
                         @click="delVideoClick(index)">
                  <CircleClose/>
                </el-icon>
                <el-icon v-if="index===projectFormData.monitorList.length-1" class="addIcon" @click="addVideoClick">
                  <CirclePlus/>
                </el-icon>
              </div>
              <el-button v-if="!(projectFormData.monitorList&&projectFormData.monitorList.length>0)"
                         type="primary" @click="addVideoClick">
                添加视频监控
              </el-button>
            </el-form-item>
            <el-form-item label="图片" prop="faultImage2">
              <el-upload v-model:file-list='projectFormData.faultImage2' :auto-upload="false"
                         :on-preview="handlePictureCardPreview" :on-remove="onRemove" accept=".png,.jpg,.jpeg"
                         action="#" limit="5" list-type="picture-card" @change="uploadFile">
                <el-icon>
                  <Plus/>
                </el-icon>
                <p>上传图片</p>
              </el-upload>
            </el-form-item>
            <p>支持jpg、png格式,且图片大小不超过5M 最多上传5张 </p>
            <p>(推荐图片尺寸：254px*254px)</p>
          </el-form>
        </div>
      </template>
    </el-drawer>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
    <!--绑定弹窗-->
    <el-dialog v-model="bindDeviceDialog" title="绑定设备" width="70%">
      <el-input v-model="bDeviceName" clearable placeholder="请输入设备名称" style="width: 200px;margin:0 10px 10px 0"
                @input="getAllDeviceListFun"></el-input>
      <el-table ref="multipleTableRef2" v-loading='unBindDeviceLoading' :data="unBindDeviceList" :row-key="rowKey"
                style="width: 100%" table-layout="auto"
                @selection-change="handleSelectionChange2">
        <el-table-column :reserve-selection="true" type="selection" width="55"/>
        <el-table-column label="设备名称" prop="name"/>
        <el-table-column label="通讯地址" prop="address"/>
        <el-table-column label="产品型号" prop="productModel"/>
        <el-table-column label="创建人" prop="creatorName"/>
        <el-table-column label="创建时间" prop="createTime"/>
      </el-table>
      <el-pagination :page-size="bPageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small" :total="bTotal"
                     background layout="total, prev, pager, next, sizes" small @size-change="bHandleSizeChange"
                     @current-change="bHandleCurrentChange"/>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="bindDeviceDialog = false">取消</el-button>
        <el-button type="primary" @click="bindSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--新增设备弹窗-->
    <el-dialog v-model="deviceDialogVisible" :close-on-click-modal="false" :width="dialogWidth" title="编辑设备">
      <div>
        <el-form ref="deviceFormRef" :model="deviceForm" :rules="deviceRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <div style="display: flex;">
            <div style="flex:1;margin-right: 30px;">
              <el-form-item label="产品类型" prop="categoryId">
                <el-select v-model="deviceForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%"
                           @change="categoryChange(deviceForm.categoryId)">
                  <el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--              <el-form-item v-if="categoryName==='电表'" label="电网费率"-->
              <!--                            prop="powerGridRateId">-->
              <!--                <el-select v-model="deviceForm.powerGridRateId" clearable placeholder="请选择电网费率" style="width:100%">-->
              <!--                  <el-option v-for="item in powerGridRateData" :key="item.id" :label="item.versionNumber"-->
              <!--                             :value="item.id" style="width:100%">-->
              <!--                  </el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <el-form-item label="设备名称" prop="name">
                <el-input v-model="deviceForm.name" placeholder="请输入设备名称"/>
              </el-form-item>
              <el-form-item label="通讯地址" prop="address">
                <el-input v-model="deviceForm.address" placeholder="请输入通讯地址" @change="addressChange"/>
              </el-form-item>
              <el-form-item label="用户类型" prop="userType">
                <el-input v-model="deviceForm.userType" placeholder="请输入用户类型"/>
              </el-form-item>
              <el-form-item label="端口" prop="port">
                <el-select v-model="deviceForm.port" clearable placeholder="请选择端口" style="width:100%">
                  <el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电流变比" prop="rateA">
                <el-input v-model="deviceForm.rateA" placeholder="请输入电流变比" type="number"/>
              </el-form-item>

              <el-form-item label="是否启用" prop="enableFlag">
                <el-select v-model="deviceForm.enableFlag" clearable filterable placeholder="请选择是否启用"
                           style="width:100%">
                  <el-option v-for="item in enableList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="flex:1;">
              <el-form-item label="产品型号" prop="productId">
                <el-select v-model="deviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
                  <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="网关" prop="gatewayId">
                <el-select v-model="deviceForm.gatewayId" clearable filterable placeholder="请选择网关" style="width:100%">
                  <el-option v-for="item in gatewayList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="规约类型" prop="rulerType">
                <el-select v-model="deviceForm.rulerType" clearable placeholder="请选择规约类型" style="width:100%">
                  <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="波特率" prop="rateType">
                <el-select v-model="deviceForm.rateType" clearable placeholder="请选择波特率" style="width:100%">
                  <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="电压变比" prop="rateV">
                <el-input v-model="deviceForm.rateV" placeholder="请输入电压变比" type="number"/>
              </el-form-item>
              <el-form-item label="备注1">
                <el-input v-model="deviceForm.remark" placeholder="请输入备注"/>
              </el-form-item>
              <!--<el-form-item label="关联设备名称">
                <el-input v-model="deviceForm.relationName" placeholder="请输入关联设备名称"/>
              </el-form-item>-->
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--新增模版弹窗-->
    <el-dialog v-model="addTemplateDialog" class="addTemplateDialog" title="新增" width="70%">
      <el-form ref="addTemplateFormRef" :model="addTemplateFormData" :rules="addTemplateFormRules" class="demo-ruleForm"
               status-icon>
        <el-form-item label="模版名称" name="name" prop="name">
          <el-input v-model="addTemplateFormData.name" placeholder="请输入模版名称"/>
        </el-form-item>
        <el-form-item label="默认展示" name="topFlag" prop="topFlag">
          <el-radio-group v-model="addTemplateFormData.topFlag">
            <el-radio :value="1">是</el-radio>
            <el-radio :value="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="!isEdit" label="分类" prop="templateType" style="width: 100%;">
          <el-select v-model="templateType" clearable placeholder="请输入模版名称" @change="templateTypeSelectChange">
            <el-option label="配电室" value="配电室"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-show="!isEdit" class="templateList">
        <div v-for="(item,index) in allTemplateListCopy" :key="index"
             :class="item.templateName===addTemplateFormData.type?'activeTemplateIItem':''"
             class="templateItem" @click="templateClick(item)">
          <img :src="item.url" alt="">
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="addTemplateDialog = false">取消</el-button>
        <el-button type="primary" @click="addTemplateSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './project.less'
import {
  Upload, Search, Plus, Minus, UploadFilled, Edit, CloseBold, CircleClose, CirclePlus
}
  from
      '@element-plus/icons-vue'
import setCheckedKeys, {
  CascaderProps,
  ElLoading,
  ElMessage,
  ElMessageBox
} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  delProjectListApi,
  getProjectListApi,
  saveProjectListApi,
  getDeviceListApi,
  getDeviceListLocalApi,
  getAllDeviceListApi,
  bindDeviceListApi,
  removeDeviceListApi,
  getCustomDetailApi,
  getDeviceIsOpenApi,
  getProjectTopTreeApi,
  getProjectSubTreeApi,
  getProjectModuleListApi,
  saveProjectModuleListApi, delProjectModuleListApi
} from "@/api/modules/project";
import {delMenuListApi} from "@/api/modules/menu";
import {uploadImg} from "@/api/modules/tissue";
import * as echarts from "echarts";
import {getEchartsDataApi, getTableDataApi} from "@/api/modules/dataSearch";
import {
  getList,
  getAllCategory,
  getProductList,
  getSelectList,
  getGatewayList,
  getDetail,
  saveList,
  deleteList, powerGridRateList
} from '../../api/modules/device'

// import OnceImg from './onceImg/onceImg'
// import EnergyFlow from './energyFlow/energyFlow'
import Overview from './overview/overview'
import ProjectDevice from './projectDevice/projectDevice'
import MqttDevice from './projectDevice/mqttDevice'
// 模版组件
import Template1 from './customComponent/template1'
import {http} from "@/api";

let router = useRouter()


// 控制是否显示资产列表
let listShow = ref(true)


const goClick = function (id) {
  router.push({
    path: '/admin/deviceManagement',
    query: {
      id: id,
      projectId: custerObj.value.id,
      isLocal: custerObj.value.nodeType
    }
  })
}
let baiduRef = ref();
let map = ref();
let point = ref();
let marker = ref();
let baiduRef2 = ref();
let map2 = ref();
let point2 = ref();
let marker2 = ref();
//页面地图
const mapAllFun2 = function (lng, lat) {
  map2.value = new BMap.Map("allmap2"); // 创建Map实例
  map2.value.clearOverlays(); // 清除地图上所有覆盖物
  var point = new BMap.Point(lng, lat); // 创建点坐标，汉得公司的经纬度坐标
  map2.value.centerAndZoom(point, 15); //初始化地图,设置中心点坐标，
  map2.value.addOverlay(new BMap.Marker(point)); //添加标注
  map2.value.enableScrollWheelZoom();
}
//弹窗地图--------------------------------------------
/*const mapAllFun = function(lng, lat) {
  map.value = new BMap.Map(baiduRef.value); //新建一个map地图实例
  point.value = new BMap.Point(lng, lat); //创建点
  marker.value = new BMap.Marker(point.value); //做标记
  map.value.centerAndZoom(point.value, 15);
  map.value.enableScrollWheelZoom(true); //滚轮缩放
  map.value.addOverlay(marker.value);
  var geoc = new BMap.Geocoder();
  //地图的点击
  map.value.addEventListener("click", function(e) {
    var pt = e.point;
    geoc.getLocation(pt, function(rs) {
      //addressComponents对象可以获取到详细的地址信息
      var addComp = rs.addressComponents;
      var site = addComp.province + ", " + addComp.city + ", " + addComp.district + ", " + addComp.street + ", " + addComp.streetNumber;
      projectFormData.value.longitude = pt.lng
      projectFormData.value.latitude = pt.lat
      map.value.clearOverlays(); //清除地图上所有覆盖物
      point.value = new BMap.Point(pt.lng, pt.lat); //创建点
      marker.value = new BMap.Marker(point.value); //做标记
      map.value.addOverlay(marker.value);
      //console.log(site,pt.lng,pt.lat)
    });
  });
}*/
let nameValue = ref()
const mapAllFun = function (lng, lat) {
  map.value = new BMap.Map("allmap"); // 创建Map实例
  map.value.clearOverlays(); // 清除地图上所有覆盖物
  var point = new BMap.Point(lng, lat); // 创建点坐标，汉得公司的经纬度坐标
  map.value.centerAndZoom(point, 15); //初始化地图,设置中心点坐标，
  map.value.addOverlay(new BMap.Marker(point)); //添加标注
  map.value.enableScrollWheelZoom();
  var ac = new BMap.Autocomplete( //建立一个自动完成的对象
      {
        "input": "suggestId",
        "location": map.value
      })
  /*if(this.editId) {
    ac.setInputValue('杭州') //自动匹配后给input默认值赋值
  }*/
  var myValue
  ac.addEventListener("onconfirm", function (e) { //鼠标点击下拉列表后的事件
    var _value = e.item.value;
    myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
    nameValue.value = myValue
    setPlace();
  });

  function setPlace() {
    map.value.clearOverlays(); //清除地图上所有覆盖物
    function myFun() {
      //console.log(local.getResults().getPoi(0).point)
      projectFormData.value.longitude = local.getResults().getPoi(0).point.lng
      projectFormData.value.latitude = local.getResults().getPoi(0).point.lat
      var userlocation = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
      map.value.centerAndZoom(userlocation, 15);
      map.value.addOverlay(new BMap.Marker(userlocation)); //添加标注
    }

    var local = new BMap.LocalSearch(map.value, { //智能搜索
      onSearchComplete: myFun
    });
    local.search(myValue);
  }

  //地图的点击
  map.value.addEventListener("click", function (e) {
    console.log(e); //经度、维度
    projectFormData.value.longitude = e.point.lng
    projectFormData.value.latitude = e.point.lat
    map.value.clearOverlays()
    var userlocation = e.point //获取第一个智能搜索的结果
    map.value.centerAndZoom(userlocation, 15);
    map.value.addOverlay(new BMap.Marker(userlocation)); //添加标注
  })
}

// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  // defaultExpandKeys.value = []
  // let res = tree(data, name)
  // if (res) {
  //   nextTick(() => {
  //     treeRef.value.setCurrentKey(res.id)
  //   })
  //
  //   defaultExpandKeys.value.push(res.parentId)
  //   custerObj.value = res
  //   getDeviceListFun(res.id)
  //   if (showFlag.value == '2') {
  //     getDetailFun()
  //   }
  // }

  treeRef.value.filter(name)
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)


const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}

//获取项目树级列表
const getProjectListFun = async function () {
  // openFullScreen()
  await getProjectListApi().then(res => {
    // loadingAdd.value.close();
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      bindFlag.value = customerList.value[0].id
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
          for (let i = 0; i < custerObj.value.imageList.length; i++) {
            custerObj.value.faultImage2.push({
              url: custerObj.value.imageList[i],
              name: i
            })
          }
        } else {
          if (window.localStorage.getItem('currentNodeKey')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj'))
            for (let i = 0; i < custerObj.value.imageList.length; i++) {
              custerObj.value.faultImage2.push({
                url: custerObj.value.imageList[i],
                name: i
              })
            }
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        nextTick(() => {
          treeRef.value && treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value && treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      //有无路由参数
      // if (router.currentRoute.value.query.id) {
      //   getDeviceListFun(router.currentRoute.value.query.id)
      // } else {
      //   if (window.localStorage.getItem('currentNodeKey')) {
      //     getDeviceListFun(window.localStorage.getItem('currentNodeKey'))
      //   } else {
      //     getDeviceListFun(customerList.value[0].id)
      //   }
      // }
      if (showFlag.value == '2') {
        getDetailFun()
      }
      nextTick(() => {
        treeRef.value && treeRef.value.setCurrentKey(currentNodeKey.value)
        getTemplateListFun()
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
    nextTick(() => {
      if (custerObj.value.faultImage2 && custerObj.value.faultImage2.length > 0) {
        mapAllFun2(custerObj.value.longitude, custerObj.value.latitude)
      }
    })
  }).catch(err => {
    // loadingAdd.value.close();
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  if (e.id != 0 && e.id !== null) {
    custerObj.value = e
    currentNodeKey.value = e.id
    custerObj.value.faultImage2 = []
    defaultExpandKeys.value = []
    window.localStorage.setItem('currentNodeKey', currentNodeKey.value)
    window.localStorage.setItem('custerObj', JSON.stringify(e))
    defaultExpandKeys.value.push(e.parentId)
    for (let i = 0; i < custerObj.value.imageList.length; i++) {
      custerObj.value.faultImage2.push({
        url: custerObj.value.imageList[i],
        name: i
      })
    }

    // getDeviceListFun(e.id)
    if (showFlag.value == '2') {
      getDetailFun()
    }
    nextTick(() => {
      if (custerObj.value.faultImage2 && custerObj.value.faultImage2.length > 0) {
        mapAllFun2(custerObj.value.longitude, custerObj.value.latitude)
      }
      getTemplateListFun()
    })
  }
  showFlag.value = '5'
  if (custerObj.value.nodeType == 2 && showFlag.value == 8) {
    nextTick(() => {
      showFlag.value = '7'
    })
  }
  if (custerObj.value.nodeType != 2 && showFlag.value == 7) {
    nextTick(() => {
      showFlag.value = '8'
    })
  }
}


// 项目树懒加载-------------------
const treeList = async function (node, resolve) {
  if (node.level === 0) {
    await getProjectTopTreeApi().then(res => {
      if (res.data.code === 0) {
        handleNodeClick(res.data.result)
        defaultExpandKeys.value = [res.data.result.id]
        currentNodeKey.value = res.data.result.id
        return resolve([res.data.result])
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (node.level === 1) {
    await getProjectTopTreeApi().then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result.subList)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    await getProjectSubTreeApi(qs.stringify({id: node.data.id})).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }

}


// 新建编辑项目----------------------------------------------------------------------------
// 新建编辑项目弹窗
let drawerProject = ref(false)
// 项目表单数据
let projectFormData = ref({
  id: 0,
  parentId: custerObj.value.id,
  name: '',
  address: '',
  nodeType: 1,
  longitude: '',
  latitude: '',
  linkman: '',
  mobile: '',
  faultImage2: [],
  monitorList: []
})
// 项目表单规则
let projectFormRules = ref({
  name: [{
    required: true,
    message: '请输入项目名称',
    trigger: 'blur'
  }],
  // address: [{
  //   required: true,
  //   message: '请输入项目地址',
  //   trigger: 'blur'
  // }],
  mobile: [{
    message: '请输入有效的手机号码',
    pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
    trigger: 'blur'
  }]
})
// 新建逻辑
const addOpera = async function (data) {
  if (addProjectName.value !== '' && custerObj.value.id) {
    // 在此处编写处理逻辑
    await saveProjectListApi(qs.stringify({
      id: 0,
      parentId: custerObj.value.id,
      name: addProjectName.value
    })).then(res => {
      if (res.data.code === 0) {
        addProjectName.value = ''
        let node1
        if (data.parentId == 0) {
          node1 = treeRef.value.getNode(data.id)
          node1.data.children = []
        } else {
          node1 = treeRef.value.getNode(data.parentId)
        }
        node1.loaded = false
        node1.expand()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    if (data) {
      data.subList = data.subList.slice(0, -1)
    } else {
      treeRef.value.getCurrentNode().subList = treeRef.value.getCurrentNode().subList.slice(0, -1)
    }
  }
}
// 新建编辑项目树
let addProjectName = ref('')
let isShowTreeFlag = ref(true)
const saveProjectClick = function (num, data, node) {
  if (num) {
    drawerProject.value = true
    projectFormData.value = JSON.parse(JSON.stringify(data))
    projectFormData.value.faultImage2 = []
    for (let i = 0; i < data.imageList.length; i++) {
      projectFormData.value.faultImage2.push({
        url: data.imageList[i],
        name: i
      })
    }
    nextTick(() => {
      if (projectFormData.value.faultImage2.length > 4) {
        document.querySelector('.el-upload--picture-card').style.display = 'none'
      } else {
        document.querySelector('.el-upload--picture-card').style.display = 'flex'
      }
    })
  } else {
    // 新增项目
    if (data) {
      defaultExpandKeys.value = [data.id]
      // if (!data.subList) {
      data.subList = []
      // }
      data.subList.push({
        id: null,
        parentId: data.id,
        name: '',
      })
      node.loaded = true
      node.expand()
    } else {
      defaultExpandKeys.value = [custerObj.value.id]
      if (!treeRef.value.getCurrentNode().subList) {
        treeRef.value.getCurrentNode().subList = []
      }
      treeRef.value.getCurrentNode().subList.push({
        id: 0,
        parentId: custerObj.value.id,
        name: ''
      })
    }

    nextTick(() => {
      setTimeout(() => {
        document.querySelector('.addInput input').focus()
        document.querySelector('.addInput input').addEventListener("blur", function () {
          addOpera(data)
        });
      }, 100)
    })


    // projectFormData.value = {
    //   id: 0,
    //   parentId: custerObj.value.id,
    //   name: '',
    //   address: '',
    //   nodeType: 1,
    //   longitude: '',
    //   latitude: '',
    //   linkman: '',
    //   mobile: '',
    //   faultImage2: []
    // }
    // if (data) {
    //   projectFormData.value.parentId = data.id
    // } else {
    //   projectFormData.value.parentId = custerObj.value.id
    // }
    // nextTick(() => {
    //   document.querySelector('.el-upload--picture-card').style.display = 'flex'
    // })
  }
  nextTick(() => {
    if (!projectFormData.value.longitude || !projectFormData.value.latitude) {
      mapAllFun(120.21551, 30.253082)
    } else {
      mapAllFun(projectFormData.value.longitude, projectFormData.value.latitude)
    }
  })
}
// 提交项目表单
let projectFormRef = ref()
const saveProjectSubmit = function () {
  // 提交表单
  if (projectFormRef) {
    projectFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        projectFormData.value.imageList = []
        for (let i = 0; i < projectFormData.value.faultImage2.length; i++) {
          projectFormData.value.imageList.push(projectFormData.value.faultImage2[i].url)
        }
        projectFormData.value.imageList = projectFormData.value.imageList.join(',')
        delete projectFormData.value.faultImage2
        projectFormData.value.monitorJsonStr = JSON.stringify(projectFormData.value.monitorList)
        delete projectFormData.value.monitorList
        saveProjectListApi(qs.stringify(projectFormData.value)).then(res => {
          if (res.data.code === 0) {
            // getProjectListFun()
            drawerProject.value = false
            // 给选中的信息赋值
            custerObj.value.id = res.data.result.id
            custerObj.value.parentId = res.data.result.parentId
            custerObj.value.name = res.data.result.name
            custerObj.value.address = res.data.result.address
            custerObj.value.longitude = res.data.result.longitude
            custerObj.value.latitude = res.data.result.latitude
            custerObj.value.linkman = res.data.result.linkman
            custerObj.value.mobile = res.data.result.mobile
            custerObj.value.nodeType = res.data.result.nodeType
            custerObj.value.imageList = res.data.result.imageList
            custerObj.value.monitorList = JSON.parse(res.data.result.monitorJsonStr)
            custerObj.value.faultImage2 = []
            for (let i = 0; i < res.data.result.imageList.length; i++) {
              custerObj.value.faultImage2.push({
                url: res.data.result.imageList[i],
                name: i
              })
            }
            let node = treeRef.value.getNode(custerObj.value.parentId)
            node.loaded = false
            node.expand()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
// 删除项目树
const delProjectClick = function (id, node) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除项目',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此项目，删除后数据不可恢复'),
    ])
  }).then(() => {
    delProjectListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        // getProjectListFun()
        currentNodeKey.value = undefined

        let node1 = treeRef.value.getNode(node.data.parentId)
        node1.loaded = false
        node1.expand()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}
// 添加视频监控
const addVideoClick = function () {
  projectFormData.value.monitorList.push({
    name: '',
    url: ''
  })
}
// 删除视频监控
const delVideoClick = function (index) {
  projectFormData.value.monitorList.splice(index, 1)
}


// 图片处理-----------------------------------------------------------------
// 上传图片
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
//新建编辑工单上传文件
const uploadFile = async function (file) {
  if ((file.size / 1024 / 1024) > 5) {
    ElMessage({
      message: '图片大小不能超过5M!',
      type: 'info'
    })
    projectFormData.value.faultImage2.splice(projectFormData.value.faultImage2.length - 1, 1)
  } else {
    let fd = new FormData();
    //注意是文件流file.raw
    fd.append('file', file.raw); //传文件
    await uploadImg(
        fd
    ).then(res => {
      if (res.data.ok) {
        projectFormData.value.faultImage2[projectFormData.value.faultImage2.length - 1].url = res.data.result.fullPath
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })

    if (projectFormData.value.faultImage2.length > 4) {
      document.querySelector('.el-upload--picture-card').style.display = 'none'
    } else {
      document.querySelector('.el-upload--picture-card').style.display = 'flex'
    }
  }
}
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}
// 图片移除操作
const onRemove = function () {
  if (projectFormData.value.faultImage2.length > 4) {
    document.querySelector('.el-upload--picture-card').style.display = 'none'
  } else {
    document.querySelector('.el-upload--picture-card').style.display = 'flex'
  }
}

// 设备列表----------------------------------------------------------------------------
const handleSizeChange = function (e) {
  pageSize.value = e
  getDeviceListFun(custerObj.value.id)
}
const handleCurrentChange = function (e) {
  pageNumber.value = e
  getDeviceListFun(custerObj.value.id)
}
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
let productModel = ref()
let equipmentName = ref()
let equipmentAddress = ref()
let showFlag = ref('5')
let deviceList = ref([])
let deviceLoading = ref(false)
//获取设备列表
const getDeviceListFun = function (id) {
  deviceLoading.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    id: id,
    getSubFlag: 0,
    productModel: productModel.value,
    equipmentName: equipmentName.value,
    equipmentAddress: equipmentAddress.value
  }
  if (custerObj.value.nodeType === 2) {
    getDeviceListLocalApi(qs.stringify(params)).then(res => {
      deviceLoading.value = false
      if (res.data.ok) {
        deviceList.value = res.data.result.list
        total.value = Number(res.data.result.total)
        // multipleTableRef.value.clearSelection()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    getDeviceListApi(qs.stringify(params)).then(res => {
      deviceLoading.value = false
      if (res.data.ok) {
        deviceList.value = res.data.result.list
        total.value = Number(res.data.result.total)
        // multipleTableRef.value.clearSelection()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 点击搜索设备列表
const searchDeviceClick = function () {
  getDeviceListFun(custerObj.value.id)
}
// 点击是否展示下级的tab
let projectModuleId = ref()
const tabClick = function (e) {
  if (showFlag.value == '2') {
    getDetailFun()
  }
  showFlag.value = e.paneName
  // getDeviceListFun(custerObj.value.id)
  for (let i = 0; i < tabList.value.length; i++) {
    if (tabList.value[i].type == showFlag.value) {
      projectModuleId.value = tabList.value[i].id
    }
  }
}


// 仪表盘----------------------------------------------------------------------------
// 获取仪表盘详情
let detailData = ref({})
const getDetailFun = function () {
  openFullScreen()
  getCustomDetailApi(qs.stringify({
    projectId: custerObj.value.id
  })).then(res => {
    loadingAdd.value.close();
    if (res.data.code === 0) {
      detailData.value = res.data.result
      // if ((detailData.value.queryList && detailData.value.queryList.length > 0) || (detailData.value.paramList && detailData.value.paramList.length > 0)) {
      //   showFlag.value = '2'
      // }
      // 处理图表数据
      for (let i = 0; i < res.data.result.queryList.length; i++) {
        let echartsDataX = []
        let echartsData = []
        echartsDataX = res.data.result.queryList[i].chartData.xAxis ? res.data.result.queryList[i].chartData.xAxis : []
        echartsData = res.data.result.queryList[i].chartData.yAxis ? res.data.result.queryList[i].chartData.yAxis : []
        for (let j = 0; j < echartsData.length; j++) {
          echartsData[j].name = echartsData[j].titleName
          // echartsData[j].type = 'line'
          // echartsData[j].symbol = 'none'
          echartsData[j].sampling = 'lttb'

          // 处理浮点数
          for (let k = 0; k < echartsData[j].data.length; k++) {
            echartsData[j].data[k] = Number(echartsData[j].data[k]).toFixed(2)
          }
          // 处理最大值最小值
          echartsData[j].markPoint = {
            data: []
          }
          if (echartsData[j].valueType !== '') {
            echartsData[j].valueType = echartsData[j].valueType.split(',')
            echartsData[j].valueType = echartsData[j].valueType.map(Number)
            if (echartsData[j].valueType && echartsData[j].valueType.length > 0) {
              for (let k = 0; k < echartsData[j].valueType.length; k++) {
                if (echartsData[j].valueType[k] === 0) {
                  echartsData[j].markPoint.data.push({
                    type: 'max',
                    name: 'Max'
                  })
                }
                if (echartsData[j].valueType[k] === 1) {
                  echartsData[j].markPoint.data.push({
                    type: 'min',
                    name: 'Min'
                  })
                }
              }
            }
          }

          // 处理图表类型
          if (echartsData[j].chatType !== '') {
            if (echartsData[j].chatType === 0) {
              echartsData[j].type = 'bar'
              echartsData[j].symbol = 'none'
              echartsData[j].barGap = '0%'
            } else if (echartsData[j].chatType === 1) {
              echartsData[j].type = 'line'
              echartsData[j].lineStyle = {
                width: 1
              }
              echartsData[j].symbol = 'circle'
              // echartsData[j].symbol = 'none'
            } else if (echartsData[j].chatType === 3) {
              echartsData[j].type = 'scatter'
              echartsData[j].symbolSize = 15
              //for (let j = 0; j < echartsData[i].data.length; j++) {
              // echartsData[i].data.splice(j, 1, [echartsDataX.value[j], Number(echartsData[i].data[j])])
              //}
            } else if (echartsData[j].chatType === 2) {
              echartsData[j].type = 'line'
              echartsData[j].symbol = 'none'
              echartsData[j].areaStyle = {
                opacity: 0.3
              }
            }
          } else {
            echartsData[j].type = 'line'
            echartsData[j].lineStyle = {
              width: 1
            }
            echartsData[j].symbol = 'circle'
            // echartsData[j].symbol = 'none'
          }

        }
        nextTick(() => {
          chartLoad(i, echartsDataX, echartsData)
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 根据详情展示图表---------------------------------------------------------------------
// 加载图表
const chartLoad = function (index, echartsDataX, echartsData) {
  var chartDom = document.querySelector(`.chat${index}`);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: 'none'
    //     },
    //     restore: {},
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: 80,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX
    },
    yAxis: {
      type: 'value',
    },
    dataZoom: [{
      type: 'inside',
      start: 0,
      end: 100,
      disabled: false
    },
      {
        start: 0,
        end: 100
      }
    ],
    series: echartsData
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}

// 绑定事件----------------------------------------------------------------------------
let bindFlag = ref(true)
let bTotal = ref()
let bPageNumber = ref(1)
let bPageSize = ref(20)
let bDeviceName = ref()
let unBindDeviceLoading = ref(false)
// 绑定弹窗
let bindDeviceDialog = ref(false)
// 未绑定的设备列表
let unBindDeviceList = ref([])
// 获取未绑定设备列表
const getAllDeviceListFun = async function () {
  await getAllDeviceListApi(qs.stringify({
    pageNumber: bPageNumber.value,
    pageSize: bPageSize.value,
    name: bDeviceName.value,
    type: 1
  })).then(res => {
    unBindDeviceLoading.value = false
    if (res.data.code === 0) {
      unBindDeviceList.value = res.data.result.list
      bTotal.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击绑定
const bindClick = async function () {
  bindDeviceDialog.value = true
  unBindDeviceLoading.value = true
  await getAllDeviceListFun()
  multipleTableRef2.value.clearSelection()
}
const bHandleSizeChange = function (e) {
  bPageSize.value = e
  getAllDeviceListFun()
}
const bHandleCurrentChange = function (e) {
  bPageNumber.value = e
  getAllDeviceListFun()
}
// 设备表格多选事件
let checkIdArr2 = ref([])
const handleSelectionChange2 = function (e) {
  checkIdArr2.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr2.value.push(Number(e[i].id))
  }
  checkIdArr2.value = [...new Set(checkIdArr2.value)]
}
// 全选
let multipleTableRef2 = ref()
const checkAll2 = async function (e) {
  let arr = []
  await getAllDeviceListApi(qs.stringify({
    pageNumber: 1,
    pageSize: bTotal.value,
    name: bDeviceName.value,
    type: 1
  })).then(res => {
    if (res.data.code === 0) {
      arr = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 判断全选或全不选
  if (!multipleTableRef2.value.store.states.isAllSelected.value) {
    multipleTableRef2.value.clearSelection()
  } else {
    for (let i = 0; i < arr.length; i++) {
      multipleTableRef2.value.toggleRowSelection(arr[i], true)
    }
  }
}
const rowKey = function (row) {
  return row.id
}
const rowKey2 = function (row) {
  return row.id
}

// 提交绑定
const bindSubmit = function () {
  bindDeviceListApi(qs.stringify({
    type: 1,
    id: custerObj.value.id,
    idList: checkIdArr2.value.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      bindDeviceDialog.value = false
      getDeviceListFun(custerObj.value.id)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 解绑事件----------------------------------------------------------------------------
// 表格多选框选择事件
let checkIdArr = ref([])
const handleSelectionChange = function (e) {
  checkIdArr.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr.value.push(Number(e[i].id))
  }
  checkIdArr.value = [...new Set(checkIdArr.value)]
}
// 全选
let multipleTableRef = ref()
const checkAll = async function (e) {
  let arr = []
  let params = {
    pageNumber: 1,
    pageSize: total.value,
    id: custerObj.value.id,
    getSubFlag: 0,
    productModel: productModel.value,
    equipmentName: equipmentName.value,
    equipmentAddress: equipmentAddress.value
  }
  await getDeviceListApi(qs.stringify(params)).then(res => {
    if (res.data.ok) {
      arr = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 判断全选或全不选
  if (!multipleTableRef.value.store.states.isAllSelected.value) {
    multipleTableRef.value.clearSelection()
  } else {
    for (let i = 0; i < arr.length; i++) {
      multipleTableRef.value.toggleRowSelection(arr[i], true)
    }
  }
}
//编辑的点击
const editClick = function (row) {
  console.log(row)
}
/*编辑设备*/
let allCategoryList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryFun = function () {
  getAllCategory(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      allCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let productList = ref([]) //产品列表
//产品列表
const getProductListFun = function () {
  getProductList(qs.stringify({
    categoryId: deviceForm.value.categoryId
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let portList = ref([])
let rateTypeList = ref([])
let rulerTypeList = ref([])
let enableList = ref([{id: 1, name: '是'}, {id: 0, name: '否'}])
const getSelectListFun = function () {
  getSelectList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      portList.value = res.data.result.portList
      rateTypeList.value = res.data.result.rateTypeList
      rulerTypeList.value = res.data.result.rulerTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let gatewayList = ref([]) //网关列表
const getGatewayListFun = function () {
  getGatewayList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      gatewayList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

let dialogWidth = ref('60%') //新增设备弹窗宽度
let deviceDialogVisible = ref(false) //新增设备弹窗开关
let title = ref() //弹窗标题
let editId = ref() //编辑id
//新增设备的点击
const addClick = function (id, row) {
  if (id) {
    editId.value = id
    title.value = '编辑设备'
    getDetail(qs.stringify({
      id: id
    })).then(res => {
      //console.log(res);
      if (res.data.ok) {
        deviceForm.value.categoryId = res.data.result.categoryId
        deviceForm.value.productId = res.data.result.productId
        deviceForm.value.gatewayId = res.data.result.gatewayId
        deviceForm.value.name = res.data.result.name
        deviceForm.value.address = res.data.result.address
        deviceForm.value.enableFlag = res.data.result.enableFlag
        deviceForm.value.rulerType = String(res.data.result.rulerType)
        deviceForm.value.userType = res.data.result.userType
        deviceForm.value.rateType = String(res.data.result.rateType)
        deviceForm.value.port = String(res.data.result.port)
        deviceForm.value.rateV = res.data.result.rateV
        deviceForm.value.rateA = res.data.result.rateA
        deviceForm.value.remark = res.data.result.remark
        deviceForm.value.relationName = res.data.result.relationName
        allCategoryList.value.forEach(item => {
          if (item.id == deviceForm.value.categoryId) {
            categoryName.value = item.name
          }
        })
        getProductListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    editId.value = ''
    title.value = '新建设备'
    deviceForm.value = {}
  }
  deviceDialogVisible.value = true
}
let deviceFormRef = ref() //设备
let categoryName = ref()
//设备表单
let deviceForm = ref({
  categoryId: '',
  productId: '',
  powerGridRateId: '',
  gatewayId: '',
  name: '',
  address: '',
  enableFlag: '',
  rulerType: '',
  userType: '',
  rateType: '',
  port: '',
  rateV: '',
  rateA: '',
  remark: '',
  relationName: ''
})
// 设备表单验证规则
let deviceRules = ref({
  categoryId: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'blur'
  }],
  powerGridRateId: [{
    required: true,
    message: '请选择电网费率',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }],
  gatewayId: [{
    required: true,
    message: '请选择网关',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  port: [{
    required: true,
    message: '请选择端口',
    trigger: 'blur'
  }],
  rateV: [{
    required: true,
    message: '请输入电压变比',
    trigger: 'blur'
  }],
  rateA: [{
    required: true,
    message: '请输入电流变比',
    trigger: 'blur'
  }],
})

let powerGridRateData = ref()//费率配置列表
const powerGridRateListFun = function () {
  powerGridRateList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      powerGridRateData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//设备的保存
const deviceSave = function () {
  let params = {
    productId: deviceForm.value.productId,
    gatewayId: deviceForm.value.gatewayId,
    powerGridRateId: categoryName.value == '电表' ? deviceForm.value.powerGridRateId : null,
    name: deviceForm.value.name,
    address: deviceForm.value.address,
    enableFlag: deviceForm.value.enableFlag,
    rulerType: deviceForm.value.rulerType,
    userType: deviceForm.value.userType,
    rateType: deviceForm.value.rateType,
    port: deviceForm.value.port,
    rateV: deviceForm.value.rateV,
    rateA: deviceForm.value.rateA,
    remark: deviceForm.value.remark,
    relationName: deviceForm.value.relationName
  }
  if (title.value == '新建设备') {
    params['id'] = 0
  } else if (title.value == '编辑设备') {
    params['id'] = editId.value
  }
  if (deviceFormRef) {
    deviceFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            deviceDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
// 点击解绑
const removeClick = function (id) {
  ElMessageBox.confirm('请确认是否要解绑这些设备', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    removeDeviceListApi(qs.stringify({
      type: 1,
      id: custerObj.value.id,
      idList: id ? id : checkIdArr.value.join(',')
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '解绑成功',
        })
        getDeviceListFun(custerObj.value.id)
        currentNodeKey.value = undefined
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消解绑',
    })
  })
}
//分类改变
const categoryChange = function (id) {
  if (deviceForm.value.categoryId) {
    getProductListFun()
  }
  allCategoryList.value.forEach(item => {
    if (item.id == id) {
      categoryName.value = item.name
      if (item.name != '电表') {
        deviceForm.value.powerGridRateId = ''
      }
    }
  })
  deviceForm.value.productId = ''


}

const customizedClick = function () {
  router.push({
    path: '/admin/customized',
    query: {
      projectId: custerObj.value.id,
    }
  })
}

// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};


// 点击导出资产
const uploadProject = function (data) {
  openFullScreen();
  setTimeout(() => {
    loadingAdd.value.close();
    ElMessage({
      type: 'success',
      message: '导出成功'
    });
  }, 2000);
  // console.log(http + 'zouk/rest/project/export?projectId=' + data.id + '&memberId=' + window.localStorage.getItem('memberId'))
  window.location.href = http + 'zouk/rest/project/export?projectId=' + data.id + '&memberId=' + window.localStorage.getItem('memberId')
}


// 点击查看视频详情
const lookVideoDetail = function () {
  router.push('/admin/projectVideoPage')
}


// 模版操作----------------------------------------------------------------------------
// 所有的模版列表
let allTemplateList = ref([
  {
    templateName: 11,
    type: '配电室',
    url: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/templateImage1.png',
  },
  {
    templateName: 12,
    type: '配电室',
    url: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/templateImage2.png',
  }
])
let allTemplateListCopy = ref([
  {
    templateName: 11,
    type: '配电室',
    url: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/templateImage1.png',
  },
  {
    templateName: 12,
    type: '配电室',
    url: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/templateImage2.png',
  }
])
let addTemplateDialog = ref(false)  //新增模版弹窗
// 展示的模版列表
let tabList = ref([])
// 获取模版列表
const getTemplateListFun = function () {
  getProjectModuleListApi(qs.stringify({
    projectId: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      tabList.value = res.data.result
      for (let i = 0; i < tabList.value.length; i++) {
        if (tabList.value[i].topFlag == 1) {
          showFlag.value = tabList.value[i].type
          projectModuleId.value = tabList.value[i].id
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let templateType = ref()  //模版分类
// 新增模版表单数据
let addTemplateFormData = ref({
  id: 0,
  name: '',
  topFlag: 0,
  type: '',
  projectId: custerObj.value.id
})
// 新增模版表单规则
let addTemplateFormRules = ref({
  name: [{
    required: true,
    message: '请输入模版名称',
    trigger: 'blur'
  }],
  topFlag: [{
    required: true,
    message: '请选择是否默认展示',
    trigger: 'blur'
  }],
})
let isEdit = ref(false)
// 新增模版点击
const addTemplateClick = function (flag) {
  addTemplateDialog.value = true
  if (flag === 'edit') {
    isEdit.value = true
    for (let i = 0; i < tabList.value.length; i++) {
      if (tabList.value[i].type == showFlag.value) {
        addTemplateFormData.value = {...tabList.value[i], projectId: custerObj.value.id}
      }
    }
  } else {
    isEdit.value = false
    addTemplateFormData.value = {
      id: 0,
      name: '',
      topFlag: 0,
      type: '',
      projectId: custerObj.value.id
    }
  }
}
// 选择模版类型筛选模版
const templateTypeSelectChange = function () {
  allTemplateListCopy.value = []
  if (templateType.value) {
    for (let i = 0; i < allTemplateList.value.length; i++) {
      if (templateType.value === allTemplateList.value[i].type) {
        allTemplateListCopy.value.push(allTemplateList.value[i])
      }
    }
  } else {
    allTemplateListCopy.value = JSON.parse(JSON.stringify(allTemplateList.value))
  }
}
// 选择模版
const templateClick = function (item) {
  addTemplateFormData.value.type = item.templateName
}
// 新增模版提交
let addTemplateFormRef = ref()
const addTemplateSubmit = function () {
  if (addTemplateFormRef) {
    addTemplateFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (addTemplateFormData.value.type !== '') {
          saveProjectModuleListApi(qs.stringify(addTemplateFormData.value)).then(res => {
            if (res.data.code === 0) {
              addTemplateDialog.value = false
              getTemplateListFun()
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message,
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            type: 'info',
            message: '请选择模版',
          })
        }
      }
    })
  }
}
// 删除模版点击
const delTemplateClick = function () {
  let id
  for (let i = 0; i < tabList.value.length; i++) {
    if (tabList.value[i].type == showFlag.value) {
      id = tabList.value[i].id
    }
  }
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除模版',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此模版'),
    ])
  }).then(() => {
    delProjectModuleListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        showFlag.value = '5'
        getTemplateListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


let addLimit = ref(false)
let editLimit = ref(false)
let delLimit = ref(false)
let isOpenDevice = ref(1)
onMounted(() => {
  // getProjectListFun()
  getAllCategoryFun()
  getSelectListFun()
  getGatewayListFun()
  // powerGridRateListFun()
  if (router.currentRoute.value.query.type) {
    showFlag.value = '6'
  } else {
    showFlag.value = '5'
  }
  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      document.querySelector('.exitClass').style.display = 'block'
    } else {
      document.querySelector('.exitClass').style.display = 'none'
    }
  });

  // 是否展示直连设备
  getDeviceIsOpenApi().then(res => {
    if (res.data.ok) {
      isOpenDevice.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })


  // 获取项目的操作权限
  let arr = JSON.parse(window.window.localStorage.getItem('menuList'))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].menuKey === '/admin/project') {
      for (let j = 0; j < arr[i].subList.length; j++) {
        if (arr[i].subList[j].menuKey === 'add') {
          addLimit.value = true
        } else if (arr[i].subList[j].menuKey === 'edit') {
          editLimit.value = true
        } else if (arr[i].subList[j].menuKey === 'delete') {
          delLimit.value = true
        }
      }
    }
  }
})
</script>

<style>
#allmap {
  width: 100%;
  height: 200px;
  font-family: "微软雅黑";
  border: 1px solid green;
}

.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.tangram-suggestion {
  z-index: 99999;
}
</style>
