<template>
  <div class="template1">
    <div class="content">
      <!--第一排-->
      <div class="contentItem">
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">配电室</p>
            <div class="operation">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/importIcon.png">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/exportIcon.png">
              <el-icon class="setIcon" style="transform: translateY(-3px);" @click="selectSetDialog=true">
                <Setting/>
              </el-icon>
            </div>
          </div>
          <div class="contItemCenterBox">
            <img :src="templateData.image" alt="">
            <img v-show="custerObj.monitorList&&custerObj.monitorList.length>0" alt=""
                 src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/videoIcon.png"
                 @click="lookVideoDetail">
          </div>
          <div class="contItemBottomBox">
            <div class="contItemBottomItem">
              <p class="name">总容量：</p>
              <p class="val">
                {{ templateData.totalCapacity }} <span class="unit">KW</span>
              </p>
            </div>
            <div class="contItemBottomItem">
              <p class="name">总用电量：</p>
              <p class="val">
                {{ templateData.usePowerAmountTotal }} <span class="unit">KW</span>
              </p>
            </div>
            <div class="contItemBottomItem">
              <p class="name">今日用电量：</p>
              <p class="val">
                {{ templateData.usePowerAmountDaily }} <span class="unit">KW</span>
              </p>
            </div>
          </div>
        </div>
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">变压器参数</p>
            <!--            <el-icon class="setIcon" @click="transformerSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <div class="listBox">
            <div class="listItem">
              <div class="listLineItem"></div>
              <div class="listLineItem">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/itemIcon1.png">
                <p>电流</p>
              </div>
              <div class="listLineItem">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/itemIcon2.png">
                <p>电压</p>
              </div>
              <div class="listLineItem">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/itemIcon3.png">
                <p>温度</p>
              </div>
            </div>
            <div class="listItem">
              <div class="listLineItem">A相</div>
              <div class="listLineItem">{{ templateData.paramCurrent.aValue }} <span class="unit">A</span></div>
              <div class="listLineItem">{{ templateData.paramVoltage.aValue }} <span class="unit">V</span></div>
              <div class="listLineItem">{{ templateData.paramTemp.aValue }} <span class="unit">℃</span></div>
            </div>
            <div class="listItem">
              <div class="listLineItem">B相</div>
              <div class="listLineItem">{{ templateData.paramCurrent.bValue }} <span class="unit">A</span></div>
              <div class="listLineItem">{{ templateData.paramVoltage.bValue }} <span class="unit">V</span></div>
              <div class="listLineItem">{{ templateData.paramTemp.bValue }} <span class="unit">℃</span></div>
            </div>
            <div class="listItem">
              <div class="listLineItem">C相</div>
              <div class="listLineItem">{{ templateData.paramCurrent.cValue }} <span class="unit">A</span></div>
              <div class="listLineItem">{{ templateData.paramVoltage.cValue }} <span class="unit">V</span></div>
              <div class="listLineItem">{{ templateData.paramTemp.cValue }} <span class="unit">℃</span></div>
            </div>
          </div>
        </div>
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">告警事件</p>
            <!--            <el-icon class="setIcon" @click="warnSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <el-table :data="templateData.warnList" style="width: 100%">
            <el-table-column label="告警设备" prop="equipmentName"/>
            <el-table-column label="告警等级" prop="level">
              <template #default="scope">
                <p v-show="scope.row.level==3" class="statusBox"
                   style="background: rgba(205,88,249,0.1);color: #CD58F9">{{ scope.row.levelStr }}</p>
                <p v-show="scope.row.level==2" class="statusBox" style="background: rgba(229,105,3,0.1);color: #E27A00">
                  {{ scope.row.levelStr }}</p>
                <p v-show="scope.row.level==1" class="statusBox" style="background: rgba(236,39,9,0.1);color: #EC2709">
                  {{ scope.row.levelStr }}</p>
                <p v-show="scope.row.level==4" class="statusBox" style="background: rgba(25,211,66,0.1);color: #19D342">
                  {{ scope.row.levelStr }}</p>
              </template>
            </el-table-column>
            <el-table-column label="发生时间" prop="time"/>
            <el-table-column label="事件" prop="triggerRule"/>
          </el-table>
        </div>
      </div>
      <!--第二排-->
      <div v-show="showFlag==11" class="contentItem">
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">环境监测</p>
            <!--            <el-icon class="setIcon" @click="environmentSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <div class="listBox">
            <div class="listItem">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon4.png">
              <p class="val">{{ templateData.tempValue }}℃</p>
              <p class="name">温度</p>
            </div>
            <div class="listItem">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon5.png">
              <p class="val">{{ templateData.humidityValue }}%RH</p>
              <p class="name">湿度</p>
            </div>
            <div class="listItem">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon6.png">
              <p class="val">{{ templateData.pmValue }}μg/m³</p>
              <p class="name">Pm2.0</p>
            </div>
            <div :class="templateData.waterLeakageStatus==1?'listItemErr':''" class="listItem">
              <img v-show="templateData.waterLeakageStatus==1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon7Copy.png">
              <img v-show="templateData.waterLeakageStatus!=1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon7.png">
              <p class="val">{{ templateData.waterLeakageStatus == 1 ? '异常' : '正常' }}</p>
              <p class="name">水浸</p>
            </div>
            <div :class="templateData.smokeStatus==1?'listItemErr':''" class="listItem">
              <img v-show="templateData.smokeStatus==1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon8Copy.png">
              <img v-show="templateData.smokeStatus!=1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon8.png">
              <p class="val">{{ templateData.smokeStatus == 1 ? '异常' : '正常' }}</p>
              <p class="name">烟感</p>
            </div>
            <div :class="templateData.infraredStatus==1?'listItemErr':''" class="listItem">
              <img v-show="templateData.infraredStatus==1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon9Copy.png">
              <img v-show="templateData.infraredStatus!=1" alt=""
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/weatherIcon9.png">
              <p class="val">{{ templateData.infraredStatus == 1 ? '异常' : '正常' }}</p>
              <p class="name">红外</p>
            </div>
          </div>
        </div>
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">配电室负载率</p>
            <!--            <el-icon class="setIcon" @click="loadRateSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <div class="chartBox">
            <div class="loadRateChart"></div>
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/circleBgc.png">
          </div>
        </div>
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">用电量数据</p>
            <!--            <el-icon class="setIcon" @click="electricitySetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <div class="electricityRateChart"></div>
        </div>
      </div>
      <!--第三排-->
      <div class="contentItem">
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">{{ templateData.powerName }}</p>
            <!--            <el-icon class="setIcon" @click="powerSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <el-tabs v-model="powerActive" class="demo-tabs" @tab-click="powerHandleClick">
            <el-tab-pane :name="1" label="有功功率"></el-tab-pane>
            <el-tab-pane :name="2" label="无功功率"></el-tab-pane>
            <el-tab-pane :name="3" label="视在功率"></el-tab-pane>
          </el-tabs>
          <div class="dateBox">
            <el-date-picker v-model="powerRateDateTime" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→"
                            start-placeholder="开始时间"
                            type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            @change="powerRateTimeChange"></el-date-picker>
          </div>
          <div v-show="powerData.xAxis.length>0" class="arkLineChart"></div>
        </div>
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">{{ templateData.usPowerName }}</p>
            <div style="display: flex;align-items: center">
              <div class="timeTabBox">
                <p :class="timeTab=='1h'?'timeTabActive':''" class="timeTabItem" @click="timeTabClick('1h')">时</p>
                <p :class="timeTab=='1d'?'timeTabActive':''" class="timeTabItem" @click="timeTabClick('1d')">日</p>
                <p :class="timeTab=='1n'?'timeTabActive':''" class="timeTabItem" @click="timeTabClick('1n')">月</p>
                <p :class="timeTab=='1y'?'timeTabActive':''" class="timeTabItem" @click="timeTabClick('1y')">年</p>
              </div>
              <!--              <el-icon class="setIcon" @click="electricQuantitySetClick">-->
              <!--                <Setting/>-->
              <!--              </el-icon>-->
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;margin-top: 5px">
            <p class="name">用电量柱状图</p>
            <div class="dateBox">
              <el-date-picker v-model="electricQuantityRateDateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→" start-placeholder="开始时间"
                              type="datetimerange"
                              value-format="YYYY-MM-DD HH:mm:ss"
                              @change="electricQuantityRateTimeChange"></el-date-picker>
            </div>
          </div>
          <div v-show="electricQuantityData.xAxis&&electricQuantityData.xAxis.length>0" class="arkBarChart"></div>
        </div>
      </div>
      <!--第四排-->
      <div class="contentItem">
        <div class="contItem">
          <div class="contItemHeadBox">
            <p class="tit">设备</p>
            <!--            <el-icon class="setIcon" @click="deviceSetClick">-->
            <!--              <Setting/>-->
            <!--            </el-icon>-->
          </div>
          <el-table :data="deviceTableData" style="width: 100%">
            <el-table-column label="设备名称" prop="name" width="120"/>
            <el-table-column v-for="(item,index) in image" :key="item.key" :label="item.key"
                             :prop="item.value"/>
            <el-table-column label="通讯状态" width="80">
              <template #default="scope">
                <div class="statusBox">
                  <img v-show="scope.row.useStatus!=1" alt=""
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/joinErr.png">
                  <img v-show="scope.row.useStatus==1" alt=""
                       src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/joinSuccess.png">
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <div v-show="scope.row.equipmentType==1" class="operation" style="min-width: 85px"
                     @click="goLookDetail(scope.row.equipmentId,scope.row.equipmentIdStr,scope.row.equipmentType,scope.row.nodeType,scope.row.name,scope.row.projectName)">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/detail.png">
                  <span>详参</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerBox">
            <el-pagination :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]"
                           :total="total" background layout="total,sizes, prev, pager, next" small
                           @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
          </div>
        </div>
      </div>
    </div>
    <!--选择配置哪个模块的弹窗-->
    <el-dialog v-model="selectSetDialog" class="selectSetDialog" title="配电室配置" width="60%">
      <div class="listBox">
        <div class="listItem" @click="selectSetSubmit(1)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect1.png">
        </div>
        <div class="listItem" @click="selectSetSubmit(2)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect2.png">
        </div>
        <div class="listItem" @click="selectSetSubmit(3)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect3.png">
        </div>
        <div v-if="showFlag==11" class="listItem" @click="selectSetSubmit(4)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect4.png">
        </div>
        <div v-if="showFlag==11" class="listItem" @click="selectSetSubmit(5)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect5.png">
        </div>
        <div v-if="showFlag==11" class="listItem" @click="selectSetSubmit(6)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect6.png">
        </div>
        <div class="listItem" @click="selectSetSubmit(7)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect7.png">
        </div>
        <div class="listItem" @click="selectSetSubmit(8)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect8.png">
        </div>
        <div class="listItem" @click="selectSetSubmit(9)">
          <img alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/tempSelect/tempSelect9.png">
        </div>
      </div>
    </el-dialog>
    <!--配电室配置-->
    <el-dialog v-model="roomSetDialog" class="roomSetDialog" title="配电室配置" width="50%">
      <el-form ref="roomSetFormRef" :model="roomSetFormData" :rules="roomSetFormRules" class="demo-ruleForm"
               status-icon>
        <el-form-item label="总容量" name="totalCapacity" prop="totalCapacity">
          <el-input v-model="roomSetFormData.totalCapacity" placeholder="请输入总容量"/>
        </el-form-item>
        <el-form-item label="用电量设备" name="equipmentIdJson" prop="equipmentIdJson">
          <el-select ref="elSelectTable" v-model="roomSetFormData.equipmentIdJson" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="true"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
        <el-form-item label="配电室图片" name="image" prop="image">
          <el-upload :auto-upload="false" :class="{hideUpload:hideUploadEdit}" :file-list='roomSetFormData.image'
                     :limit="1" accept=".png,.jpg" action="#" list-type="picture-card" @change="uploadFile">
            <el-icon>
              <Plus/>
            </el-icon>
            <p>上传图片</p>
            <template #file="{ file }">
              <div>
                <img :src="file.url" alt="" class="el-upload-list__item-thumbnail"/>
                <span class="el-upload-list__item-actions">
										  <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                      >
										    <el-icon><ZoomIn/></el-icon>
										  </span>
								<span class="el-upload-list__item-delete" @click="handleRemove(file)">
            							  <el-icon><Delete/></el-icon>
          								</span>
								</span>
              </div>
            </template>
          </el-upload>
          <span style="margin-left: 20px;">（ 支持jpg、png格式 ）</span>
        </el-form-item>

        <el-dialog v-model="cropperDialogVisible" :close-on-press-escape="false" title="图片裁剪" width="800px">
          <div class="cropper-container">
            <VueCropper
                ref="cropper"
                :autoCrop="true"
                :autoCropHeight="300"
                :autoCropWidth="516"
                :fixedBox="true"
                :img="cropperImg"
                :outputSize="1"
                outputType="png"
            ></VueCropper>
          </div>
          <template #footer>
            <span class="dialog-footer">
<!--              <el-button @click="cropperDialogVisible = false">取消</el-button>-->
              <el-button type="primary" @click="confirmCropClick">确认</el-button>
            </span>
          </template>
        </el-dialog>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="roomSetDialog = false">取消</el-button>
        <el-button type="primary" @click="roomSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="查看大图">
      <img :src="dialogImageUrl" alt="Preview Image" style="width:100%" w-full/>
    </el-dialog>
    <!--变压器配置-->
    <el-dialog v-model="transformerSetDialog" class="roomSetDialog" title="变压器参数配置" width="50%">
      <el-form ref="transformerSetFormRef" :model="transformerSetFormData" :rules="transformerSetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="变压器设备" name="equipmentId" prop="equipmentId">
          <el-select ref="elSelectTable" v-model="transformerSetFormData.equipmentId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="false"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
        <div class="tit">电流配置</div>
        <el-form-item label="A相" name="paramCurrentJson.aId"
                      prop="paramCurrentJson.aId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramCurrentJson.aId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(1)">
            <el-option v-for="(item,index) in dictList[0]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===1" :checkDictList="dictList[0]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="B相" name="paramCurrentJson.bId"
                      prop="paramCurrentJson.bId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramCurrentJson.bId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(2)">
            <el-option v-for="(item,index) in dictList[1]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===2" :checkDictList="dictList[1]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="C相" name="paramCurrentJson.cId"
                      prop="paramCurrentJson.cId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramCurrentJson.cId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(3)">
            <el-option v-for="(item,index) in dictList[2]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===3" :checkDictList="dictList[2]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <div class="tit">电压配置</div>
        <el-form-item label="A相" name="paramVoltageJson.aId"
                      prop="paramVoltageJson.aId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramVoltageJson.aId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(4)">
            <el-option v-for="(item,index) in dictList[3]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===4" :checkDictList="dictList[3]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="B相" name="paramVoltageJson.bId"
                      prop="paramVoltageJson.bId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramVoltageJson.bId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(5)">
            <el-option v-for="(item,index) in dictList[4]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===5" :checkDictList="dictList[4]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="C相" name="paramVoltageJson.cId"
                      prop="paramVoltageJson.cId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramVoltageJson.cId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(6)">
            <el-option v-for="(item,index) in dictList[5]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===6" :checkDictList="dictList[5]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <div class="tit">温度配置</div>
        <el-form-item label="A相" name="paramTempJson.aId"
                      prop="paramTempJson.aId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramTempJson.aId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(7)">
            <el-option v-for="(item,index) in dictList[6]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===7" :checkDictList="dictList[6]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="B相" name="paramTempJson.bId"
                      prop="paramTempJson.bId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramTempJson.bId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(8)">
            <el-option v-for="(item,index) in dictList[7]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===8" :checkDictList="dictList[7]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
        <el-form-item label="C相" name="paramTempJson.cId"
                      prop="paramTempJson.cId">
          <el-select ref="elSelectTable"
                     v-model="transformerSetFormData.paramTempJson.cId"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(9)">
            <el-option v-for="(item,index) in dictList[8]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===9" :checkDictList="dictList[8]" :dictVisible="dictVisible"
                     :equipmentId="transformerSetFormData.equipmentId[0]"
                     :isMultiple="false"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="transformerSetDialog = false">取消</el-button>
        <el-button type="primary" @click="transformerSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--告警配置-->
    <el-dialog v-model="warnSetDialog" class="warnSetDialog" title="告警配置" width="50%">
      <el-form ref="warnSetFormRef" :model="warnSetFormData" :rules="warnSetFormRules" class="demo-ruleForm"
               status-icon>
        <el-form-item label="告警设备" name="equipmentIdJson" prop="equipmentIdJson">
          <el-select ref="elSelectTable" v-model="warnSetFormData.equipmentIdJson" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="true"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="warnSetDialog = false">取消</el-button>
        <el-button type="primary" @click="warnSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--环境监控-->
    <el-dialog v-model="environmentSetDialog" class="environmentSetDialog" title="环境监控配置" width="50%">
      <el-form ref="environmentSetFormRef" :model="environmentSetFormData" :rules="environmentSetFormRules"
               class="demo-ruleForm"
               status-icon>
        <div class="tit">温度</div>
        <el-form-item label="温度设备" name="tempJson.dictId" prop="tempJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.tempJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(1)">
            <el-option v-for="(item,index) in deviceDictList[0]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===1" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <div class="tit">湿度</div>
        <el-form-item label="湿度设备" name="humidityJson.dictId" prop="humidityJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.humidityJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(2)">
            <el-option v-for="(item,index) in deviceDictList[1]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===2" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <div class="tit">pm</div>
        <el-form-item label="pm设备" name="pmJson.dictId" prop="pmJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.pmJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(3)">
            <el-option v-for="(item,index) in deviceDictList[2]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===3" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <div class="tit">水浸</div>
        <el-form-item label="水浸设备" name="waterLeakageJson.dictId" prop="waterLeakageJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.waterLeakageJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(4)">
            <el-option v-for="(item,index) in deviceDictList[3]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===4" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <el-form-item label="正常值" name="waterLeakageJson.normalValue" prop="waterLeakageJson.normalValue">
          <el-input v-model="environmentSetFormData.waterLeakageJson.normalValue" placeholder="请输入正常值"></el-input>
        </el-form-item>
        <el-form-item label="异常值" name="waterLeakageJson.exceptionValue" prop="waterLeakageJson.exceptionValue">
          <el-input v-model="environmentSetFormData.waterLeakageJson.exceptionValue" placeholder="请输入异常值"></el-input>
        </el-form-item>
        <div class="tit">烟感</div>
        <el-form-item label="烟感设备" name="smokeJson.dictId" prop="smokeJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.smokeJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(5)">
            <el-option v-for="(item,index) in deviceDictList[4]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===5" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <el-form-item label="正常值" name="smokeJson.normalValue" prop="smokeJson.normalValue">
          <el-input v-model="environmentSetFormData.smokeJson.normalValue" placeholder="请输入正常值"></el-input>
        </el-form-item>
        <el-form-item label="异常值" name="smokeJson.exceptionValue" prop="smokeJson.exceptionValue">
          <el-input v-model="environmentSetFormData.smokeJson.exceptionValue" placeholder="请输入异常值"></el-input>
        </el-form-item>
        <div class="tit">红外</div>
        <el-form-item label="红外设备" name="infraredJson.dictId" prop="infraredJson.dictId">
          <el-select ref="elSelectTable" v-model="environmentSetFormData.infraredJson.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(6)">
            <el-option v-for="(item,index) in deviceDictList[5]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect v-if="deviceDictSelectIdx===6" :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
        <el-form-item label="正常值" name="infraredJson.normalValue" prop="infraredJson.normalValue">
          <el-input v-model="environmentSetFormData.infraredJson.normalValue" placeholder="请输入正常值"></el-input>
        </el-form-item>
        <el-form-item label="异常值" name="infraredJson.exceptionValue" prop="infraredJson.exceptionValue">
          <el-input v-model="environmentSetFormData.infraredJson.exceptionValue" placeholder="请输入异常值"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="environmentSetDialog = false">取消</el-button>
        <el-button type="primary" @click="environmentSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--负载率-->
    <el-dialog v-model="loadRateSetDialog" class="loadRateSetDialog" title="负载率配置" width="50%">
      <el-form ref="loadRateSetFormRef" :model="loadRateSetFormData" :rules="loadRateSetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="负载率设备指标" name="dictId" prop="dictId">
          <el-select ref="elSelectTable" v-model="loadRateSetFormData.dictId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple placeholder="请选择设备指标" popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceDictSelectClick(1)">
            <el-option v-for="(item,index) in deviceDictList[0]" :key="index"
                       :label="item.deviceName+'-'+item.dictName" :value="item.dictId"></el-option>
          </el-select>
          <DeviceDictSelect :deviceDictVisible="deviceDictVisible"
                            @checkDeviceDictListFun="checkDeviceDictListFun"
                            @deviceDictVisibleFun="deviceDictVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="loadRateSetDialog = false">取消</el-button>
        <el-button type="primary" @click="loadRateSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--用电量数据配置-->
    <el-dialog v-model="electricitySetDialog" class="electricitySetDialog" title="用电量数据配置" width="50%">
      <el-form ref="electricitySetFormRef" :model="electricitySetFormData" :rules="electricitySetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="用电量数据设备" name="equipmentIdJson" prop="equipmentIdJson">
          <el-select ref="elSelectTable" v-model="electricitySetFormData.equipmentIdJson" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="true"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
      </el-form>

      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="electricitySetDialog = false">取消</el-button>
        <el-button type="primary" @click="electricitySetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--功率配置-->
    <el-dialog v-model="powerSetDialog" class="roomSetDialog" title="功率配置" width="50%">
      <el-form ref="powerSetFormRef" :model="powerSetFormData" :rules="powerSetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="模块名称" name="name" prop="name">
          <el-input v-model="powerSetFormData.name" placeholder="请输入模块名称"></el-input>
        </el-form-item>
        <el-form-item label="功率设备" name="equipmentId" prop="equipmentId">
          <el-select ref="elSelectTable" v-model="powerSetFormData.equipmentId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="false"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="powerSetDialog = false">取消</el-button>
        <el-button type="primary" @click="powerSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--用电量配置-->
    <el-dialog v-model="electricQuantitySetDialog" class="roomSetDialog" title="用电量配置" width="50%">
      <el-form ref="electricQuantitySetFormRef" :model="electricQuantitySetFormData"
               :rules="electricQuantitySetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="模块名称" name="name" prop="name">
          <el-input v-model="electricQuantitySetFormData.name" placeholder="请输入模块名称"></el-input>
        </el-form-item>
        <el-form-item label="用电量设备" name="equipmentId" prop="equipmentId">
          <el-select ref="elSelectTable" v-model="electricQuantitySetFormData.equipmentId" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="false"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="electricQuantitySetDialog = false">取消</el-button>
        <el-button type="primary" @click="electricQuantitySetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
    <!--设备配置-->
    <el-dialog v-model="deviceSetDialog" class="roomSetDialog" title="设备配置" width="50%">
      <el-form ref="deviceSetFormRef" :model="deviceSetFormData" :rules="deviceSetFormRules"
               class="demo-ruleForm"
               status-icon>
        <el-form-item label="设备" name="equipmentIdJson" prop="equipmentIdJson">
          <el-select ref="elSelectTable" v-model="deviceSetFormData.equipmentIdJson" :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="deviceSelectClick">
            <el-option v-for="(item,index) in equipmentList" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <DeviceCheck :checkDeviceList="equipmentList" :deviceVisible="deviceVisible" :isMultiple="true"
                       @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
        </el-form-item>
        <el-form-item label="指标" name="dictJson" prop="dictJson">
          <el-select ref="elSelectTable"
                     v-model="deviceSetFormData.dictIdJson"
                     :disabled="true"
                     :popper-append-to-body="false" :suffix-icon="Plus" class="timeSelect"
                     multiple popper-class="timeSelectPopper" style="width: 500px"
                     @click="dictSelectClick(1)">
            <el-option v-for="(item,index) in dictList[0]" :key="index"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
          <!--指标选择弹窗-->
          <DictCheck v-if="dictSelectIdx===1" :checkDictList="dictList[0]" :dictVisible="dictVisible"
                     :equipmentId="deviceSetFormData.equipmentIdJson"
                     :isMultiple="true"
                     @checkDictListFun="checkDictListFun" @dictVisibleFun="dictVisibleFun"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="resetButton" @click="deviceSetDialog = false">取消</el-button>
        <el-button type="primary" @click="deviceSetSubmit">确定</el-button>
     </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, onMounted, reactive, ref, watch, nextTick, computed} from "vue"
import {Setting, Plus, ZoomIn, Delete} from '@element-plus/icons-vue'
import './template1.less'
import * as echarts from "echarts";
import DeviceCheck from './component/deviceCheck'
import DictCheck from './component/dictCheck'
import DeviceDictSelect from './component/deviceDictSelect'
import {
  getPowerRoomDataApi1, getPowerRoomDictApi1,
  getPowerRoomEquipmentApi1,
  getPowerRoomRateChartDataApi1,
  getPowerRoomUsePowerAmountChartDataApi1,
  getProjectModuleConfigDetailApi1,
  saveWithEnvironmentMonitoringApi1,
  saveWithEquipmentApi1,
  saveWithPowerRoomApi1,
  saveWithPowerRoomLoadRateApi1,
  saveWithRateApi1,
  saveWithTransformerParameterApi1,
  saveWithUsePowerApi1,
  saveWithUsePowerDataApi1,
  saveWithWarnApi1
} from "@/api/modules/project";
import qs from "qs";
import {ElMessage} from "element-plus";
import {uploadImg} from "@/api/modules/bigScreenConfigure";
import moment from "moment";
import {useRouter} from "vue-router/dist/vue-router";
import 'vue-cropper/dist/index.css'
import {VueCropper} from "vue-cropper";

let router = useRouter()
const props = defineProps(['custerObj', 'projectModuleId', 'showFlag']);

// 点击查看视频
const lookVideoDetail = function () {
  router.push('/admin/projectVideoPage')
}


// 获取页面数据***************************************************************************
let templateData = ref({
  humidityValue: {},//湿度
  infraredStatus: {},//红外
  loadRate: {},//负载率
  paramCurrent: {},//变压器 电流
  paramTemp: {},//变压器 温度
  paramVoltage: {},//变压器 电压
  pmValue: {}, //pm
  smokeStatus: {},  //烟感
  tempValue: {},  //温度
  totalCapacity: {},  //总容量
  usePowerAmountDaily: {},  //今日用电量
  usePowerAmountList: [],   //用电量数据
  usePowerAmountTotal: {},  //总用电量
  warnList: [],  //告警列表
  waterLeakageStatus: 0, //水浸 状态：1-异常，0-正常
  powerName: '-',
  usPowerName: '-',
})
const getTemplateDataFun = function () {
  getPowerRoomDataApi1(qs.stringify({
    id: projectModuleId.value
  })).then(res => {
    if (res.data.code === 0) {
      templateData.value = JSON.parse(JSON.stringify(res.data.result))

      // 用电量数据处理
      templateData.value.usePowerAmountList1 = []
      templateData.value.usePowerAmountList2 = []
      for (let i = 0; i < templateData.value.usePowerAmountList.length; i++) {
        templateData.value.usePowerAmountList1.push({
          name: templateData.value.usePowerAmountList[i].key,
          value: templateData.value.usePowerAmountList[i].value,
        })
        templateData.value.usePowerAmountList2.push({
          name: templateData.value.usePowerAmountList[i].key,
          value: templateData.value.usePowerAmountList[i].value,
        })
        if (templateData.value.usePowerAmountList[i].key === '尖') {
          templateData.value.usePowerAmountList1[i].itemStyle = {
            color: '#FFD765',
          }
          templateData.value.usePowerAmountList2[i].itemStyle = {
            color: '#FFF7E1',
          }
        } else if (templateData.value.usePowerAmountList[i].key === '峰') {
          templateData.value.usePowerAmountList1[i].itemStyle = {
            color: '#FDA3A3',
          }
          templateData.value.usePowerAmountList2[i].itemStyle = {
            color: '#FFEDED',
          }
        } else if (templateData.value.usePowerAmountList[i].key === '平') {
          templateData.value.usePowerAmountList1[i].itemStyle = {
            color: '#F89CE3',
          }
          templateData.value.usePowerAmountList2[i].itemStyle = {
            color: '#FEEBF9',
          }
        } else if (templateData.value.usePowerAmountList[i].key === '谷') {
          templateData.value.usePowerAmountList1[i].itemStyle = {
            color: '#77CBF1',
          }
          templateData.value.usePowerAmountList2[i].itemStyle = {
            color: '#E4F5FC',
          }
        } else if (templateData.value.usePowerAmountList[i].key === '深谷') {
          templateData.value.usePowerAmountList1[i].itemStyle = {
            color: '#24E4AE',
          }
          templateData.value.usePowerAmountList2[i].itemStyle = {
            color: '#D3FAEF',
          }
        }
      }

      nextTick(() => {
        loadRateChartLoad()  //负载率图表
        electricityChartLoad()  //用电量数据图表
      })

    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 点击选择配置哪个模块
let selectSetDialog = ref(false)
const selectSetSubmit = function (idx) {
  if (idx) {
    // selectSetDialog.value = false
    if (idx === 1) {
      roomSetClick()
    } else if (idx === 2) {
      transformerSetClick()
    } else if (idx === 3) {
      warnSetClick()
    } else if (idx === 4) {
      environmentSetClick()
    } else if (idx === 5) {
      loadRateSetClick()
    } else if (idx === 6) {
      electricitySetClick()
    } else if (idx === 7) {
      powerSetClick()
    } else if (idx === 8) {
      electricQuantitySetClick()
    } else if (idx === 9) {
      deviceSetClick()
    }
  } else {
    ElMessage({
      type: 'info',
      message: '请选择配置的模块！'
    })
  }
}


// 配电室***************************************************************************
let roomSetDialog = ref(false)  //配电室弹窗
let roomSetFormData = ref({  //配电室表单数据
  totalCapacity: '',
  equipmentIdJson: [],
  image: []
})
const arrayLengthValidator = function (rule, value, callback) {
  if (value.length > 0) {
    callback(); // 通过验证
  } else {
    callback(new Error('请选择用电量设备')); // 未通过验证，显示错误信息
  }
}
let roomSetFormRules = ref({  //配电室表单规则
  totalCapacity: [{
    required: true,
    message: '请输入总容量',
    trigger: 'blur'
  }],
  equipmentIdJson: [{
    required: true,
    validator: arrayLengthValidator,
    trigger: 'blur'
  }],
  image: [{
    required: true,
    message: '请上传配电室图片',
    trigger: 'blur'
  }],
})
// 点击配置配电室
const roomSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 1
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      equipmentList.value = data.equipmentList
      roomSetFormData.value.totalCapacity = data.totalCapacity
      roomSetFormData.value.equipmentIdJson = []
      for (let i = 0; i < data.equipmentList.length; i++) {
        roomSetFormData.value.equipmentIdJson.push(data.equipmentList[i].id)
      }
      if (data.image) {
        hideUploadEdit.value = true
        roomSetFormData.value.image = [{
          name: 'aaa',
          url: data.image
        }]
      } else {
        hideUploadEdit.value = false
      }

      nextTick(() => {
        roomSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击配电室提交
let roomSetFormRef = ref()
const roomSetSubmit = function () {
  if (roomSetFormRef) {
    roomSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithPowerRoomApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          totalCapacity: roomSetFormData.value.totalCapacity,
          image: roomSetFormData.value.image[0].url,
          equipmentIdJson: JSON.stringify(roomSetFormData.value.equipmentIdJson),
        })).then(res => {
          if (res.data.code === 0) {
            roomSetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}

/** 上传图片 **/
let dialogVisible = ref(false) //大图弹窗显示开关
let dialogImageUrl = ref() //大图列表
let hideUploadEdit = ref(false) //图片上传显示开关
// 裁剪图片
let cropper = ref()
let cropperImg = ref()
let cropperDialogVisible = ref(false)
//上传文件
const uploadFile = function (file) {
  if ((file.size / 1024 / 1024) > 5) {
    ElMessage({
      message: '图片大小不能超过5M!',
      type: 'info'
    })
    roomSetFormData.value.image = []
  } else {
    cropperDialogVisible.value = true
    nextTick(() => {
      const reader = new FileReader();
      reader.onload = (e) => {
        cropperImg.value = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    })
  }
}

const confirmCropClick = function () {
  cropper.value.getCropBlob(data => {
    const croppedFile = new File([data], 'cropped-image.png', {
      type: 'image/png',
      lastModified: Date.now(),
    });
    let fd = new FormData();
    //注意是文件流file.raw
    fd.append('file', croppedFile); //传文件
    uploadImg(
        fd
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        hideUploadEdit.value = true
        roomSetFormData.value.image = []
        roomSetFormData.value.image.push({
          name: 'aaa',
          url: res.data.result.fullPath
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
    cropperDialogVisible.value = false
  })

}

//删除图片
const handleRemove = (file) => {
  roomSetFormData.value.image = []
  hideUploadEdit.value = false
}
//查看大图
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}


// 变压器参数***************************************************************************
let transformerSetDialog = ref(false)  //配电室弹窗
let transformerSetFormData = ref({  //配电室表单数据
  equipmentId: [],
  paramCurrentJson: {
    aId: '',
    bId: '',
    cId: '',
  },
  paramVoltageJson: {
    aId: '',
    bId: '',
    cId: '',
  },
  paramTempJson: {
    aId: '',
    bId: '',
    cId: '',
  }
})
let transformerSetFormRules = ref({  //配电室表单规则
  equipmentId: [{
    required: true,
    message: '请选择设备',
    trigger: 'blur'
  }],
  // 'paramCurrentJson.aId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramCurrentJson.bId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramCurrentJson.cId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramVoltageJson.aId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramVoltageJson.bId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramVoltageJson.cId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramTempJson.aId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramTempJson.bId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
  // 'paramTempJson.cId': [{
  //   required: true,
  //   message: '请选择指标',
  //   trigger: 'blur'
  // }],
})
// 点击配置变压器
const transformerSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 2
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      transformerSetFormData.value.equipmentId = data.equipmentId ? [data.equipmentId] : []
      equipmentList.value = data.equipmentId ? [{
        id: data.equipmentId,
        name: data.equipmentName
      }] : []
      transformerSetFormData.value.paramCurrentJson = {
        aId: data.paramCurrent.aId ? [data.paramCurrent.aId] : [],
        bId: data.paramCurrent.bId ? [data.paramCurrent.bId] : [],
        cId: data.paramCurrent.cId ? [data.paramCurrent.cId] : [],
      }
      transformerSetFormData.value.paramVoltageJson = {
        aId: data.paramVoltage.aId ? [data.paramVoltage.aId] : [],
        bId: data.paramVoltage.bId ? [data.paramVoltage.bId] : [],
        cId: data.paramVoltage.cId ? [data.paramVoltage.cId] : [],
      }
      transformerSetFormData.value.paramTempJson = {
        aId: data.paramTemp.aId ? [data.paramTemp.aId] : [],
        bId: data.paramTemp.bId ? [data.paramTemp.bId] : [],
        cId: data.paramTemp.cId ? [data.paramTemp.cId] : [],
      }
      dictList.value = [
        [{
          id: data.paramCurrent.aId,
          name: data.paramCurrent.aName,
        }], [{
          id: data.paramCurrent.bId,
          name: data.paramCurrent.bName,
        }], [{
          id: data.paramCurrent.cId,
          name: data.paramCurrent.cName,
        }], [{
          id: data.paramVoltage.aId,
          name: data.paramVoltage.aName,
        }], [{
          id: data.paramVoltage.bId,
          name: data.paramVoltage.bName,
        }], [{
          id: data.paramVoltage.cId,
          name: data.paramVoltage.cName,
        }], [{
          id: data.paramTemp.aId,
          name: data.paramTemp.aName,
        }], [{
          id: data.paramTemp.bId,
          name: data.paramTemp.bName,
        }], [{
          id: data.paramTemp.cId,
          name: data.paramTemp.cName,
        }]
      ]


      nextTick(() => {
        transformerSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击配电室提交
let transformerSetFormRef = ref()
const transformerSetSubmit = function () {
  if (transformerSetFormRef) {
    transformerSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let paramCurrentJson = {
          aId: transformerSetFormData.value.paramCurrentJson.aId[0],
          bId: transformerSetFormData.value.paramCurrentJson.bId[0],
          cId: transformerSetFormData.value.paramCurrentJson.cId[0],
        }
        let paramVoltageJson = {
          aId: transformerSetFormData.value.paramVoltageJson.aId[0],
          bId: transformerSetFormData.value.paramVoltageJson.bId[0],
          cId: transformerSetFormData.value.paramVoltageJson.cId[0],
        }
        let paramTempJson = {
          aId: transformerSetFormData.value.paramTempJson.aId[0],
          bId: transformerSetFormData.value.paramTempJson.bId[0],
          cId: transformerSetFormData.value.paramTempJson.cId[0],
        }
        saveWithTransformerParameterApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentId: transformerSetFormData.value.equipmentId[0],
          paramCurrentJsonStr: JSON.stringify(paramCurrentJson),
          paramVoltageJsonStr: JSON.stringify(paramVoltageJson),
          paramTempJsonStr: JSON.stringify(paramTempJson),
        })).then(res => {
          if (res.data.code === 0) {
            transformerSetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 告警***************************************************************************
let warnSetDialog = ref(false)  //告警弹窗
let warnSetFormData = ref({  //配电室表单数据
  equipmentIdJson: [],
})
const arrayLengthValidator2 = function (rule, value, callback) {
  if (value.length > 0) {
    callback(); // 通过验证
  } else {
    callback(new Error('请选择告警设备')); // 未通过验证，显示错误信息
  }
}
let warnSetFormRules = ref({  //配电室表单规则
  equipmentIdJson: [{
    required: true,
    validator: arrayLengthValidator2,
    trigger: 'blur'
  }],
})
// 点击配置告警
const warnSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 3
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      equipmentList.value = data.equipmentList
      warnSetFormData.value.equipmentIdJson = []
      for (let i = 0; i < data.equipmentList.length; i++) {
        warnSetFormData.value.equipmentIdJson.push(data.equipmentList[i].id)
      }
      nextTick(() => {
        warnSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击告警提交
let warnSetFormRef = ref()
const warnSetSubmit = function () {
  if (warnSetFormRef) {
    warnSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithWarnApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentIdJson: JSON.stringify(warnSetFormData.value.equipmentIdJson),
        })).then(res => {
          if (res.data.code === 0) {
            warnSetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 环境监控***************************************************************************
let environmentSetDialog = ref(false)  //环境弹窗
let environmentSetFormData = ref({  //配电室表单数据
  tempJson: {
    dictId: '',
    equipmentId: '',
  },
  humidityJson: {
    dictId: '',
    equipmentId: '',
  },
  pmJson: {
    dictId: '',
    equipmentId: '',
  },
  waterLeakageJson: {
    dictId: '',
    equipmentId: '',
    normalValue: '',
    exceptionValue: '',
  },
  smokeJson: {
    dictId: '',
    equipmentId: '',
    normalValue: '',
    exceptionValue: '',
  },
  infraredJson: {
    dictId: '',
    equipmentId: '',
    normalValue: '',
    exceptionValue: '',
  }
})
let environmentSetFormRules = ref({  //配电室表单规则
  'tempJson.dictId': [{
    required: true,
    message: '请选择温度指标',
    trigger: 'blur'
  }],
  'tempJson.equipmentId': [{
    required: true,
    message: '请选择温度设备',
    trigger: 'blur'
  }],
  'humidityJson.dictId': [{
    required: true,
    message: '请选择湿度指标',
    trigger: 'blur'
  }],
  'humidityJson.equipmentId': [{
    required: true,
    message: '请选择湿度设备',
    trigger: 'blur'
  }],
  'pmJson.dictId': [{
    required: true,
    message: '请选择pm指标',
    trigger: 'blur'
  }],
  'pmJson.equipmentId': [{
    required: true,
    message: '请选择pm设备',
    trigger: 'blur'
  }],
  'waterLeakageJson.dictId': [{
    required: true,
    message: '请选择水浸指标',
    trigger: 'blur'
  }],
  'waterLeakageJson.equipmentId': [{
    required: true,
    message: '请选择水浸设备',
    trigger: 'blur'
  }],
  'waterLeakageJson.normalValue': [{
    required: true,
    message: '请输入水浸正常值',
    trigger: 'blur'
  }],
  'waterLeakageJson.exceptionValue': [{
    required: true,
    message: '请输入水浸异常值',
    trigger: 'blur'
  }],
  'smokeJson.dictId': [{
    required: true,
    message: '请选择烟感指标',
    trigger: 'blur'
  }],
  'smokeJson.equipmentId': [{
    required: true,
    message: '请选择烟感设备',
    trigger: 'blur'
  }],
  'smokeJson.normalValue': [{
    required: true,
    message: '请输入烟感正常值',
    trigger: 'blur'
  }],
  'smokeJson.exceptionValue': [{
    required: true,
    message: '请输入烟感异常值',
    trigger: 'blur'
  }],
  'infraredJson.dictId': [{
    required: true,
    message: '请选择红外指标',
    trigger: 'blur'
  }],
  'infraredJson.equipmentId': [{
    required: true,
    message: '请选择红外设备',
    trigger: 'blur'
  }],
  'infraredJson.normalValue': [{
    required: true,
    message: '请输入红外正常值',
    trigger: 'blur'
  }],
  'infraredJson.exceptionValue': [{
    required: true,
    message: '请输入红外异常值',
    trigger: 'blur'
  }],
})
// 点击配置环境监控
const environmentSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 4
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      environmentSetFormData.value = {
        tempJson: {
          dictId: data.temp.dictId ? [data.temp.dictId] : [],
          equipmentId: data.temp.equipmentId,
        },
        humidityJson: {
          dictId: data.humidity.dictId ? [data.humidity.dictId] : [],
          equipmentId: data.humidity.equipmentId,
        },
        pmJson: {
          dictId: data.pm.dictId ? [data.pm.dictId] : [],
          equipmentId: data.pm.equipmentId,
        },
        waterLeakageJson: {
          dictId: data.waterLeakage.dictId ? [data.waterLeakage.dictId] : [],
          equipmentId: data.waterLeakage.equipmentId,
          normalValue: data.waterLeakage.normalValue,
          exceptionValue: data.waterLeakage.exceptionValue,
        },
        smokeJson: {
          dictId: data.smoke.dictId ? [data.smoke.dictId] : [],
          equipmentId: data.smoke.equipmentId,
          normalValue: data.smoke.normalValue,
          exceptionValue: data.smoke.exceptionValue,
        },
        infraredJson: {
          dictId: data.infrared.dictId ? [data.infrared.dictId] : [],
          equipmentId: data.infrared.equipmentId,
          normalValue: data.infrared.normalValue,
          exceptionValue: data.infrared.exceptionValue,
        }
      }

      deviceDictList.value = [
        [{
          deviceId: data.temp.equipmentId,
          deviceName: data.temp.equipmentName,
          dictId: data.temp.dictId,
          dictName: data.temp.dictName,
        }], [{
          deviceId: data.humidity.equipmentId,
          deviceName: data.humidity.equipmentName,
          dictId: data.humidity.dictId,
          dictName: data.humidity.dictName,
        }], [{
          deviceId: data.pm.equipmentId,
          deviceName: data.pm.equipmentName,
          dictId: data.pm.dictId,
          dictName: data.pm.dictName,
        }], [{
          deviceId: data.waterLeakage.equipmentId,
          deviceName: data.waterLeakage.equipmentName,
          dictId: data.waterLeakage.dictId,
          dictName: data.waterLeakage.dictName,
        }], [{
          deviceId: data.smoke.equipmentId,
          deviceName: data.smoke.equipmentName,
          dictId: data.smoke.dictId,
          dictName: data.smoke.dictName,
        }], [{
          deviceId: data.infrared.equipmentId,
          deviceName: data.infrared.equipmentName,
          dictId: data.infrared.dictId,
          dictName: data.infrared.dictName,
        }]
      ]

      nextTick(() => {
        environmentSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击环境监控提交
let environmentSetFormRef = ref()
const environmentSetSubmit = function () {
  console.log(environmentSetFormData.value)
  if (environmentSetFormRef) {
    environmentSetFormRef.value.validate((valid) => {
      let data = JSON.parse(JSON.stringify(environmentSetFormData.value))

      data.tempJson.dictId = data.tempJson.dictId[0]
      data.humidityJson.dictId = data.humidityJson.dictId[0]
      data.pmJson.dictId = data.pmJson.dictId[0]
      data.waterLeakageJson.dictId = data.waterLeakageJson.dictId[0]
      data.smokeJson.dictId = data.smokeJson.dictId[0]
      data.infraredJson.dictId = data.infraredJson.dictId[0]
      //提交成功
      if (valid) {
        saveWithEnvironmentMonitoringApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          tempJsonStr: JSON.stringify(data.tempJson),
          humidityJsonStr: JSON.stringify(data.humidityJson),
          pmJsonStr: JSON.stringify(data.pmJson),
          waterLeakageJsonStr: JSON.stringify(data.waterLeakageJson),
          smokeJsonStr: JSON.stringify(data.smokeJson),
          infraredJsonStr: JSON.stringify(data.infraredJson),
        })).then(res => {
          if (res.data.code === 0) {
            environmentSetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 负载率***************************************************************************
// 加载负载率图表
const loadRateChartLoad = function () {
  var chartDom = document.querySelector('.loadRateChart');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    series: [{
      type: 'gauge',
      radius: '100%',
      pointer: {  //仪表盘指针
        // icon: 'image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectTemplate/circleNeedle.png',
        // length: '50%',
        // width: 16,
        // offsetCenter: [0, 0]

        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        length: '50%',
        width: 8,
        // offsetCenter: [0, '5%']
        itemStyle: {
          color: '#8BECC3'
        }
      },
      axisLine: {
        lineStyle: {
          width: 10,  //仪表盘（轴线）背景宽度
        }
      },
      axisTick: {
        show: false
      },
      splitLine: {  //分割线
        length: 5,
        distance: 5,  //距离轴线的距离
        lineStyle: {
          width: 1,
          color: '#999'
        }
      },
      axisLabel: {  //刻度标签
        distance: 15,
        color: '#999',
        fontSize: 10
      },
      anchor: {  //表盘中指针的固定点
        show: true,
        showAbove: true,
        size: 25,
        itemStyle: {
          borderWidth: 0,
          color: '#fff',
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.2)',
        }
      },
      title: {
        show: true,
        color: '#333',
        fontSize: 12,
        fontWeight: 300,
        offsetCenter: [0, '50%'],
      },
      detail: {
        valueAnimation: true,
        fontSize: 26,
        offsetCenter: [0, '80%'],
        fontWeight: 800,
        formatter: "{value}%"
      },
      data: [
        {
          value: templateData.value.loadRate,
          name: '负载率'
        }
      ]
    }],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 配置
let loadRateSetDialog = ref(false)  //负载率弹窗
let loadRateSetFormData = ref({
  dictId: [],
  equipmentId: '',
})
let loadRateSetFormRules = ref({  //负载率表单规则
  dictId: [{
    required: true,
    message: '请选择设备指标',
    trigger: 'blur'
  }],
})
// 点击配置负载率
const loadRateSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 5
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      loadRateSetFormData.value = {
        dictId: data.dictId ? [data.dictId] : [],
        equipmentId: data.equipmentId,
      }
      deviceDictList.value = [
        [{
          deviceId: data.equipmentId,
          deviceName: data.equipmentName,
          dictId: data.dictId,
          dictName: data.dictName,
        }]
      ]

      nextTick(() => {
        loadRateSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击负载率提交
let loadRateSetFormRef = ref()
const loadRateSetSubmit = function () {
  if (loadRateSetFormRef) {
    loadRateSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithPowerRoomLoadRateApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentId: loadRateSetFormData.value.equipmentId,
          dictId: loadRateSetFormData.value.dictId[0],
        })).then(res => {
          if (res.data.code === 0) {
            loadRateSetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 用电量数据***************************************************************************
const electricityChartLoad = function () {
  var chartDom = document.querySelector('.electricityRateChart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: -70,
      top: 10,
      bottom: 10,
      right: 10,
    },
    tooltip: {
      // trigger: 'axis', // axis   item   none三个值
      show: true,
      position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置
        var pointX = point[0];
        var pointY = point[1];
        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];
        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = 100;
        } else { // 左边放的下
          x = pointX - boxWidth;
        }
        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5;
        } else { // 上边放得下
          y = pointY - boxHeight;
        }
        return [x, y];
      },
    },
    legend: {
      show: true,
      right: "10%",
      orient: "vertical",
      top: "15%"
    },
    series: [
      {
        name: '用电量数据',
        type: 'pie',
        left: '-30%',
        radius: ['55%', '80%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        label: {
          show: false  // 隐藏所有标签
        },
        hoverAnimation: false,
        data: templateData.value.usePowerAmountList1
      }, {
        name: '用电量数据',
        type: 'pie',
        left: '-30%',
        radius: ['80%', '100%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        label: {
          show: false  // 隐藏所有标签
        },
        hoverAnimation: false,
        data: templateData.value.usePowerAmountList2
      }]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

}
// 配置
let electricitySetDialog = ref(false)  //用电量数据弹窗
let electricitySetFormData = ref({  //配电室表单数据
  equipmentIdJson: [],
})
const arrayLengthValidator3 = function (rule, value, callback) {
  if (value.length > 0) {
    callback(); // 通过验证
  } else {
    callback(new Error('请选择用电量设备')); // 未通过验证，显示错误信息
  }
}
let electricitySetFormRules = ref({  //配电室表单规则
  equipmentIdJson: [{
    required: true,
    validator: arrayLengthValidator3,
    trigger: 'blur'
  }],
})
// 点击配置用电量数据
const electricitySetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 6
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      equipmentList.value = data.equipmentList
      electricitySetFormData.value.equipmentIdJson = []
      for (let i = 0; i < data.equipmentList.length; i++) {
        electricitySetFormData.value.equipmentIdJson.push(data.equipmentList[i].id)
      }
      nextTick(() => {
        electricitySetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击用电量数据提交
let electricitySetFormRef = ref()
const electricitySetSubmit = function () {
  if (electricitySetFormRef) {
    electricitySetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithUsePowerDataApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentIdJson: JSON.stringify(electricitySetFormData.value.equipmentIdJson),
        })).then(res => {
          if (res.data.code === 0) {
            electricitySetDialog.value = false
            getTemplateDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 进线柜1***************************************************************************
let powerActive = ref(1) //tab选中
let powerRateDateTime = ref([moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')])  //时间
// 获取功率数据
let powerData = ref({
  xAxis: [],
  yAxis: []
})
const getPowerDataFun = function () {
  getPowerRoomRateChartDataApi1(qs.stringify({
    id: projectModuleId.value,
    rateType: powerActive.value,
    startTime: powerRateDateTime.value[0],
    endTime: powerRateDateTime.value[1]
  })).then(res => {
    if (res.data.code === 0) {
      powerData.value.xAxis = res.data.result.xAxis
      powerData.value.yAxis = []
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        powerData.value.yAxis.push({
          name: res.data.result.yAxis[i].titleName,
          type: 'line',
          symbol: 'none',
          smooth: true,
          data: res.data.result.yAxis[i].data
        })
      }
      nextTick(() => {
        arkLineChartLoad()
      })
      // arkBarChartLoad()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch((err) => {
    console.log(err);
  })
}
// tab栏切换
const powerHandleClick = function (e) {
  powerActive.value = e.paneName
  getPowerDataFun()
}
// 时间选择
const powerRateTimeChange = function () {
  getPowerDataFun()
}
// 进线柜1图表--折线图
const arkLineChartLoad = function () {
  var chartDom = document.querySelector('.arkLineChart');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      right: 20,
      bottom: 35,
      top: 25,
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 0,
      top: 0
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      boundaryGap: false,
      data: powerData.value.xAxis
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
        height: 10,
        bottom: 5,
        disabled: false,
      },
      {
        type: 'slider',
        start: 0,
        end: 100,
        height: 10,
        bottom: 5,
        // show: echartsDataX.value ? true : false
      }
    ],
    color: ['#165DFF', '#FCB645', '#19D342', '#EC2709'],
    series: powerData.value.yAxis
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

}

// 配置-------------------------
let powerSetDialog = ref(false)  //功率弹窗
let powerSetFormData = ref({  //功率表单数据
  equipmentId: [],
  name: ''
})
let powerSetFormRules = ref({  //功率表单规则
  equipmentId: [{
    required: true,
    message: '请选择设备',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入模块名称',
    trigger: 'blur'
  }],
})
// 点击配置功率
const powerSetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 7
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      powerSetFormData.value.equipmentId = data.equipmentId ? [data.equipmentId] : []
      powerSetFormData.value.name = data.name
      equipmentList.value = data.equipmentId ? [{
        id: data.equipmentId,
        name: data.equipmentName
      }] : []
      nextTick(() => {
        powerSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击功率提交
let powerSetFormRef = ref()
const powerSetSubmit = function () {
  if (powerSetFormRef) {
    powerSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithRateApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentId: powerSetFormData.value.equipmentId[0],
          name: powerSetFormData.value.name
        })).then(res => {
          if (res.data.code === 0) {
            powerSetDialog.value = false
            getPowerDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 进线柜2***************************************************************************
// 进线柜年月日切换
let timeTab = ref('1h')
let electricQuantityRateDateTime = ref([moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')])
// 获取用电量图表数据
let electricQuantityData = ref({
  xAxis: [],
  yAxis: []
})
const getElectricQuantityDataFun = function () {
  getPowerRoomUsePowerAmountChartDataApi1(qs.stringify({
    id: projectModuleId.value,
    timeUnit: timeTab.value,
    startTime: electricQuantityRateDateTime.value[0],
    endTime: electricQuantityRateDateTime.value[1]
  })).then(res => {
    if (res.data.code === 0) {
      electricQuantityData.value.xAxis = res.data.result.xAxis
      electricQuantityData.value.yAxis = []
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        electricQuantityData.value.yAxis.push({
          name: res.data.result.yAxis[i].titleName,
          data: res.data.result.yAxis[i].data,
          type: 'bar',
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {offset: 0, color: '#3196FF'},
            {offset: 1, color: '#165DFF'}
          ])
        })
      }
      nextTick(() => {
        arkBarChartLoad()
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch((err) => {
    console.log(err);
  })
}


const timeTabClick = function (idx) {
  timeTab.value = idx
  if (timeTab.value === '1h') {
    electricQuantityRateDateTime.value = [moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
  } else if (timeTab.value === '1d') {
    electricQuantityRateDateTime.value = [moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
  } else if (timeTab.value === '1n') {
    electricQuantityRateDateTime.value = [moment().subtract(1, 'year').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
  } else if (timeTab.value === '1y') {
    electricQuantityRateDateTime.value = [moment().subtract(10, 'year').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
  }
  getElectricQuantityDataFun()
}
const electricQuantityRateTimeChange = function () {
  getElectricQuantityDataFun()
}
// 进线柜2图表--柱状图
const arkBarChartLoad = function () {
  var chartDom = document.querySelector('.arkBarChart');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      right: 20,
      bottom: 20,
      top: 10,
    },
    legend: {
      show: false,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true,
      confine: true,
      className: 'custom-tooltip'
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: electricQuantityData.value.xAxis
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: electricQuantityData.value.yAxis
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

}

// 配置-------------------------
let electricQuantitySetDialog = ref(false)  //用电量弹窗
let electricQuantitySetFormData = ref({  //用电量表单数据
  equipmentId: [],
  name: ''
})
let electricQuantitySetFormRules = ref({  //用电量表单规则
  equipmentId: [{
    required: true,
    message: '请选择设备',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入模块名称',
    trigger: 'blur'
  }],
})
// 点击配置用电量
const electricQuantitySetClick = function () {
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 8
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      electricQuantitySetFormData.value.equipmentId = data.equipmentId ? [data.equipmentId] : []
      electricQuantitySetFormData.value.name = data.name
      equipmentList.value = data.equipmentId ? [{
        id: data.equipmentId,
        name: data.equipmentName
      }] : []
      nextTick(() => {
        electricQuantitySetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击用电量提交
let electricQuantitySetFormRef = ref()
const electricQuantitySetSubmit = function () {
  if (electricQuantitySetFormRef) {
    electricQuantitySetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithUsePowerApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentId: electricQuantitySetFormData.value.equipmentId[0],
          name: electricQuantitySetFormData.value.name
        })).then(res => {
          if (res.data.code === 0) {
            electricQuantitySetDialog.value = false
            getElectricQuantityDataFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 设备***************************************************************************
let deviceTableData = ref([])
let image = ref([])
let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
// 获取设备列表
const getDeviceListFun = function () {
  getPowerRoomDictApi1(qs.stringify({
    id: projectModuleId.value,
  })).then(res => {
    if (res.data.code === 0) {
      image.value = res.data.result.dictList
      getPowerRoomEquipmentApi1(qs.stringify({
        id: projectModuleId.value,
        pageNumber: pageNumber.value,
        pageSize: pageSize.value
      })).then(res => {
        if (res.data.code === 0) {
          deviceTableData.value = res.data.result.list
          total.value = Number(res.data.result.total)
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch((err) => {
        console.log(err);
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch((err) => {
    console.log(err);
  })
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getDeviceListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getDeviceListFun();
}
// 点击查看详情
const goLookDetail = function (id, idStr, type, nodeType, equipmentName, projectName) {
  window.open(`#/admin/projectData?isLocal=${nodeType}&id=${id}&idStr=${idStr}&type=${type}&equipmentName=${equipmentName}&projectName=${projectName}`, '_blank');
}

// 配置-----------------------------
let deviceSetDialog = ref(false)  //设备弹窗
let deviceSetFormData = ref({  //设备表单数据
  equipmentIdJson: [],
  dictJson: [],
  dictIdJson: []
})
let deviceSetFormRules = ref({  //设备表单规则
  equipmentIdJson: [{
    required: true,
    message: '请选择设备',
    trigger: 'blur'
  }],
  dictJson: [{
    required: true,
    message: '请选择指标',
    trigger: 'blur'
  }],
})
// 点击配置设备
const deviceSetClick = function () {
  deviceSetDialog.value = true
  getProjectModuleConfigDetailApi1(qs.stringify({
    projectModuleId: projectModuleId.value,
    type: 9
  })).then(res => {
    if (res.data.code === 0) {
      let data = JSON.parse(JSON.stringify(res.data.result))
      // 设备处理
      equipmentList.value = data.equipmentList
      deviceSetFormData.value.equipmentIdJson = []
      for (let i = 0; i < data.equipmentList.length; i++) {
        deviceSetFormData.value.equipmentIdJson.push(data.equipmentList[i].id)
      }
      // 指标处理
      dictList.value = [[]]
      deviceSetFormData.value.dictIdJson = []
      deviceSetFormData.value.dictJson = []
      for (let i = 0; i < data.dictList.length; i++) {
        deviceSetFormData.value.dictIdJson.push(data.dictList[i].dictId)
        dictList.value[0].push({
          id: data.dictList[i].dictId,
          name: data.dictList[i].dictName,
        })
        deviceSetFormData.value.dictJson.push({
          value: data.dictList[i].dictId,
          key: data.dictList[i].dictName,
        })
      }
      nextTick(() => {
        deviceSetDialog.value = true
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击设备提交
let deviceSetFormRef = ref()
const deviceSetSubmit = function () {
  if (deviceSetFormRef) {
    deviceSetFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveWithEquipmentApi1(qs.stringify({
          projectModuleId: projectModuleId.value,
          equipmentIdJson: JSON.stringify(deviceSetFormData.value.equipmentIdJson),
          dictJson: JSON.stringify(deviceSetFormData.value.dictJson),
        })).then(res => {
          if (res.data.code === 0) {
            deviceSetDialog.value = false
            getDeviceListFun()
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message,
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}


// 项目数据***************************************************************************
let custerObj = ref()
let projectModuleId = ref()
// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.custerObj, async (newValues, oldValues) => {
  custerObj.value = newValues
}, {immediate: true, deep: true});
watch(() => props.projectModuleId, async (newValues, oldValues) => {
  projectModuleId.value = newValues
}, {immediate: true, deep: true});


// 设备指标处理***************************************************************************
// 设备-------------------------------------------
let deviceVisible = ref(false)   //选择设备的组件弹窗
let equipmentList = ref([])  //选择设备的设备列表
// 点击选择设备
const deviceSelectClick = function () {
  deviceVisible.value = true
}
const selectDeviceFun = function () {
  deviceVisible.value = true
}
const deviceVisibleFun = function (data) {
  deviceVisible.value = data
}
const checkDeviceListFun = function (data) {
  equipmentList.value = data
}
// 监听设备列表
watch(() => equipmentList.value, (newVal) => {
  if (roomSetDialog.value) {
    roomSetFormData.value.equipmentIdJson = []
    for (let i = 0; i < newVal.length; i++) {
      roomSetFormData.value.equipmentIdJson.push(newVal[i].id)
    }
  } else if (transformerSetDialog.value) {
    transformerSetFormData.value.equipmentId = []
    for (let i = 0; i < newVal.length; i++) {
      transformerSetFormData.value.equipmentId.push(newVal[i].id)
    }
  } else if (warnSetDialog.value) {
    warnSetFormData.value.equipmentIdJson = []
    for (let i = 0; i < newVal.length; i++) {
      warnSetFormData.value.equipmentIdJson.push(newVal[i].id)
    }
  } else if (electricitySetDialog.value) {
    electricitySetFormData.value.equipmentIdJson = []
    for (let i = 0; i < newVal.length; i++) {
      electricitySetFormData.value.equipmentIdJson.push(newVal[i].id)
    }
  } else if (powerSetDialog.value) {
    powerSetFormData.value.equipmentId = []
    for (let i = 0; i < newVal.length; i++) {
      powerSetFormData.value.equipmentId.push(newVal[i].id)
    }
  } else if (electricQuantitySetDialog.value) {
    electricQuantitySetFormData.value.equipmentId = []
    for (let i = 0; i < newVal.length; i++) {
      electricQuantitySetFormData.value.equipmentId.push(newVal[i].id)
    }
  } else if (deviceSetDialog.value) {
    deviceSetFormData.value.equipmentIdJson = []
    for (let i = 0; i < newVal.length; i++) {
      deviceSetFormData.value.equipmentIdJson.push(newVal[i].id)
    }
  }

}, {deep: true, immediate: true})

// 指标-------------------------------------------
let dictVisible = ref(false)   //选择设备的组件弹窗
let dictList = ref([[], [], [], [], [], [], [], [], []])  //选择设备的设备列表
let dictSelectIdx = ref()
// 点击选择设备
const dictSelectClick = function (idx) {
  dictVisible.value = true
  dictSelectIdx.value = idx
}
const selectDictFun = function () {
  dictVisible.value = true
}
const dictVisibleFun = function (data) {
  dictVisible.value = data
}
const checkDictListFun = function (data) {
  dictList.value[dictSelectIdx.value - 1] = data
}
watch(() => dictList.value, (newVal) => {
  if (transformerSetDialog.value) {
    if (dictSelectIdx.value === 1) {
      transformerSetFormData.value.paramCurrentJson.aId = []
      for (let i = 0; i < newVal[0].length; i++) {
        transformerSetFormData.value.paramCurrentJson.aId.push(newVal[0][i].id)
      }
    } else if (dictSelectIdx.value === 2) {
      transformerSetFormData.value.paramCurrentJson.bId = []
      for (let i = 0; i < newVal[1].length; i++) {
        transformerSetFormData.value.paramCurrentJson.bId.push(newVal[1][i].id)
      }
    } else if (dictSelectIdx.value === 3) {
      transformerSetFormData.value.paramCurrentJson.cId = []
      for (let i = 0; i < newVal[2].length; i++) {
        transformerSetFormData.value.paramCurrentJson.cId.push(newVal[2][i].id)
      }
    } else if (dictSelectIdx.value === 4) {
      transformerSetFormData.value.paramVoltageJson.aId = []
      for (let i = 0; i < newVal[3].length; i++) {
        transformerSetFormData.value.paramVoltageJson.aId.push(newVal[3][i].id)
      }
    } else if (dictSelectIdx.value === 5) {
      transformerSetFormData.value.paramVoltageJson.bId = []
      for (let i = 0; i < newVal[4].length; i++) {
        transformerSetFormData.value.paramVoltageJson.bId.push(newVal[4][i].id)
      }
    } else if (dictSelectIdx.value === 6) {
      transformerSetFormData.value.paramVoltageJson.cId = []
      for (let i = 0; i < newVal[5].length; i++) {
        transformerSetFormData.value.paramVoltageJson.cId.push(newVal[5][i].id)
      }
    } else if (dictSelectIdx.value === 7) {
      transformerSetFormData.value.paramTempJson.aId = []
      for (let i = 0; i < newVal[6].length; i++) {
        transformerSetFormData.value.paramTempJson.aId.push(newVal[6][i].id)
      }
    } else if (dictSelectIdx.value === 8) {
      transformerSetFormData.value.paramTempJson.bId = []
      for (let i = 0; i < newVal[7].length; i++) {
        transformerSetFormData.value.paramTempJson.bId.push(newVal[7][i].id)
      }
    } else if (dictSelectIdx.value === 9) {
      transformerSetFormData.value.paramTempJson.cId = []
      for (let i = 0; i < newVal[8].length; i++) {
        transformerSetFormData.value.paramTempJson.cId.push(newVal[8][i].id)
      }
    }
  } else if (deviceSetDialog.value) {
    if (dictSelectIdx.value === 1) {
      deviceSetFormData.value.dictJson = []
      deviceSetFormData.value.dictIdJson = []
      for (let i = 0; i < newVal[0].length; i++) {
        deviceSetFormData.value.dictJson.push({
          value: newVal[0][i].id,
          key: newVal[0][i].name
        })
        deviceSetFormData.value.dictIdJson.push(newVal[0][i].id)
      }
    }
  }

}, {deep: true, immediate: true})

// 设备指标-------------------------------------------
let deviceDictVisible = ref(false)   //选择设备的组件弹窗
let deviceDictList = ref([[], [], [], [], [], []])  //选择设备的设备列表
let deviceDictSelectIdx = ref()
// 点击选择设备
const deviceDictSelectClick = function (idx) {
  deviceDictVisible.value = true
  deviceDictSelectIdx.value = idx
}
const selectDeviceDictFun = function () {
  deviceDictVisible.value = true
}
const deviceDictVisibleFun = function (data) {
  deviceDictVisible.value = data
}
const checkDeviceDictListFun = function (data) {
  deviceDictList.value[deviceDictSelectIdx.value - 1] = JSON.parse(JSON.stringify(data))
}
watch(() => deviceDictList.value, (newVal) => {
  if (environmentSetDialog.value) {
    if (deviceDictSelectIdx.value === 1) {
      environmentSetFormData.value.tempJson.dictId = []
      for (let i = 0; i < newVal[0].length; i++) {
        environmentSetFormData.value.tempJson.dictId.push(newVal[0][i].dictId)
        environmentSetFormData.value.tempJson.equipmentId = newVal[0][i].deviceId
      }
    } else if (deviceDictSelectIdx.value === 2) {
      environmentSetFormData.value.humidityJson.dictId = []
      for (let i = 0; i < newVal[1].length; i++) {
        environmentSetFormData.value.humidityJson.dictId.push(newVal[1][i].dictId)
        environmentSetFormData.value.humidityJson.equipmentId = newVal[1][i].deviceId
      }
    } else if (deviceDictSelectIdx.value === 3) {
      environmentSetFormData.value.pmJson.dictId = []
      for (let i = 0; i < newVal[2].length; i++) {
        environmentSetFormData.value.pmJson.dictId.push(newVal[2][i].dictId)
        environmentSetFormData.value.pmJson.equipmentId = newVal[2][i].deviceId
      }
    } else if (deviceDictSelectIdx.value === 4) {
      environmentSetFormData.value.waterLeakageJson.dictId = []
      for (let i = 0; i < newVal[3].length; i++) {
        environmentSetFormData.value.waterLeakageJson.dictId.push(newVal[3][i].dictId)
        environmentSetFormData.value.waterLeakageJson.equipmentId = newVal[3][i].deviceId
      }
    } else if (deviceDictSelectIdx.value === 5) {
      environmentSetFormData.value.smokeJson.dictId = []
      for (let i = 0; i < newVal[4].length; i++) {
        environmentSetFormData.value.smokeJson.dictId.push(newVal[4][i].dictId)
        environmentSetFormData.value.smokeJson.equipmentId = newVal[4][i].deviceId
      }
    } else if (deviceDictSelectIdx.value === 6) {
      environmentSetFormData.value.infraredJson.dictId = []
      for (let i = 0; i < newVal[5].length; i++) {
        environmentSetFormData.value.infraredJson.dictId.push(newVal[5][i].dictId)
        environmentSetFormData.value.infraredJson.equipmentId = newVal[5][i].deviceId
      }
    }
  } else if (loadRateSetDialog.value) {
    for (let i = 0; i < newVal[0].length; i++) {
      loadRateSetFormData.value.dictId = []
      loadRateSetFormData.value.dictId.push(newVal[0][i].dictId)
      loadRateSetFormData.value.equipmentId = newVal[0][i].deviceId
    }
  }

}, {deep: true, immediate: true})


// 生命周期***************************************************************************
onMounted(() => {
  getTemplateDataFun()  //获取配电室数据
  getPowerDataFun()   //获取功率数据
  getElectricQuantityDataFun()   //获取用电量图表数据
  getDeviceListFun()      //获取设备列表
})
</script>

<style lang="less">

</style>
