<template>
  <div class="mqttDevice">
    <div class="title"></div>
    <div class="content">
      <div class="searchBox">
        <el-form>
          <div class="searchPox">
            <div class="liBox">
              <el-form-item label="网关ID">
                <el-input v-model="gatewayId" autocomplete="off" clearable placeholder='请输入网关ID'
                          type="text"></el-input>
              </el-form-item>
            </div>
            <div class="liBox">
              <el-form-item label="设备名称">
                <el-input v-model="name" autocomplete="off" clearable placeholder='请输入设备名称' type="text"
                          @change="nameChange"></el-input>
              </el-form-item>
            </div>
            <div class="liBox" style="width: 252px">
              <el-form-item label="设备状态">
                <el-radio-group v-model="useStatus">
                  <el-radio value="">全部</el-radio>
                  <el-radio value="1">在线</el-radio>
                  <el-radio value="0">离线</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="liBox searchBtn">
              <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
              <el-button v-if="addDeviceLimit" :disabled="custerObj.nodeType==2?true:false" :icon="Plus" type="primary"
                         @click="addClick()">
                新建设备
              </el-button>
              <el-button type="primary">
                <el-checkbox v-model="getSubFlag" label="" size="large" @change="showNextLevel"/>
                显示下级
              </el-button>
              <el-button v-if="addDeviceLimit" :disabled="custerObj.nodeType==2?true:false" type="primary"
                         @click="importClick()">导入设备
              </el-button>
              <div style="display: none;">
                <el-upload v-model:file-list="fileList" :auto-upload="false" :show-file-list="false" accept=".xls,.xlsx"
                           action="#" class="upload-demo"
                           @change="uploadFile2">
                  <el-button id="fileName" style="margin: 0 12px;" type="primary" @click="">
                    导入
                  </el-button>
                </el-upload>
              </div>
              <el-button style="margin-left: 12px;" type="primary" @click="exportClick">导出模版</el-button>
              <el-button style="margin-left: 12px;" type="primary"
                         @click="bindGroupClick()">
                批量添加费率
              </el-button>
              <el-button style="margin-left: 12px;" @click="enterFullScreen()">全屏展示</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableBox">
        <!--列表-->
        <div class="tableBox">
          <el-table ref="multipleTableRef" v-loading='loadingVal' :data="deviceList" :row-key="rowKey"
                    style="width: 100%" table-layout="auto" @selection-change="handleSelectionChange">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                <p>您可以在此页面绑定设备</p>
              </el-empty>
            </template>
            <el-table-column :reserve-selection="true" type="selection" width="55"/>
            <el-table-column label="设备名称" min-width="150px" prop="name"/>
            <el-table-column label="设备地址" min-width="150px" prop="address"/>
            <el-table-column label="网关ID" min-width="150px" prop="dId"/>
            <el-table-column label="状态" min-width="150px" property="useStatusStr">
              <template #default="scope">
                <div v-show="scope.row.useStatus==1" class='statusOnClass'>
                  <div class="dotOnClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
                <div v-show="scope.row.useStatus==0" class='statusOutClass'>
                  <div class="dotOutClass"></div>
                  {{ scope.row.useStatusStr }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品型号" min-width="150px" prop="productModel"/>
            <!--            <el-table-column label="创建人" min-width="150px" prop="creatorName"/>-->
            <el-table-column label="最后通讯时间" min-width="150px" prop="lastTime" sortable/>
            <el-table-column :width="120" fixed="right" label="操作">
              <template #default="scope">
                <ul class="operationUl">
                  <li v-if="editDeviceLimit" v-show="custerObj.nodeType!=2" class="editClass"
                      @click="addClick(scope.row.id)">
                    <el-tooltip content="编辑" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear1.png">
                    </el-tooltip>
                  </li>
                  <li class="editClass"
                      @click="managementClick(scope.row.id,scope.row.idStr,scope.row.name,scope.row.equipmentId)">
                    <el-tooltip content="数据" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear2.png">
                    </el-tooltip>
                  </li>
                  <li class="editClass" @click='goWarnFun(scope.row.id,scope.row.idStr)'>
                    <el-tooltip content="报警" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear5.svg">
                    </el-tooltip>
                  </li>
                  <li v-if="delDeviceLimit" v-show="custerObj.nodeType!=2" class="editClass"
                      @click="deleteClick(scope.row.id)">
                    <el-tooltip content="删除" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear4.svg">
                    </el-tooltip>
                  </li>
                  <li v-if="editDeviceLimit" class="editClass"
                      @click="bindGroupClick(scope.row.id,scope.row.idStr)">
                    <el-tooltip content="添加费率" effect="light" placement="top">
                      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear6.svg">
                    </el-tooltip>
                  </li>
                  <!--                  <li class="editClass">-->
                  <!--                    <el-popover popper-class="moreOpera2" trigger="hover">-->
                  <!--                      <div class="listPox">-->
                  <!--                        <p @click='goWarnFun(scope.row.id,scope.row.idStr)'>报警</p>-->
                  <!--                        <p v-if="delDeviceLimit" v-show="custerObj.nodeType!=2" @click="deleteClick(scope.row.id)">-->
                  <!--                          删除</p>-->
                  <!--                      </div>-->
                  <!--                      <template #reference>-->
                  <!--                        <p class="more">-->
                  <!--                          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectOpear3.png">-->
                  <!--                        </p>-->
                  <!--                      </template>-->
                  <!--                    </el-popover>-->
                  <!--                  </li>-->
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="footerBox">
            <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]"
                           :total="total" background layout="total,sizes, prev, pager, next" small
                           @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
          </div>
        </div>
      </div>
    </div>
    <!--新增设备弹窗-->
    <el-dialog v-model="deviceDialogVisible" :close-on-click-modal="false" :title="deviceForm.id?'编辑设备':'新增设备'"
               width="40%">
      <div>
        <el-form ref="deviceFormRef" :model="deviceForm" :rules="deviceRules" class="demo-ruleForm" label-width="auto"
                 style="width:98%">
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="deviceForm.name" placeholder="请输入设备名称"/>
          </el-form-item>
          <el-form-item label="产品型号" prop="productId">
            <el-select v-model="deviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
              <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网关ID" prop="dId">
            <el-input v-model="deviceForm.dId" placeholder="请输入网关ID"/>
          </el-form-item>
          <el-form-item label="通讯地址" prop="address">
            <el-input v-model="deviceForm.address" placeholder="请输入通讯地址" @change="addressChange"/>
          </el-form-item>
          <el-form-item label="密码" prop="pwd">
            <el-input v-model="deviceForm.pwd" placeholder="请输入密码"/>
          </el-form-item>
          <el-form-item label="是否启用" prop="enableFlag">
            <el-select v-model="deviceForm.enableFlag" clearable filterable placeholder="请选择是否启用"
                       style="width:100%">
              <el-option v-for="item in enableList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="deviceForm.remark" placeholder="请输入备注"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--绑定组合包弹窗-->
    <el-dialog v-model="bindGroupVisible" :close-on-click-modal="false" title="添加费率" width="80%">
      <div class="addRateMix">
        <div style="display: flex;align-items: center;margin-bottom: 20px">
          <span style="margin-right: 10px">电价组合包</span>
          <el-select v-model="bindGroupId" class="bagClass" clearable placeholder="请选择电价组合包"
                     style="width: 220px;">
            <el-option v-for="(item,index) in electrovalenceBagList" :key="index" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="content" style="padding: 0;width: 100%;">
          <DetailModule v-if="bindGroupId" :id="bindGroupId"/>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle resetButton" @click="bindGroupVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="bindGroupSave()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, h, onMounted, reactive, ref, watch} from "vue"
import './mqttDevice.less'
import {useRouter} from "vue-router/dist/vue-router";
import {getDeviceIsOpenApi, getDeviceListApi, getDeviceListLocalApi, getDeviceMqttListApi} from "@/api/modules/project";
import qs from "qs";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {
  deleteList, deleteMqttList,
  getDetail,
  getMqttDetail,
  getMqttProductList,
  importDeviceList, importMqttDeviceList,
  saveList, saveMqttList
} from "@/api/modules/device";
import {http} from "@/api";
import DetailModule from '../../rateManagement/addRateMix/detailModule'
import {
  getPowerPriceGroupListApi,
  getPowerPriceGroupListByPrefixEquipmentIdApi,
  updateGroupEquipmentApi
} from "@/api/modules/rate";

const props = defineProps(['custerObj']);
let router = useRouter()
let route = useRouter()

// 获取列表-------------------------------------------------------------------------
//设备名称的改变
const nameChange = function () {
  window.localStorage.setItem('name', name.value)
}
let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置

//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let name = ref() //设备名称
let gatewayId = ref() //网关id
let useStatus = ref('') //状态
let getSubFlag = ref(true) //状态
//获取设备列表
let deviceList = ref([])
const getListFun = async function () {
  if (custerObj.value.id) {
    loadingVal.value = true
    let params = {
      pageNumber: pageNumber.value,
      pageSize: pageSize.value,
      projectId: custerObj.value.id,
      getSubFlag: getSubFlag.value ? 1 : 0,
      name: name.value,
      useStatus: useStatus.value,
      dId: gatewayId.value
    }
    await getDeviceMqttListApi(qs.stringify(params)).then(res => {
      loadingVal.value = false
      if (res.data.ok) {
        deviceList.value = res.data.result.list
        total.value = Number(res.data.result.total)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}


// 显示下级-------------------------------------------------------------------------
const showNextLevel = function () {
  getListFun()
}


// 新增操作-------------------------------------------------------------------------
let deviceDialogVisible = ref(false) //新增设备弹窗开关
let editId = ref() //编辑id
let enableList = ref([{
  id: 1,
  name: '是'
}, {
  id: 0,
  name: '否'
}])
//新增设备的点击
const addClick = function (id) {
  deviceDialogVisible.value = true
  if (id) {
    editId.value = id
    getMqttDetail(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.ok) {
        deviceForm.value.id = res.data.result.id
        deviceForm.value.name = res.data.result.name
        deviceForm.value.productId = res.data.result.productId
        deviceForm.value.dId = res.data.result.dId
        deviceForm.value.address = res.data.result.address
        deviceForm.value.pwd = res.data.result.pwd
        deviceForm.value.enableFlag = res.data.result.enableFlag
        deviceForm.value.projectId = custerObj.value.id
        deviceForm.value.remark = res.data.result.remark
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    deviceForm.value = {
      id: 0,
      name: '',
      productId: '',
      dId: '',
      address: '',
      pwd: '',
      projectId: custerObj.value.id,
      enableFlag: 1,
      remark: '',
    }
  }
}

let deviceFormRef = ref() //设备
//设备表单
let deviceForm = ref({
  id: 0,
  name: '',
  productId: '',
  dId: '',
  address: '',
  pwd: '',
  projectId: '',
  enableFlag: 1,
  remark: '',
})
// 设备表单验证规则
let deviceRules = ref({
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }],
  dId: [{
    required: true,
    message: '请输入网关id',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  pwd: [{
    required: true,
    message: '请输入密码',
    trigger: 'blur'
  }],
  enableFlag: [{
    required: true,
    message: '请选择是否启用',
    trigger: 'blur'
  }]
})
//设备的保存
const deviceSave = function () {
  if (deviceFormRef) {
    deviceFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveMqttList(
            qs.stringify(deviceForm.value)
        ).then(async res => {
          if (res.data.ok) {
            deviceDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteMqttList(qs.stringify({
          id: id
        })
    ).then(res => {
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}


// 导入设备---------------------------------------------------------------
let personData = ref({}) //自定义上传参数
let fileList = ref([]) //上传文件
const importClick = function () {
  document.getElementById("fileName").click();
}
const uploadFile2 = function (file) {
  let fd = new FormData();
  fd.append('file', file.raw);
  importMqttDeviceList(fd).then(res => {
    if (res && res.data && res.data.ok) {
      //console.log(res)
      getListFun()
      ElMessage({
        type: 'success',
        message: '导入成功！'
      });
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 导出模版
const exportClick = function () {
  openFullScreen();
  setTimeout(() => {
    loadingAdd.value.close();
    ElMessage({
      type: 'success',
      message: '导出成功'
    });
  }, 2000);
  window.location.href = http + 'zouk/rest/equipmentMqtt/exportTemplate?projectId=' + custerObj.value.id + '&memberId=' + window.localStorage.getItem('memberId')
}


//管理
const managementClick = function (id, idStr, equipmentName, equipmentId) {
  window.open(`#/admin/projectData?isLocal=${custerObj.value.nodeType}&id=${id}&idStr=${idStr}&type=4&equipmentName=${equipmentName}&projectName=${custerObj.value.name}&equipmentId=${equipmentId}`, '_blank');
}
// 点击报警
const goWarnFun = function (id, idStr) {
  window.open(`#/admin/projectWarning?id=${idStr}`, '_blank');
}


// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.rightBox'); // 获取需要全屏的元素;
  // document.querySelector('.largeScreenContent .chart').style.height = 'calc(100% - 140px)'  //进入全屏后变大图片
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }


};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }

};


// 表格多选框选择事件
let checkIdArr = ref([])
const rowKey = function (row) {
  return row.id
}
const handleSelectionChange = function (e) {
  checkIdArr.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr.value.push(Number(e[i].id))
  }
  checkIdArr.value = [...new Set(checkIdArr.value)]
}


// 绑定组合包---------------------------------------------------------------
let bindGroupVisible = ref(false)  //绑定组合包弹窗
let bindGroupId = ref() // 组合包id
let bindGroupEquipmentId = ref() // 绑定组合包的设备id
let electrovalenceBagList = ref([])
// 点击绑定组合包
const bindGroupClick = function () {
  bindGroupEquipmentId.value = id ? id : null
  bindGroupVisible.value = true
  // 获取电价组合包列表
  getPowerPriceGroupListByPrefixEquipmentIdApi(qs.stringify({
    prefixId: idStr ? idStr : null,
  })).then(res => {
    if (res.data.code === 0) {
      electrovalenceBagList.value = res.data.result
      let flag = true
      for (let i = 0; i < electrovalenceBagList.value.length; i++) {
        if (electrovalenceBagList.value[i].checkFlag) {
          bindGroupId.value = electrovalenceBagList.value[i].id
          flag = false
        }
      }
      if (flag) {
        bindGroupId.value = ''
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 绑定组合包提交
const bindGroupSave = function () {
  updateGroupEquipmentApi(qs.stringify({
    id: bindGroupId.value,
    equipmentType: 4,
    equipmentIdList: bindGroupEquipmentId.value ? bindGroupEquipmentId.value : checkIdArr.value.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      bindGroupVisible.value = false
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


let addDeviceLimit = ref(false)
let editDeviceLimit = ref(false)
let delDeviceLimit = ref(false)
/*生命周期*/
let productList = ref([])
onMounted(async () => {
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});


  // 获取产品型号选项
  getMqttProductList().then(res => {
    if (res && res.data && res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })


  // 获取设备的操作权限
  let arr = JSON.parse(window.window.localStorage.getItem('menuList'))
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].menuKey === '/admin/project') {
      for (let j = 0; j < arr[i].subList.length; j++) {
        if (arr[i].subList[j].menuKey === 'addDevice') {
          addDeviceLimit.value = true
        } else if (arr[i].subList[j].menuKey === 'editDevice') {
          editDeviceLimit.value = true
        } else if (arr[i].subList[j].menuKey === 'delDevice') {
          delDeviceLimit.value = true
        }
      }
    }
  }
})

let custerObj = ref()
// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.custerObj, async (newValues, oldValues) => {
  custerObj.value = newValues
  if (newValues.id) {
    await getListFun()
  }
}, {immediate: true, deep: true});


</script>

<style lang="less" scoped>
</style>
