import {api} from "@/api";

// 获取项目树
export const getProjectListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTree',
        method: 'POST',
        data: data
    })
}


// 保存项目列表
export const saveProjectListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/save',
        method: 'POST',
        data: data
    })
}

// 删除项目列表
export const delProjectListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/delete',
        method: 'POST',
        data: data
    })
}

// 获取绑定该项目的设备列表
export const getDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/pageEquipment',
        method: 'POST',
        data: data
    })
}
// 获取绑定该项目的设备列表  --- mqtt
export const getDeviceMqttListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipmentMqtt/page',
        method: 'POST',
        data: data
    })
}
// 获取绑定该项目的设备列表  ---  本地
export const getDeviceListLocalApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/pageEquipmentLocal',
        method: 'POST',
        data: data
    })
}

// 获取未绑定项目设备
export const getAllDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/page',
        method: 'POST',
        data: data
    })
}
// 绑定设备
export const bindDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/bind',
        method: 'POST',
        data: data
    })
}
// 解绑设备
export const removeDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/unbind',
        method: 'POST',
        data: data
    })
}


// 仪表盘设置---------------------------------------------------------------------
// 获取仪表盘选项
export const getCustomSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panel/listParamSelect',
        method: 'POST',
        data: data
    })
}
// 获取图表选项
export const getChatSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panel/listQuerySelect',
        method: 'POST',
        data: data
    })
}
// 获取仪表盘详情
export const getCustomDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/panel/getDetail',
        url: '/zouk/message/rest/panel/getData',
        method: 'POST',
        data: data
    })
}

// 更新仪表盘
export const saveCustomDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/panel/save',
        method: 'POST',
        data: data
    })
}


// 获取项目概览数据
export const getProjectOverviewApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getProjectData',
        method: 'POST',
        data: data
    })
}

// 新增项目查询
export const addProjectQueryApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/addQuery',
        method: 'POST',
        data: data
    })
}
// 删除项目查询
export const delProjectQueryApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/deleteQuery',
        method: 'POST',
        data: data
    })
}

// 获取直连设备是否开启配置
export const getDeviceIsOpenApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/getOpenDirectEquipment',
        method: 'POST',
        data: data
    })
}


// 获取分组列表
export const getQueryGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/queryGroup/listGroup',
        method: 'POST',
        data: data
    })
}


// 保存分组列表
export const addQueryGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/queryGroup/save',
        url: '/zouk/rest/queryGroup/saveV2',
        method: 'POST',
        data: data
    })
}


// 删除分组列表
export const delQueryGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/queryGroup/delete',
        method: 'POST',
        data: data
    })
}

// 获取分组图表数据
export const getQueryGroupDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/queryGroup/getData',
        method: 'POST',
        data: data
    })
}


// 获取顶级项目树
export const getProjectTopTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTopTree',
        method: 'POST',
        data: data
    })
}

// 获取下级项目树
export const getProjectSubTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getSubTree',
        method: 'POST',
        data: data
    })
}


// 模版接口
// 获取模版列表
export const getProjectModuleListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/list',
        method: 'POST',
        data: data
    })
}

// 保存模版列表
export const saveProjectModuleListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/save',
        method: 'POST',
        data: data
    })
}

// 获取模版列表--------------------------------------------------
export const delProjectModuleListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/delete',
        method: 'POST',
        data: data
    })
}

// 配电室1模版接口--------------------------------------------------
export const saveWithPowerRoomApi1 = (data) => {   //配电室
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithPowerRoom',
        method: 'POST',
        data: data
    })
}
export const saveWithTransformerParameterApi1 = (data) => {   //变压器
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithTransformerParameter',
        method: 'POST',
        data: data
    })
}
export const saveWithWarnApi1 = (data) => {   //告警
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithWarn',
        method: 'POST',
        data: data
    })
}

export const saveWithEnvironmentMonitoringApi1 = (data) => {   //环境监测
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithEnvironmentMonitoring',
        method: 'POST',
        data: data
    })
}
export const saveWithPowerRoomLoadRateApi1 = (data) => {   //负载率
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithPowerRoomLoadRate',
        method: 'POST',
        data: data
    })
}
export const saveWithUsePowerDataApi1 = (data) => {   //用电量数据
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithUsePowerData',
        method: 'POST',
        data: data
    })
}
export const saveWithRateApi1 = (data) => {   //功率
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithRate',
        method: 'POST',
        data: data
    })
}
export const saveWithUsePowerApi1 = (data) => {   //用电量
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithUsePower',
        method: 'POST',
        data: data
    })
}
export const saveWithEquipmentApi1 = (data) => {   //设备
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/saveWithEquipment',
        method: 'POST',
        data: data
    })
}
// 获取配置详情
export const getProjectModuleConfigDetailApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModuleConfig/getDetail',
        method: 'POST',
        data: data
    })
}
// 获取配电室全部数据
export const getPowerRoomDataApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/getPowerRoomData',
        method: 'POST',
        data: data
    })
}
// 获取配电室设备数据
export const getPowerRoomEquipmentApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/pagePowerRoomEquipment',
        method: 'POST',
        data: data
    })
}
// 获取配电室设备指标
export const getPowerRoomDictApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/getPowerRoomEquipment',
        method: 'POST',
        data: data
    })
}

// 获取配电室功率折线图数据
export const getPowerRoomRateChartDataApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/getPowerRoomRateChartData',
        method: 'POST',
        data: data
    })
}
// 获取配电室用电量柱状图数据
export const getPowerRoomUsePowerAmountChartDataApi1 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/projectModule/getPowerRoomUsePowerAmountChartData',
        method: 'POST',
        data: data
    })
}








